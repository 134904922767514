import React, {Component} from 'react';
import { withRouter } from 'react-router-dom'
import {Menu} from 'antd'
import styles from './NavigationMenu.module.css'
import cx from 'classnames'
import _keyBy from 'lodash/keyBy'
import _get from 'lodash/get'

class NavigationMenu extends Component {
	constructor(props) {
		super(props);
		const {menuItems} = this.props;
		const itemKeyMap = _keyBy(menuItems,'key');
		const itemRouteMap = _keyBy(menuItems,'route');
		this.state = {
			itemKeyMap,
			itemRouteMap,
		}
	}
	
	
	handleClick = e => {
		this.setState({
			current: e.key,
		});
		const {itemKeyMap} = this.state;
		const {history} = this.props;
		const {route} = itemKeyMap[e.key];
		history.push(route)
		if(this.props.handleMenuClick) this.props.handleMenuClick();
	};
	
	getSelectedTab = () => {
		const {location} = this.props;
		const {itemRouteMap} = this.state;
		return _get(itemRouteMap,[location.pathname,'key']);
	}
	
	renderMenuItem = (menuItem) => {
		const {sidebar} = this.props;
		const menuItemClassname = cx((sidebar? styles.sideBarMenuItem:""), styles.menuItem)
		const menuLabelClassname = cx((sidebar? styles.sideBarMenuLabel:""), styles.menuItem)
		return(
			<Menu.Item
			key={menuItem.key}
			className={menuItemClassname}
		>
			<span className={menuLabelClassname}>{menuItem.label}</span>
		</Menu.Item>
		)
	}
	
	renderMenuItems= () => {
		const { menuItems } = this.props;
		return menuItems.map(this.renderMenuItem)
	}
	render() {
		const { mode } = this.props;
		const selectedTabKey = this.getSelectedTab();
		return (
			<Menu
				theme="dark"
				mode={mode}
				onClick={this.handleClick}
				selectedKeys={[selectedTabKey]}
			>
				{this.renderMenuItems()}
			</Menu>
		);
	}
}

export default withRouter(NavigationMenu);
