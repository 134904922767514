import React, {Component} from 'react';

const withIsMobile = (WrappedComponent) => {
	return class WithIsMobile extends Component {
		state = {}
		
		componentDidMount() {
			window.addEventListener("resize", this.resize);
			this.resize();
		}
		
		componentWillUnmount() {
			window.removeEventListener('resize', this.resize);
		}
		
		resize = () => {
			this.setState({isMobile: window.innerWidth <= 800});
		}
		
		render() {
			const { isMobile } = this.state;
			return (
				<WrappedComponent
					isMobile={isMobile}
					{...this.props}
				/>
			)
		}
	}
};

export default withIsMobile;
