import React, {Component} from 'react';
import RouteSwitch from "atoms/routeSwitch";
import AdminApp from "pages/adminPages/pages/base";
import StudentApp from 'pages/studentPages/pages/base'
import CommonApp from "pages/commonPages/pages/base";

const adminRoute = (componentProps) => ({
	path: '/admin',
	component: AdminApp,
	componentProps: {componentProps},
	accessSubject: 'admin',
});

const studentRoute = (componentProps) => ({
	path: '/student',
	component: StudentApp,
	componentProps: {componentProps},
	accessSubject: 'student',
});

const commonRoute = (componentProps) => ({
	path: '/',
	component: CommonApp,
	componentProps: {componentProps},
	accessSubject: 'common',
});

const getRoutes = (componentProps) => ([
	adminRoute(componentProps),
	studentRoute(componentProps),
	commonRoute(componentProps),
]);

class Routes extends Component {
	render() {
		return (
			<RouteSwitch
				routes={getRoutes({...this.props})}
				redirectPath="/"
			/>
		);
	}
}

export default Routes;
