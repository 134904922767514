import React, {Component} from 'react';
import cx from "classnames";
import _difference from "lodash/difference";
import {STUDENT_DETAILS_KEY_LABEL_MAP, STUDENT_DETAILS_KEYS} from "constants/app.general";
import styles from "./StudentDetails.module.scss";

class StudentDetails extends Component {
	renderDetail = (key) => {
		const {studentDetails} = this.props;
		return (
			<>
				<div className={cx(styles.studentDetailsLabel)} key={key+"_label"}>
					{STUDENT_DETAILS_KEY_LABEL_MAP[key]}
				</div>
				<div className={cx(styles.studentDetailsValue)} key={key+"_value"}>
					{studentDetails[key]}
				</div>
			</>
		)
	}
	
	render() {
		return (
			<div className={cx(styles.studentDetailsContainer)}>
				{_difference(STUDENT_DETAILS_KEYS, ['stream', 'batch']).map(this.renderDetail)}
			</div>
		);
	}
}

export default StudentDetails;
