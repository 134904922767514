import {firebaseAppAuth, usersList} from "services/firebase";
import _values from 'lodash/values';

export const isUserRegistered = emailToCheck => {
	return usersList.once('value').then(snapshot => {
		const usersList = _values(snapshot.val());
		for(const {studentEmail, email} of usersList){
			if(emailToCheck === studentEmail || emailToCheck === email)
				return true;
		}
		return false;
	})
}

export const sendResetEmail = (email) => {
	return firebaseAppAuth.sendPasswordResetEmail(email)
}
