import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';

import {
  interestedStudentsList,
  studentsOfBatch,
  attendanceOfStreamBatchAndDate,
  deletedUserDetails,
  userDetails,
  interestedStudentsDetails,
  streamBatchAttendance,
  batchDetails,
} from "services/firebase";
import {fetchAllRegisteredUsers} from '../../actions/users';

import {deleteUser} from 'services/adminServices';
import PERMISSIONS from "constants/permissions";
import { fetchStreamData, setAttendanceStatus } from "actions/app.actions";

import ACTION_TYPES from "./AdminDashboard.actionTypes";

export const fetchInterestedStudentList = () => (dispatch) => {
	dispatch({type: ACTION_TYPES.FETCH_INTERESTED_STUDENT_LIST_REQUEST});
	interestedStudentsList.once('value')
		.then(snapshot => {
			const receivedData = snapshot.val();
			const interestedStudentList = _isEmpty(receivedData) || _isNil(receivedData) ? [] : _map(receivedData, (interestedStudentDetails, id) => ({
        ...interestedStudentDetails,
        id,
      }));
			dispatch({
				type: ACTION_TYPES.FETCH_INTERESTED_STUDENT_LIST_SUCCESS,
				payload: interestedStudentList,
			})
		})
		.catch(err => {
			dispatch({
				type: ACTION_TYPES.FETCH_INTERESTED_STUDENT_LIST_FAILURE,
				payload: err,
			})
		});
}

export const fetchRegisteredStudentList = () => (dispatch) => {
	dispatch({type: ACTION_TYPES.FETCH_REGISTERED_STUDENT_LIST_REQUEST});
  fetchAllRegisteredUsers()
		.then(receivedData => {
      const listOfUsers = _map(receivedData, (value, key) => ({...value, uid: key}))
			const registeredStudentsList = _isEmpty(receivedData) || _isNil(receivedData) ? [] : _filter(listOfUsers,({permission}) => permission === PERMISSIONS.STUDENT);
			dispatch({
				type: ACTION_TYPES.FETCH_REGISTERED_STUDENT_LIST_SUCCESS,
				payload: {
				  registeredStudentsList,
          registeredStudentsMap: receivedData
        },
			})
		})
		.catch(err => {
			dispatch({
				type: ACTION_TYPES.FETCH_REGISTERED_STUDENT_LIST_FAILURE,
				payload: err,
			})
		});
}

export const getStreamData = () => (dispatch) => {
	dispatch({type: ACTION_TYPES.FETCH_STREAM_DATA_REQUEST});
	fetchStreamData().then(({ streams, streamsData }) => {
		dispatch({
			type: ACTION_TYPES.FETCH_STREAM_DATA_SUCCESS,
			payload: {
				streams,
				streamMap: streamsData,
			}
		})
	})
		.catch(error => dispatch({type: ACTION_TYPES.FETCH_STREAM_DATA_FAILURE, payload: error}));
};

export const updateAttendanceStatus = (date, stream, batch, topic, status, studentId, isStudentPresent) => dispatch => {
	dispatch({type: ACTION_TYPES.UPDATE_ATTENDANCE_REQUEST});
	setAttendanceStatus(date, stream, batch, topic, status, studentId, isStudentPresent)
		.then(() => dispatch({type: ACTION_TYPES.UPDATE_ATTENDANCE_SUCCESS}))
		.catch(() => dispatch({type: ACTION_TYPES.UPDATE_ATTENDANCE_FAILURE}));
};

export const attendanceOpenStatus = (date, stream, batch) => attendanceOfStreamBatchAndDate(stream, batch, date).once('value').then(snapshot => snapshot.val())

export const resetOpenAttendanceStatus = () => dispatch => dispatch({type: ACTION_TYPES.RESET_OPEN_ATTENDANCE_STATUS});

export const removeStudent = (studentDetails) => {
  const { uid, studentName, previousBatches = [], stream, batch} = studentDetails;
  const  setDeletedUserDetailsPromise = (!stream || !batch) ? Promise.resolve() : deletedUserDetails(uid).set({
    studentName,
    streamsAndBatches: _uniqWith([
      ...previousBatches,
      {
        stream,
        batch,
      },
    ], _isEqual)
  });
  return setDeletedUserDetailsPromise.then(() => Promise.all([
    studentsOfBatch(stream, batch).once('value').then(snapshot => {
      const students = snapshot.val();
      const updatedListOfStudents = _filter(students, studentId => studentId !== uid );
      return studentsOfBatch(stream, batch).set(updatedListOfStudents);
    }),
    userDetails(uid).remove(),
    deleteUser(uid),
  ]));
};

export const removeInterestedStudent = (id) => interestedStudentsDetails(id).remove();

export const deleteBatch = (stream, batch) => {
  return Promise.all([
    streamBatchAttendance(stream, batch).remove(),
    batchDetails(stream, batch).remove(),
  ])
}
