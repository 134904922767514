export const interestedStudentEmailGenerator = (details, keyLabelMap) => {
	const subject = "Student Interested in Easy Chemistry Academy";
	const body = `Hello,
	
	<h3>${details.studentName} has expressed interest in joining Easy Chemistry Academy</h3>
	His/Her details are:
	<ul>
	${Object.keys(details).reduce((acc, key) => acc + `<li>${keyLabelMap[key]}: ${details[key]}</li>`, '')}
	</ul>
	Thanks & Regards,<br/>
	Easy Chemistry Academy
	`;
	return {subject, body};
}

export const registeredStudentEmailForAdminGenerator = (details, keyLabelMap) => {
	const subject = "Student Registered at Easy Chemistry Academy";
	const body = `Hello,
	
	<h3>${details.studentName} has registered on Easy Chemistry Academy</h3>
	His/Her details are:
	<ul>
	${Object.keys(details).reduce((acc, key) => acc + `<li>${keyLabelMap[key]}: ${details[key]}</li>`, '')}
	</ul>
	Admit him/her into a stream on <a href="easychem.in">easychem.in</a>
	Thanks & Regards,
	<br/>
	Easy Chemistry Academy
	`;
	return {subject, body};
}

export const registeredStudentEmailForStudentGenerator = (user, details, keyLabelMap, role) => {
	const subject = "Welcome to Easy Chemistry Academy";
	const studentGas1 = "You've just made a great first step towards excelling in your career.";
	const parentGas1 = "You've just made a great first step towards ensuring your child excels in their career.";
	const studentGas2 = "I'm excited to help you in your success journey";
	const parentGas2 = "I'm excited to help you in your child's success journey";
	const body = `Dear ${user},<br/>
	<p>A warm welcome to the EasyChemistry Family!<br/>
	${role==='student' ? studentGas1: parentGas1}</p>
	<p>
	No more rote-learning! No more monotonous classes! No more conceptual confusion! - Learn Chemistry the right way. Approach the subject analytically and understand the why, not what of Chemistry. ${role==='student' ? studentGas2: parentGas2} and make this the start of something bigger.
	</p>
	<p>The details of your registration are as follows:
	<ul>
		${Object.keys(details).reduce((acc, key) => acc + `<li>${keyLabelMap[key]}: ${details[key]}</li>`, '')}
	</ul>

	Please wait till you are admitted into your stream on the student portal to get access to the functionalities on the website
	<br/>
	</p>
	In the meantime, some ground rules for classes:
	<br/>
	<ul>
		<li>A dedicated notebook for the classes to take running notes to be maintained.</li>
		
		<li>Textbooks and reference material to be kept ready for reference during the class.</li>
		
		<li>Students have to complete the daily homework, pre-reading and experiments before the start of the next class. They should be submitted over WhatsApp/Email.</li>
		
		<li>Throughout the class, students have to switch on their video and unmute their microphones. Ensure classes are attended in a quiet environment.</li>
		
		<li>Parents are requested to ensure that students don't have access to any distractions such as smartphones, games etc. during the classes.</li>
		
		<li>Classes will strictly start on time. Joining late would mean losing out the part missed.</li>
		
		<li> Classes will generally have the following flow:
			<ul>
				<li>Brief revision of previous topic</li>
				<li>Discussion on submitted homework</li>
				<li>Explanation of day's topic</li>
				<li>Doubts session</li>
			</ul>
		</li>
		<li>Fee will have to be paid prior to the start of classes.</li>
	</ul>
	<br/>
In case of any further doubts, feel free to call/WhatsApp to +91-934-840-5065 and get them clarified.<br style="line-height:2;"/>
<div style="display: flex; align-items: center;">
	<p>
		In quest for your success,<br/>
		Easy Chemistry Academy<br/>
		<a href="easychem.in">easychem.in</a>
	</p>
</div>
	`;
	return {subject, body};
}

export const studentAdmittedEmailForStudentGenerator = (user, details, keyLabelMap, role) => {
	const subject = "Successful Admission to a Stream in Easy Chemistry Academy";
	const body = `Dear ${user},<br/>
	<p>We are happy to inform you that ${role==='student' ? 'you have ': 'your child has '} been successfully admitted to a stream in Easy Chemistry Academy</p>
	<p>
	Please find below the details of your stream:
	<ul>
		${Object.keys(details).reduce((acc, key) => acc + `<li>${keyLabelMap[key]}: ${details[key]}</li>`, '')}
	</ul>

	You will now be able to access the functionalities on the website which are limited currently to marking attendance. More features and interfaces are on the way! Stay tuned.
	<br/>
	</p>
	
<div style="display: flex; align-items: center;">
	<p>
		In quest for your success,<br/>
		Easy Chemistry Academy<br/>
		<a href="easychem.in">easychem.in</a>
	</p>
</div>
	`;
	return {subject, body};
}

export const studentAdmittedEmailForAdminGenerator = (studentName, details, keyLabelMap) => {
	const subject = "Student Admitted into Easy Chemistry Academy";
	const body = `Hello,<br/>
	<p>
		${studentName} has been successfully admitted to Easy Chemistry Academy. The details of his/her admission are as follows:
		<ul>
			${Object.keys(details).reduce((acc, key) => acc + `<li>${keyLabelMap[key]}: ${details[key]}</li>`, '')}
		</ul>
	</p>
	<p>
		In quest for your success,<br/>
		Easy Chemistry Academy<br/>
		<a href="easychem.in">easychem.in</a>
	</p>
</div>
	`;
	return {subject, body};
}
