import firebaseHttp from "./firebaseFunctions";
import qs from 'qs';


export const sendEmailToStudent = (dest, subject, body, attachments) => {
	const path = '/sendMailToStudent';
	const data = {subject, body, attachments};
	const params = {dest};
	return firebaseHttp({
		url: path,
		method: 'POST',
		params: params,
		headers: { 'content-type': 'application/x-www-form-urlencoded' },
		data: qs.stringify(data)
	})
}

export const sendEmailToAdmin = (subject, body, attachments) => {
	const path = '/sendMailToAdmin';
	const data = {subject, body, attachments};
	return firebaseHttp({
		url: path,
		method: 'POST',
		headers: { 'content-type': 'application/x-www-form-urlencoded' },
		data: qs.stringify(data)
	});
}
