import {attendanceOfStreamBatchAndDate, userDetails} from "services/firebase";

export const fetchTodaysAttendanceDetails = (stream, batch, date) => {
	return attendanceOfStreamBatchAndDate(stream, batch, date).once('value').then(snapshot => snapshot.val());
}

export const markAttendance = (stream, batch, date, studUid) => {
	return Promise.all([
    userDetails(studUid).update({lastPresentDate: date}),
	  attendanceOfStreamBatchAndDate(stream, batch, date).update({[studUid]: true})
  ]);
}
