import React, {Component} from 'react';
import {connect} from 'react-redux';

import _map from 'lodash/map';
import _get from 'lodash/get';

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {Spin} from "antd";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";

import PageHeader from "molecules/pageHeader";
import AttendanceCalendar from "organisms/AttendanceCalendar/AttendanceCalendar";

import {fetchAttendance } from "./Attendance.actions";
import {getStudentAttendanceEvents, getStudentAttendanceRowsData} from "helpers/attendance";

import {VIEW_OPTIONS, VIEW_OPTIONS_VS_LABEL} from "./Attendance.constants";

import styles from './Attendance.module.scss'
import AttendanceTable from "../../../../organisms/AttendanceTable";
import {toast} from "react-toastify";


class Attendance extends Component {
  state = {
    selectedView: VIEW_OPTIONS.TABLE_LIST_VIEW,
    attendanceEvents: [],
    loading: true,
  }
  
  componentDidMount(){
    const {stream, batch, uid} = _get(this.props, 'user')
    Promise.all([fetchAttendance(stream, batch)]).then(([attendanceData]) => {
      const attendanceEvents = getStudentAttendanceEvents(attendanceData, {uid, stream, batch}, styles.absentEvent);
      const attendanceRows = getStudentAttendanceRowsData(attendanceData, {uid, stream, batch});
      this.setState({
        attendanceEvents,
        attendanceRows,
        loading: false,
      })
    }).catch(() => toast.error('Error Fetching Attendance data...'));
  }
  
  renderCalendar = () => {
    const { attendanceEvents } = this.state;
    return <AttendanceCalendar
      className={styles.attendanceCalendar}
      events={attendanceEvents}
      isStudent
    />
  }
  
  handleViewChange = (event) => {
    const selectedView = _get(event, 'target.value');
    this.setState({
      selectedView,
    })
  }
  
  renderToolbar = () => {
    const { selectedView } = this.state;
    return (
      <div className={styles.toolbar}>
        <TextField
          select
          label="View"
          variant="outlined"
          onChange={this.handleViewChange}
          classes={{root: styles.viewSelectField}}
          value={selectedView}
          SelectProps={{
            MenuProps: { MenuListProps:{ classes: {root: styles.selectViewList}, dense: true } },
            autoWidth: true,
          }}
        >
          {_map(VIEW_OPTIONS, val => <MenuItem key={val} value={val}>{VIEW_OPTIONS_VS_LABEL[val]}</MenuItem>)}
        </TextField>
      </div>
    );
  }
  
  renderTableListView = () => {
    const { attendanceRows } = this.state;
    
    return (
      <AttendanceTable
        data={attendanceRows}
        isStudent
      />
    )
  }
  
  renderSelectedViewBody = () => {
    const { selectedView } = this.state;
    switch(selectedView) {
      case VIEW_OPTIONS.TABLE_LIST_VIEW:
        return this.renderTableListView();
      case VIEW_OPTIONS.CALENDAR_VIEW:
        return this.renderCalendar();
      default:
        return null;
    }
  }
  
  renderLoader = () => (
    <Spin
      spinning
      indicator={<LoadingOutlined/>}
      tip="Fetching Attendance Details"
      className={styles.loadingSpinner}
    />
  );
  
  renderBody = () => (
    <div className={styles.body}>
      {this.renderToolbar()}
      {this.renderSelectedViewBody()}
    </div>
  );
  
  render() {
    const { loading } = this.state;
    return (
      <div className={styles.attendancePage}>
        <PageHeader title="Attendance"/>
        { loading ? this.renderLoader() : this.renderBody()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: _get(state, 'auth.user'),
});


export default connect(mapStateToProps)(Attendance);
