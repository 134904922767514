import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {defaultMemoize} from "reselect";
import {saveAs} from 'file-saver';
import {toast} from "react-toastify";
import moment from 'moment';

import _get from 'lodash/get';
import _keys from 'lodash/keys';
import _noop from 'lodash/noop';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _isEmpty from 'lodash/isEmpty';

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import PageHeader from "molecules/pageHeader";
import {Button} from "@material-ui/core";

import { getBatchName, getCSVData } from "helpers/general";

import {ATTENDANCE_CSV_COLUMN_CONFIG, DATE_FORMAT} from 'constants/app.general';
import {
  STREAM_COLUMN_ID,
  BATCH_COLUMN_ID,
  DATE_COLUMN_ID,
} from 'constants/attendance';

import styles from './DownloadAttendanceModal.module.scss';

const memoizedGetKeys = defaultMemoize(_keys)

function DownloadAttendanceModal({
  streamMap,
  visible,
  onClose,
  attendanceRows,
  stream: defaultStream,
  batch: defaultBatch,
}) {
  const [stream, setStream] = useState(defaultStream);
  const [batch, setBatch] = useState(defaultBatch);
  const streams = memoizedGetKeys(streamMap);
  const batches = memoizedGetKeys(_get(streamMap, [stream, 'batches']));
  
  const downloadAttendance = () => {
    const parsedBatch = getBatchName(batch);
    const filteredData = _filter(attendanceRows, (row) => row[STREAM_COLUMN_ID] === stream && row[BATCH_COLUMN_ID] === parsedBatch);
    if (_isEmpty(filteredData)) return toast.error('This Stream and Batch have no Attendance')
    const sortedData = _sortBy(filteredData, (row) => moment(row[DATE_COLUMN_ID], DATE_FORMAT).toDate())
    const csvData = getCSVData(sortedData, ATTENDANCE_CSV_COLUMN_CONFIG);
    const csvFile = new File([csvData], `${stream}_${batch}_attendance.csv`, { type: 'text/csv'});
    saveAs(csvFile);
  }
  
  const renderHeader = () => (
    <PageHeader
      title="Download Attendance"
      hasBack
      handleGoBack={onClose}
      className={styles.header}
      backIconClassName={styles.headerBackButton}
    />
  );
  
  const renderFields = () => (
    <div className={styles.fieldsContainer}>
      <TextField
        select
        label="Stream"
        value={stream}
        variant="outlined"
        classes={{root: styles.selectField}}
        onChange={e => {setStream(e.target.value); setBatch(undefined);}}
        SelectProps={{
          MenuProps: { MenuListProps:{ classes: {root: styles.selectOption}, dense: true } },
        }}
      >
        {streams.map(val => <MenuItem key={val} value={val}>{val}</MenuItem>)}
      </TextField>
      <TextField
        select
        label="Batch"
        value={batch}
        variant="outlined"
        classes={{root: styles.selectField}}
        onChange={e => setBatch(e.target.value)}
        SelectProps={{
          MenuProps: { MenuListProps:{ classes: {root: styles.selectOption}, dense: true } },
        }}
      >
        {batches.map(val => <MenuItem key={val} value={val}>{getBatchName(val)}</MenuItem>)}
      </TextField>
    </div>
  )
  
  const renderBody = () => (
    <div className={styles.body}>
      {renderFields()}
      <Button
        variant="outlined"
        classes={{root: styles.downloadButtonRoot, label: styles.downloadButtonLabel}}
        onClick={downloadAttendance}
      >
        Download Attendance
      </Button>
    </div>
  )
  
  return (
    <Dialog
      fullWidth
      open={visible}
    >
      {renderHeader()}
      {renderBody()}
    </Dialog>
  );
}

DownloadAttendanceModal.propTypes = {
  streamMap: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  attendanceRows: PropTypes.array,
};

DownloadAttendanceModal.defaultProps = {
  streamMap: {},
  visible: false,
  onClose: _noop,
  attendanceRows: [],
};

export default DownloadAttendanceModal;
