import _map from 'lodash/map';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import _reduce from 'lodash/reduce';
import _join from 'lodash/join';

export const getBatchName = (batch = '') => {
  const [month, year, topic] = batch.split("_");
  return `${month}, ${year} - ${topic}`;
};

const getCSVCellData = (data) => {
  if (_isNil(data)) return '';
  if (_isArray(data)) return `"${_reduce(data, (acc, dataItem) => acc + `${getCSVCellData(dataItem)}, `, '')}"`;
  if (_isObject(data)) return JSON.stringify(data);
  return data.toString();
}

export const getCSVData = (rawData, columnConfig) => {
  const columnHeaders = _map(columnConfig, 'name');
  const data = [
    columnHeaders,
    ..._map(rawData, row => _map(columnConfig, ({id, getValue}) => getValue ? getValue(_get(row, id), row) : getCSVCellData(_get(row, id)))),
  ];
  return _join(_map(data, row => _join(row,';')), '\n');
};

export const convertMillisecondsToHoursAndMins = (milliseconds)  => {
  const totalMinutes = milliseconds/(60*1000)
  const remainingMinutes = totalMinutes%60;
  const hours = (totalMinutes - remainingMinutes)/60;
  if (!hours && !remainingMinutes) {
    return '0 hrs';
  }
  else if (hours && remainingMinutes) {
    return `${hours} hrs ${remainingMinutes} mins`;
  }
  else if (remainingMinutes) {
    return `${remainingMinutes} mins`;
  }
  return `${hours} hrs`;
};
