import React, {Component} from 'react';
import {Avatar, Button, Layout, Popover} from 'antd';
import Menu from 'atoms/navigationMenu'
import { withRouter } from 'react-router-dom'
import styles from "./SideBar.module.css";
import _get from "lodash/get";
import {compose} from "recompose";
import {connect} from "react-redux";
import {logoutUser} from "actions/login.actions";
import logo from 'public/logo.svg';

const { Sider } = Layout;

class SideBar extends Component {
	renderUserPopoverTitle = () => {
		const displayName = _get(this.props, ['user', 'studentName']) || _get(this.props, ['user', 'name']);
		return(
			<div className={styles.popOverTitle}>
				<h3>{displayName}</h3>
			</div>
		)
	}
	
	handleLogoutSuccess = () => {
		const {history} = this.props;
		history.push('/login');
	}
	
	handleLogoutRequest = () => {
		const {logoutUser} = this.props;
		logoutUser().then(this.handleLogoutSuccess)
	}
	
	renderUserPopoverContent = () => {
		const { isLoggingOut} = this.props;
		return (
			<div className={styles.popoverContent}>
				<Button
					type="link"
					onClick={this.handleLogoutRequest}
					loading={isLoggingOut}
				>
					Sign Out
				</Button>
			</div>
		)
	}
	render() {
		const displayName = _get(this.props, ['user', 'studentName']) || _get(this.props, ['user', 'name']);
		const initials = displayName? displayName.split(' ').reduce((acc,namePart)=>namePart.length?acc+namePart[0]:acc,''): undefined;
		const {handleLogoClick, menuItems} = this.props;
		return (
			<Sider
				collapsible
				collapsed={!!this.props.collapsed}
				collapsedWidth={0}
				width={165}
				trigger={null}
			>
				<div className={styles.sideBar}>
					<img
						src={logo}
						alt=""
						className={styles.logoImg}
						onClick={handleLogoClick}
					/>
					<Menu
						mode="inline"
						sidebar={true}
						handleMenuClick={this.props.handleMenuClick}
						menuItems={menuItems}
					/>
					<Popover
						trigger="click"
						title={this.renderUserPopoverTitle()}
						placement="rightBottom"
						content={this.renderUserPopoverContent()}
					>
						<Button
							type="link"
							icon={
								<Avatar
									style={{backgroundColor: "grey", verticalAlign: 'middle'}}
									size="large"
									// icon={<UserOutlined/>}
								>
									{initials}
								</Avatar>
							}
							key="user"
							className={styles.userAvatarButton}
						/>
					</Popover>
				</div>
				
			</Sider>
		);
	}
}

const mapStateToProps = state => ({
	user: _get(state, 'auth.user'),
	isLoggingOut: _get(state, 'auth.isLoggingOut')
});

const mapDispatchToProps = {
	logoutUser,
}

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(SideBar);
