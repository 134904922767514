import React from 'react';
import * as images from 'public';

export const streams = ['JEE Mains & Advanced','NEET','SAT','AP Chemistry',['IX',<sup key={'9th'}>th</sup>,' and X',<sup key={'10th'}>th</sup>,' Foundation'],'Pharma Industry'];

export const sections = [
	{
		title: 'Gain a Foundation',
		text: 'There’s a marked difference between the success of students who approach chemistry logically versus those who learn by cramming. During classes, students will gain a sound understanding of fundamental concepts to change the way they approach problems',
		image: images.chemBoardImg,
		imagePercent: 55,
		mobileImagePercent: 80,
	},
	{
		title: 'Practise Comprehensively',
		text: 'The classes are designed with a winning pedagogy to enable students to approach complex problems by improving and sharpening their analytical skills. During the program, students will work on best-in-class materials to develop an analytical ability to ace tests',
		image: images.periodicTableImg,
		imagePercent: 50,
		mobileImagePercent: 40,
	},
	{
		title: 'Receive Personalized Coaching',
		text: 'With a focus on developing a problem-solving mindset through conceptual clarity and constant support, students receive personal attention to bring the best out of them. The classes designed for small groups to enable collaboration and inclusive learning',
		image: images.personalised,
		imagePercent: 55,
		mobileImage: images.mobilePersonalised,
		mobileImagePercent: 85,
		fullImageSizePercent: 100,
	}
];

export const reasons = [
	{
		text: ['An expert member of the',  <mark>Indian Pharmacopoeia Commission</mark>, ', Dr. Padmaja is an accomplished academic with', <mark>25+ years of teaching and industry experience</mark>, 'across levels up to M.Sc Chemistry'],
		title: 'Expertise',
	},
	{
		text: ['After working in the industry on', <mark>drug research, quality control, training, and skill-sessions</mark>, 'in institutions such as BITS Pilani, IIT Hyderabad and Dr. Reddy’s, she has reconnected with her passion for teaching'],
		title: 'Experience',
	},
	{
		text: ['With successful stints in preparing students for competitive exams, she packs classes with', <mark>logical reasoning, combining test specific instruction with the best of material</mark>, ', tailoring content and delivery for each student'],
		title: 'Excellence',
	},
	{
		text: ['Skilled in', <mark> identifying strengths and weaknesses of students</mark>,', her student-centric classes constitute a comprehensive program to ', <mark>make Chemistry lovable and interesting</mark>],
		title: 'Effectiveness',
	},
];

export const aboutClasses = [
	{
		text: 'Available in daily and weekly formats'
	},
	{
		text: ['Classes conducted online on Zoom'],
		tip: <p>(Unsure about Zoom's privacy or security. Click <a href="https://foundation.mozilla.org/en/privacynotincluded/products/zoom/" target="_blank"  rel="noopener noreferrer" >here</a>)</p>
	},
	{
		text: 'Daily Assignments and practice papers for students'
	},
	{
		text: 'Guidance for students on research projects'
		
	},
	{
		text: 'Crash course and long-term packages offered'
	},
]
