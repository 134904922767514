import React, {Component} from 'react';
import cx from "classnames";
import {compose} from "recompose";
import {connect} from "react-redux";
import produce from 'immer';

import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import {LoadingOutlined} from "@ant-design/icons";
import CheckOutlined from "@material-ui/icons/CheckOutlined";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import {PlusCircleOutlined} from "@ant-design/icons";
import {toast} from "react-toastify";

import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _remove from 'lodash/remove';
import _set from 'lodash/set';
import _difference from 'lodash/difference';

import PageHeader from "molecules/pageHeader/PageHeader";
import withIsMobile from "connectors/withIsMobile";
import {getBatchName} from "helpers/general";

import {MONTHS, STREAMS, YEARS} from "constants/app.general";
import {REDUX_PATH_KEY as adminReduxKey} from "pages/adminPages/constants/adminPages.general";
import {REDUX_PATH_KEY as adminDashboardReduxKey} from "pages/adminPages/pages/adminDashboard/constants/adminDashboard.general";

import { submitBatchDetails} from "./AddStreamModal.actions";
import {fetchRegisteredStudentList, getStreamData} from "../../../../AdminDashboard.actions";

import styles from "./AddStreamModal.module.scss";

const NEW_STREAM = 'newStream';
const NEW_BATCH = 'newBatch';

class AddStreamModal extends Component {
	constructor(props) {
		super(props);
		const { defaultStudents, allStudents, defaultStream, defaultBatch } = props;
		this.studentMap = _keyBy(allStudents, 'uid');
		this.state = {
			isNewStreamFieldVisible: false,
			formValues: {
				students: defaultStudents ? defaultStudents : [],
				batchDetails: {},
        batch: defaultBatch,
        stream: defaultStream,
			}
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.allStudents !== this.props.allStudents){
			this.studentMap = _keyBy(this.props.allStudents, 'uid');
		}
	}
	
	handleStreamChange = (event) => {
		const {isNewStreamFieldVisible} = this.state;
		const selectedStream = _get(event, 'target.value');
		if(selectedStream === NEW_STREAM && !isNewStreamFieldVisible){
			this.setState(state => ({
				isNewStreamFieldVisible: true,
				formValues: {
					...state.formValues,
					stream: '',
				}
			}))
		}
		else{
			this.setState(state => ({
				isNewStreamFieldVisible: false,
				formValues: {
					...state.formValues,
					stream: selectedStream,
				}
			}))
		}
	}
	
	handleNewStreamFieldChange = (event) => {
		const value = event.target.value;
		this.setState(state => ({
			formValues: {
				...state.formValues,
				stream: value,
			}
		}));
	}
	
	renderStreamSection = () => {
		const {isNewStreamFieldVisible, formValues: {stream}} = this.state;
		const {fetchedStreams} = this.props;
		const streamsList = STREAMS.concat(_difference(fetchedStreams, STREAMS));
		return (
			<div className={cx(styles.formSection)}>
				<h3 className={cx(styles.sectionHeader)}>Stream Details</h3>
				<TextField
					select
					label="Stream"
					variant="outlined"
					onChange={this.handleStreamChange}
					classes={{root: styles.streamSelectField}}
					value={isNewStreamFieldVisible? NEW_STREAM: stream}
					SelectProps={{MenuProps: { MenuListProps:{ classes: {root: styles.selectMenuList}, dense: true } }}}
				>
					{streamsList.map(val => <MenuItem key={val} value={val}>{val}</MenuItem>)}
					<MenuItem key={NEW_STREAM} value={NEW_STREAM}> <PlusCircleOutlined style={{marginRight: '8px'}}/>  New Stream</MenuItem>
				</TextField>
				{isNewStreamFieldVisible && <TextField
					label="New Stream Name"
					value={stream}
					classes={{root: cx(styles.newStreamField)}}
					onChange={this.handleNewStreamFieldChange}
				/>}
			</div>
		);
	}
	
	handleBatchChange = (event) => {
		const {isNewBatchFieldsVisible} = this.state;
		const selectedBatch = _get(event, 'target.value');
		if(selectedBatch === NEW_BATCH && !isNewBatchFieldsVisible){
			this.setState(state => ({
				isNewBatchFieldsVisible: true,
				formValues: {
					...state.formValues,
					batch: '',
					batchDetails: undefined,
				}
			}))
		}
		else{
			this.setState(state => ({
				isNewBatchFieldsVisible: false,
				formValues: {
					...state.formValues,
					batch: selectedBatch,
					batchDetails: {
						year: '',
						month: '',
						topic: '',
					}
				}
			}));
		}
	}
	
	handleNewBatchDetailsChange = key => (event) => {
		const value = event.target.value;
		this.setState( produce(draft => {
			_set(draft, ['formValues', 'batchDetails', key], value);
		}))
	}
	
	renderNewBatchDetailsFields = () => {
		return (<div className={styles.newBatchDetails}>
			<TextField
				select
				label="Month"
				variant="outlined"
				onChange={this.handleNewBatchDetailsChange('month')}
				SelectProps={{MenuProps: { MenuListProps:{ classes: {root: styles.selectMenuList}, dense: true } }}}
				classes={{root: cx(styles.batchMonthField)}}
			>
				{MONTHS.map(val => <MenuItem key={val} value={val}>{val}</MenuItem>)}
			</TextField>
			<TextField
				select
				label="Year"
				variant="outlined"
				onChange={this.handleNewBatchDetailsChange('year')}
				SelectProps={{MenuProps: { MenuListProps:{ classes: {root: styles.selectMenuList}, dense: true } }}}
				classes={{root: cx( styles.batchYearField)}}
				>
				{YEARS.map(val => <MenuItem key={val} value={val}>{val}</MenuItem>)}
			</TextField>
			<TextField
				label="Batch Topic"
				onChange={this.handleNewBatchDetailsChange('topic')}
				classes={{root: cx( styles.batchTopicField)}}
			/>
		</div>)
	}
	
	renderBatchSection = () => {
		const {isNewBatchFieldsVisible, formValues: {batch, stream}} = this.state;
		const {streamMap} = this.props;
		const currentBatchesList = Object.keys(_get(streamMap, [stream, 'batches'], {}));
		return (
			<div className={cx(styles.formSection)}>
				<h3 className={cx(styles.sectionHeader)}>Batch Details</h3>
				<TextField
					select
					label="Batch"
					variant="outlined"
					onChange={this.handleBatchChange}
					classes={{root: cx(styles.batchSelectField)}}
					value={isNewBatchFieldsVisible ? NEW_BATCH : batch}
					SelectProps={{MenuProps: {MenuListProps: {classes: {root: cx(styles.selectMenuList)}, dense: true}}}}
				>
					{currentBatchesList.map(val => <MenuItem key={val} value={val}>{getBatchName(val)}</MenuItem>)}
					<MenuItem key={NEW_BATCH} value={NEW_BATCH}> <PlusCircleOutlined style={{marginRight: '8px'}}/> New Batch</MenuItem>
				</TextField>
				{isNewBatchFieldsVisible && this.renderNewBatchDetailsFields()}
			</div>
		);
	}
	
	handleStudentFieldChange = (event) => {
		const selectedStudents = event.target.value;
		this.setState(produce(draft => {
			draft.formValues.students = selectedStudents;
		}))
	}
	
	handleRemoveStudent = uid => () => {
		this.setState(produce(draft => {
			const {students} = draft.formValues;
			_remove(students, studUid => studUid === uid);
		}))
	}
 
	getStudentChipLabel = (uid) => {
	  const studentDetails = this.studentMap[uid];
	  const { stream, batch } =  studentDetails || {};
	  return (
	    <div className={styles.studentChipLabel}>
        <div className={styles.studentChipStudentName}>{_get(studentDetails, 'studentName')}</div>
        <div>{!stream || !batch ? 'New Student' : stream}</div>
      </div>
    );
  }
  
	renderStudentSection = () => {
		const {allStudents} = this.props;
		const {students} = this.state.formValues;
		return (
			<div className={cx(styles.formSection)}>
				<h3 className={cx(styles.sectionHeader)}>Students</h3>
				<TextField
					select
					multiline
					SelectProps={{
						multiple: true,
						MenuProps: { MenuListProps:{ classes: {root: styles.selectMenuList}, dense: true } }
					}}
					label="Students"
					variant="outlined"
					value={students}
					classes={{root: cx(styles.studentSelect)}}
					onChange={this.handleStudentFieldChange}
				>
					{allStudents.map(({studentName, parentName, uid}) => <MenuItem key={studentName+parentName} value={uid}>{`${studentName} - ${parentName}`}</MenuItem>)}
				</TextField>
				<div className={styles.selectedStudentsContainer}>
					{students.map((uid) =>
							<Chip
								label={this.getStudentChipLabel(uid)}
								onDelete={this.handleRemoveStudent(uid)}
								variant="outlined"
								key={uid}
								classes={{root: styles.studentChip, label: styles.studentChipLabelContainer}}
							/>
					)}
				</div>
			</div>
		);
	}
	
	handleSubmitSuccess = () => {
	  const { fetchRegisteredStudentList: fetchRegisteredStudentListAction, getStreamData: getStreamDataAction, onClose } = this.props;
    toast.success('Successfully Added Students to Stream')
		this.setState({isSubmitting: false});
    fetchRegisteredStudentListAction();
    getStreamDataAction();
    onClose();
	}
	
	handleSubmitFailure = () => {
    toast.error('Error Occurred')
		this.setState({isSubmitting: false});
	}
	
	handleSubmit = () => {
		const { streamMap } = this.props;
		const {formValues} = this.state;
		const finalFormValues = formValues.batch ? formValues : {
			...formValues,
			batch: `${_get(formValues,['batchDetails', 'month'])}_${_get(formValues,['batchDetails', 'year'])}_${_get(formValues,['batchDetails', 'topic'])}`
		}
		this.setState({isSubmitting: true});
		submitBatchDetails(finalFormValues, streamMap, this.studentMap).then(this.handleSubmitSuccess).catch(this.handleSubmitFailure);
	}
	
	canSubmit = () => {
		const {stream, students, batch, batchDetails} = _get(this.state, 'formValues');
		return !(stream && students.length && (batch || (_get(batchDetails, 'month') && _get(batchDetails, 'year') && _get(batchDetails, 'topic'))))
	}
	
	renderSubmitButton = () => {
		const {isSubmitting} = this.state;
		const isDisabled = this.canSubmit();
		return(<Button
			variant="contained"
			classes={{root: cx(styles.submitButton, isDisabled && styles.disabledSubmitButton)}}
			onClick={this.handleSubmit}
			disabled={isDisabled}
			startIcon={ isSubmitting ? <LoadingOutlined/> : <CheckOutlined/>}
		>
			Submit
		</Button>)
	}
	
	renderForm = () => {
		return (
			<div className={cx(styles.formContainer)}>
				{this.renderStreamSection()}
				{this.renderBatchSection()}
				{this.renderStudentSection()}
				{this.renderSubmitButton()}
			</div>
		)
	}
	
	render() {
		const {visible, isMobile, onClose} = this.props;
		return (
			<Dialog
				fullWidth={!isMobile}
				fullScreen={isMobile}
				open={visible}
				classes={{ paper: styles.addStreamDialog}}
			>
				<PageHeader
					title="Add Stream Details"
					hasBack
					handleGoBack={onClose}
					className={styles.header}
					backIconClassName={styles.backIcon}
				/>
				{this.renderForm()}
			</Dialog>
		);
	}
}

const mapStateToProps = state => ({
	allStudents: _get(state, [adminReduxKey, adminDashboardReduxKey, 'registeredStudentsList']),
	fetchedStreams: _get(state, [adminReduxKey, adminDashboardReduxKey, 'streams']),
	streamMap: _get(state, [adminReduxKey, adminDashboardReduxKey, 'streamMap']),
});

const mapDispatchToProps = {
  fetchRegisteredStudentList,
  getStreamData,
}

export default compose(
	withIsMobile,
	connect(mapStateToProps, mapDispatchToProps),
) (AddStreamModal);
