import React from 'react';
import Routes from './routes/routes'
import { withRouter} from "react-router-dom";
import {compose} from 'recompose';
import {ToastContainer, Slide} from "react-toastify";
import cx from "classnames";

import _get from 'lodash/get'
import {connect} from "react-redux";
import withIsMobile from "../../connectors/withIsMobile";
import AbilityContext from 'services/abilityContext';
import defineAbilityFor from "../../helpers/defineAbility";

function App({userPermission, isMobile}) {
	return (
		<React.Fragment>
			<AbilityContext.Provider value={defineAbilityFor(userPermission)}>
				<div className={cx(isMobile && 'mobile')} style={{height: '100%'}}>
					<Routes/>
				</div>
			</AbilityContext.Provider>
			<ToastContainer
				position="bottom-center"
				autoClose={1500}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover={false}
				style={{textAlign: 'center'}}
				transition={Slide}
			/>
		</React.Fragment>
	);
}

const mapStateToProps = state => ({
	userPermission: _get(state, 'auth.user.permission'),
})

export default compose(
	connect(mapStateToProps),
	withRouter,
	withIsMobile,
)(App);
