import React from 'react';
import cx from 'classnames';

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import _map from "lodash/map";

import {
  START_TIME_COLUMN_ID,
  END_TIME_COLUMN_ID,
  STREAM_COLUMN_ID,
  BATCH_COLUMN_ID,
  TOPIC_COLUMN_ID,
  DATE_COLUMN_ID,
  DURATION_COLUMN_ID,
  PRESENT_COLUMN_ID,
  ABSENT_COLUMN_ID,
  ATTENDANCE_STATUS_COLUMN_ID,
} from 'constants/attendance';

import DateTimeRenderer from "../../molecules/DateTimeRenderer";
import StudentListRenderer from "../../molecules/StudentListRenderer";

import styles from "./attendanceTable.module.scss";
import AttendanceStatus from "../../molecules/AttendanceStatus";

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, Header }, className
}) {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  
  return (
    <TextField
      label={Header}
      variant="outlined"
      select
      onChange={e => {
        const value = e.target.value || undefined;
        setFilter(value === "all" ? "" : value);
      }}
      classes={{root: cx(styles.field, className)}}
      value={filterValue}
      SelectProps={{
        MenuProps: { MenuListProps:{ classes: {root: styles.selectField}, dense: true } },
        defaultValue: "all",
        autoWidth: true,
      }}
    >
      <MenuItem key={'all'} value="all">All</MenuItem>
      {_map(options, val => <MenuItem key={val} value={val}>{val}</MenuItem>)}
    </TextField>
  )
}


const START_TIME_COLUMN = {
  Header: 'Start Time',
  accessor: START_TIME_COLUMN_ID,
  Cell: React.memo((props) => (<DateTimeRenderer date={false} {...props}/>)),
  sortType: 'datetime',
  disableFilters: true,
  width: 70,
};

const END_TIME_COLUMN = {
  Header: 'End Time',
  accessor: END_TIME_COLUMN_ID,
  Cell: React.memo((props) => (<DateTimeRenderer date={false}  {...props}/>)),
  sortType: 'datetime',
  disableFilters: true,
  width: 70,
};

const STREAM_COLUMN = {
  Header: 'Stream',
  accessor: STREAM_COLUMN_ID,
  Filter: SelectColumnFilter,
  filter: 'includes',
  width: 110,
};

const BATCH_COLUMN = {
  Header: 'Batch',
  accessor: BATCH_COLUMN_ID,
  Filter: SelectColumnFilter,
  filter: 'includes',
  width: 110,
};

const TOPIC_COLUMN = {
  Header: 'Topic',
  accessor: TOPIC_COLUMN_ID,
  disableFilters: true,
};

const DATE_COLUMN = {
  Header: 'Date',
  accessor: DATE_COLUMN_ID,
  Cell: React.memo((props) => (<DateTimeRenderer time={false}  {...props}/>)),
  sortType: 'datetime',
  disableFilters: true,
  width: 110,
};

const DURATION_COLUMN = {
  Header: 'Duration',
  accessor: DURATION_COLUMN_ID,
  disableFilters: true,
  width: 70,
};

const PRESENT_COLUMN = {
  Header: 'Present',
  accessor: PRESENT_COLUMN_ID,
  Cell: StudentListRenderer,
  disableFilters: true,
};

const ABSENT_COLUMN = {
  Header: 'Absent',
  accessor: ABSENT_COLUMN_ID,
  Cell: StudentListRenderer,
  disableFilters: true,
};

const ATTENDANCE_STATUS_COLUMN = {
  Header: 'Attendance Status',
  accessor: ATTENDANCE_STATUS_COLUMN_ID,
  Cell: AttendanceStatus,
  disableFilters: true,
}

const BASE_COLUMNS = [
  DATE_COLUMN,
  STREAM_COLUMN,
  BATCH_COLUMN,
  START_TIME_COLUMN,
  END_TIME_COLUMN,
  DURATION_COLUMN,
  TOPIC_COLUMN,
];

export const STUDENT_COLUMNS = [
  ...BASE_COLUMNS,
  ATTENDANCE_STATUS_COLUMN,
];

export const ADMIN_COLUMNS = [
  ...BASE_COLUMNS,
  PRESENT_COLUMN,
  ABSENT_COLUMN,
];
