import { combineReducers } from 'redux';
import appReducer from './app.reducer';
import adminReducer from 'pages/adminPages/reducers';
// import studentReducer from 'pages/studentPages/reducers';
import commonPagesReducer from 'pages/commonPages/reducers'
import loginReducer from './login.reducer'

const rootReducer = combineReducers({
	app: appReducer,
	admin: adminReducer,
	auth: loginReducer,
	common: commonPagesReducer,
	// student: studentReducer,
});

export default rootReducer;
