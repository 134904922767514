import React, {Component} from 'react';
import cx from "classnames";

import OpenAttendance from "./OpenAttendance/OpenAttendance";

import styles from './AttendanceDashboard.module.scss';
import _get from "lodash/get";
import {REDUX_PATH_KEY as adminReduxKey} from "../../../../constants/adminPages.general";
import {REDUX_PATH_KEY as adminDashboardReduxKey} from "../../constants/adminDashboard.general";
import {updateAttendanceStatus, resetOpenAttendanceStatus} from "../../AdminDashboard.actions";
import {connect} from "react-redux";

class AttendanceDashboard extends Component {
	
	render() {
		const {
			className,
			noHeader,
			streams,
			streamMap,
			isOpeningAttendance,
			openAttendanceStatus,
      updateAttendanceStatus,
			resetOpenAttendanceStatus
		} = this.props;
		return (
			<div className={cx(className)}>
				<div className={cx(styles.container)}>
					{!noHeader && <h2>Mark Attendance</h2>}
					<OpenAttendance
						streams={streams}
						streamMap={streamMap}
						isOpeningAttendance={isOpeningAttendance}
						openAttendanceStatus={openAttendanceStatus}
            updateAttendanceStatus={updateAttendanceStatus}
						onAttendanceStatusUpdate={resetOpenAttendanceStatus}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	streams: _get(state, [adminReduxKey, adminDashboardReduxKey, 'streams'], []),
	streamMap: _get(state, [adminReduxKey, adminDashboardReduxKey, 'streamMap'], {}),
	isOpeningAttendance: _get(state, [adminReduxKey, adminDashboardReduxKey, 'isOpeningAttendance']),
	openAttendanceStatus: _get(state, [adminReduxKey, adminDashboardReduxKey, 'openAttendanceStatus']),
});

const mapDispatchToProps = {
	updateAttendanceStatus,
	resetOpenAttendanceStatus,
}


export default connect(mapStateToProps, mapDispatchToProps)(AttendanceDashboard);
