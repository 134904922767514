import TextField from "@material-ui/core/TextField";
import _property from 'lodash/property'
import {LAYOUT_TYPES} from "organisms/form";
import MenuItem from '@material-ui/core/MenuItem';
import React from "react";
import {LEVELS_OF_STUDY} from "../../../../constants/app.general";

const inputEventToValue = _property('target.value')

const renderMenuItem = ({label, value}) => {
	return (
		<MenuItem key={label} value={value}>
			{label}
		</MenuItem>
	)
}

const studentName = {
	Renderer: TextField,
	key: 'studentName',
	type: LAYOUT_TYPES.FIELD,
	required: true,
	valueFromResponse: inputEventToValue,
	renderOptions: {
		variant: 'outlined',
		label: 'Student\'s Name',
		required: true,
		size: 'small',
		style: {
			borderColor: 'white',
			color: 'white'
		},
		InputProps: {
			style: {backgroundColor: "rgba(255,255,255,0.3)"},
		}
	},
};

const parentName = {
	Renderer: TextField,
	key: 'parentName',
	type: LAYOUT_TYPES.FIELD,
	required: true,
	valueFromResponse: inputEventToValue,
	renderOptions: {
		variant: 'outlined',
		label: 'Parent\'s Name',
		required: true,
		size: 'small',
		InputProps: {
			style: {backgroundColor: "rgba(255,255,255,0.3)"},
		}
	},
}

const levelOfStudy = {
	Renderer: TextField,
	key: 'levelOfStudy',
	type: LAYOUT_TYPES.FIELD,
	required: true,
	valueFromResponse: inputEventToValue,
	children: LEVELS_OF_STUDY.map(levelOfStudy => ({label: levelOfStudy, value: levelOfStudy})).map(renderMenuItem),
	renderOptions: {
		variant: 'outlined',
		label: 'Level Of Study',
		select: true,
		required: true,
		size: 'small',
		style: {
			textAlign: 'left'
		},
		InputProps: {
			style: {backgroundColor: "rgba(255,255,255,0.3)"},
		}
	},
};

const institute = {
	Renderer: TextField,
	key: 'institute',
	type: LAYOUT_TYPES.FIELD,
	required: true,
	valueFromResponse: inputEventToValue,
	renderOptions: {
		variant: 'outlined',
		label: 'School/College',
		required: true,
		size: 'small',
		InputProps: {
			style: {backgroundColor: "rgba(255,255,255,0.3)"},
		}
	}
}

const mobileNumber = {
	Renderer: TextField,
	key: 'mobileNumber',
	type: LAYOUT_TYPES.FIELD,
	required: true,
	valueFromResponse: inputEventToValue,
	renderOptions: {
		variant: 'outlined',
		label: 'Mobile Number',
		required: true,
		size: 'small',
		InputProps: {
			style: {backgroundColor: "rgba(255,255,255,0.3)"},
		}
	},
};

const email = {
	Renderer: TextField,
	key: 'parentEmail',
	type: LAYOUT_TYPES.FIELD,
	required: true,
	valueFromResponse: inputEventToValue,
	renderOptions: {
		variant: 'outlined',
		label: 'Parent\'s Email',
		type: 'email',
		required: true,
		size: 'small',
		InputProps: {
			style: {backgroundColor: "rgba(255,255,255,0.3)"},
		}
	},
};

const city = {
	Renderer: TextField,
	key: 'city',
	type: LAYOUT_TYPES.FIELD,
	required: true,
	valueFromResponse: inputEventToValue,
	renderOptions: {
		variant: 'outlined',
		label: 'City',
		required: true,
		size: 'small',
		InputProps: {
			style: {backgroundColor: "rgba(255,255,255,0.3)"},
		}
	},
};

export const getDetailsFormLayout = () => ([
	studentName,
	parentName,
	levelOfStudy,
	institute,
	mobileNumber,
	email,
	city,
]);
