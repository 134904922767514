import ACTION_TYPES from "actions/app.actionTypes";
import _transform from "lodash/transform";
import {handleActions} from 'redux-actions';
const INITIAL_STATE = {
	classTypes: [],
	loadError: undefined,
};

const handleStoreClassTypesRequest = (state, {payload}) => ({
	...state,
	classTypes: _transform(payload, (acc, _, key) => acc.push({label: key, value: key}), []),
});



const actionHandlers = {
	[ACTION_TYPES.LOAD_CLASS_TYPES_SUCCESS]: handleStoreClassTypesRequest,
}
export default handleActions(actionHandlers, INITIAL_STATE);
