import ACTION_TYPES from "./createAccount.actionTypes";
import {firebaseAppAuth, userDetails,} from "services/firebase";
import PERMISSIONS from "constants/permissions";
import {toast} from "react-toastify";
import {
	registeredStudentEmailForAdminGenerator,
	registeredStudentEmailForStudentGenerator
} from "helpers/emailGenerator";
import {STUDENT_DETAILS_KEY_LABEL_MAP} from "constants/app.general";
import {sendEmailToAdmin, sendEmailToStudent} from "services/emailServices";

const getErrorComponentAndMessage = (code) => {
	switch (code) {
		case 'auth/email-already-in-use':
			return ['email', 'Email already registered'];
		case 'auth/invalid-email':
			return ['email', '(Invalid email. Choose another'];
		case 'auth/weak-password':
			return ['password', 'Weak Password. Choose another']
		default:
			return [null, 'Unknown Error Occurred']
	}
}

export const handleEmailCheckResponse = dispatch => response => {
	if (!response) {
		dispatch({type: ACTION_TYPES.EMAIL_CHECK_FAILURE});
		return;
	}
	if (response.length) {
		dispatch({
			type: ACTION_TYPES.EMAIL_CHECK_FAILURE,
			errorMessage: "Email already registered",
			errorComponent: 'email',
		});
		return;
	}
	dispatch({type: ACTION_TYPES.EMAIL_CHECK_SUCCESS});
}

export const handleEmailCheckFailure = (dispatch) => () => {
	dispatch({type: ACTION_TYPES.EMAIL_CHECK_FAILURE});
}

export const checkIfEmailAvailable = (email) => dispatch => {
	dispatch({type: ACTION_TYPES.EMAIL_CHECK_REQUEST});
	return firebaseAppAuth.fetchSignInMethodsForEmail(email)
		.then(handleEmailCheckResponse(dispatch))
		.catch(handleEmailCheckFailure(dispatch));
}

const handleCreateUserSuccess = dispatch => () => {
	dispatch({type: ACTION_TYPES.CREDENTIALS_SUBMIT_SUCCESS});
}

const handleCreateUserFailure = dispatch => (error) => {
	const [errorComponent, errorMessage] = getErrorComponentAndMessage(error.code);
	if(!errorComponent){
		toast.error(errorMessage);
	}
	dispatch({type: ACTION_TYPES.CREDENTIALS_SUBMIT_FAILURE, payload: {errorComponent, errorMessage}});
	
}

export const submitUserCredentials = (email, password) => dispatch => {
	dispatch({ type: ACTION_TYPES.CREDENTIALS_SUBMIT_REQUEST });
	return firebaseAppAuth.createUserWithEmailAndPassword(email, password)
		.then(handleCreateUserSuccess(dispatch))
		.catch(handleCreateUserFailure(dispatch));
}

const submitUserDetailsSuccess = dispatch => () => {
	dispatch({type: ACTION_TYPES.DETAILS_SUBMIT_SUCCESS});
}

const handleSubmitUserDetailsError = (dispatch, user) => error => {
	if (user) {
		user.delete();
	}
	dispatch({
		type: ACTION_TYPES.DETAILS_SUBMIT_FAILURE,
		error: error,
	});
	throw new Error('User Details Submit Error ');
}

const handleSignInSuccess = () => {
	return firebaseAppAuth.signOut();
}

const uploadUserDetails = (dispatch, details) => ({user}) => {
	const finalDetails = {
		...details,
		permission: PERMISSIONS.STUDENT,
		studentEmail: user.email,
	}
	const {subject: adminEmailSubject, body: adminEmailBody} = registeredStudentEmailForAdminGenerator(details, STUDENT_DETAILS_KEY_LABEL_MAP);
	const {subject: studentEmailSubject, body: studentEmailBody, attachments: studentEmailAttachments} = registeredStudentEmailForStudentGenerator(details.studentName, details, STUDENT_DETAILS_KEY_LABEL_MAP, 'student');
	const {subject: parentEmailSubject, body: parentEmailBody, attachments: parentEmailAttachments} = registeredStudentEmailForStudentGenerator(details.parentName, details,STUDENT_DETAILS_KEY_LABEL_MAP, 'parent');
	const emailPromises = [
		sendEmailToStudent(details.parentEmail, parentEmailSubject, parentEmailBody, parentEmailAttachments),
		sendEmailToAdmin(adminEmailSubject, adminEmailBody),
	];
	if(details.parentEmail !== user.email) emailPromises.push(sendEmailToStudent(user.email, studentEmailSubject, studentEmailBody, studentEmailAttachments))
	return userDetails(user.uid).set(finalDetails)
		.then(() => Promise.all(emailPromises))
		.then(submitUserDetailsSuccess(dispatch))
		.catch(handleCreateUserFailure(dispatch, user))
		.finally(handleSignInSuccess)
}


export const submitUserDetails = (email, password, details) => dispatch => () => {
	dispatch({type: ACTION_TYPES.DETAILS_SUBMIT_REQUEST});
	return firebaseAppAuth.signInWithEmailAndPassword(email, password)
		.then(uploadUserDetails(dispatch, details))
		.catch(handleSubmitUserDetailsError(dispatch))
}

export const resetState = () => dispatch => dispatch({ type: ACTION_TYPES.RESET_CREATE_ACCOUNT_STATE })

export const removeCredentialsError = () => dispatch => dispatch({type:ACTION_TYPES.REMOVE_CREDENTIALS_ERROR})

