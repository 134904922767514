import React from 'react';
import {connect} from 'react-redux'
import _get from 'lodash/get'
import {withRouter} from "react-router-dom";
import {compose} from 'recompose';

import Routes from "./routes";
import ErrorBoundary from "organisms/ErrorBoundary";

class CommonApp extends React.Component {
	render() {
		return (
			<ErrorBoundary>
				<Routes/>
			</ErrorBoundary>
		);
	}
}

const mapStateToProps = state => ({
	isAuthenticated: _get(state, 'auth.isAuthenticated'),
})

export default compose(
	connect(mapStateToProps),
	withRouter,
)(CommonApp);
