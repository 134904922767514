import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from "classnames";
import moment from 'moment';

import _get from 'lodash/get';
import _noop from 'lodash/noop';

import TextField from "@material-ui/core/TextField";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import ReadOutlined from "@ant-design/icons/lib/icons/ReadOutlined";
import {Modal, Spin} from "antd";

import {SmileTwoTone} from "@ant-design/icons"
import MomentUtils from '@date-io/moment';
import FrownTwoTone from "@ant-design/icons/lib/icons/FrownTwoTone";

import { getBatchName } from "helpers/general";

import {DATE_FORMAT, STATUSES, TIME_FORMAT} from "constants/app.general";

import {attendanceOpenStatus} from "../../../AdminDashboard.actions";

import styles from './OpenAttendance.module.scss';

const ATTENDANCE_STATUSES = {
	NOT_OPEN: 'NOT_OPEN',
	OPEN: 'OPEN',
	CLOSED: 'CLOSED',
}

class OpenAttendance extends Component {
	state = {
		formValues: {
			date: moment(),
			stream: '',
			batch: '',
			topic: '',
		},
		attendanceStatus: ATTENDANCE_STATUSES.NOT_OPEN,
	}
	
	displaySubmitButton = () => {
		const {date, stream, batch} = _get(this.state, 'formValues');
		if (!date || !stream || !batch) return;
		const formattedDate = date.format(DATE_FORMAT);
		this.setState(
			{fetchingAttendanceStatus: true}
		)
		attendanceOpenStatus(formattedDate, stream, batch).then((attendanceDetails) => {
			const openStatus = _get(attendanceDetails, 'open');
			const topic = _get(attendanceDetails, 'topic', '');
			switch (openStatus) {
				case true: {
					this.setState(state => ({
						attendanceStatus: ATTENDANCE_STATUSES.OPEN,
						fetchingAttendanceStatus: false,
						formValues: {
							...state.formValues,
							topic,
						},
					}))
					return;
				}
				case false: {
					this.setState(state => ({
						attendanceStatus: ATTENDANCE_STATUSES.CLOSED,
						fetchingAttendanceStatus: false,
						formValues: {
							...state.formValues,
							topic,
						},
					}))
					return;
				}
				default:
					this.setState(state => ({
						fetchingAttendanceStatus: false,
						attendanceStatus: ATTENDANCE_STATUSES.NOT_OPEN,
						formValues: {
							...state.formValues,
							topic,
						},
					}))
					return;
			}
		})
	}
	
	handleFieldChange = key => value => {
		this.setState(({formValues}) => ({
			formValues: {
				...formValues,
				[key]: value,
			}
		}), key === 'topic' ? _noop : this.displaySubmitButton);
	}
	
	handleSubmit = () => {
		const {date, stream, batch, topic} = _get(this.state, 'formValues');
		const {attendanceStatus} = this.state;
		const {updateAttendanceStatus} = this.props;
		const formattedDate = date.format(DATE_FORMAT);
		const formattedTime = moment().format(TIME_FORMAT)
		if (attendanceStatus === ATTENDANCE_STATUSES.NOT_OPEN) {
      updateAttendanceStatus(formattedDate, stream, batch, topic, true, 'startTime', formattedTime);
		} else if (attendanceStatus === ATTENDANCE_STATUSES.OPEN) {
      updateAttendanceStatus(formattedDate, stream, batch, topic, false, 'endTime', formattedTime);
		}
		if(attendanceStatus === ATTENDANCE_STATUSES.CLOSED){
      updateAttendanceStatus(formattedDate, stream, batch, topic, true);
		}
	}
	
	renderSuccessModal = () => {
		const {onAttendanceStatusUpdate} = this.props;
		const {attendanceStatus} = this.state;
		setTimeout(() => {
			onAttendanceStatusUpdate(STATUSES.SUCCESS);
			this.setState({
				formValues:{
					stream: '',
					batch: '',
					date: moment(),
					topic: '',
				}
			})
		}, 2000);
		return (<Modal
			visible
			closable={false}
			footer={null}
			centered={true}
			maskClosable={false}
			width={400}
			zIndex={2000}
		>
			<SmileTwoTone className={styles.modalIcon}/>
			<span className={styles.modalMessage}>{
				attendanceStatus === ATTENDANCE_STATUSES.NOT_OPEN ? "Opened Attendance Successfully" :
				attendanceStatus === ATTENDANCE_STATUSES.OPEN ? "Closed Attendance Successfully" : "Re-Opened Attendance Successfully"
			}
			</span>
		</Modal>)
	}
	
	renderErrorModal = () => {
		const {onAttendanceStatusUpdate} = this.props;
		setTimeout(() => {
			onAttendanceStatusUpdate(STATUSES.FAILED);
			this.setState({
				formValues:{
					stream: '',
					batch: '',
					date: moment(),
					topic: '',
				}
			})
		}, 2000);
		return (<Modal
			visible
			closable={false}
			footer={null}
			centered={true}
			maskClosable={false}
			width={400}
			zIndex={2000}
		>
			<FrownTwoTone className={styles.modalIcon} twoToneColor="red"/>
			<span className={styles.modalMessage}>Error Occurred</span>
		</Modal>)
	}
	
	render() {
		const {date, stream, batch, topic} = _get(this.state, 'formValues');
		const {attendanceStatus, fetchingAttendanceStatus} = this.state;
		const {streams, streamMap, isOpeningAttendance, openAttendanceStatus} = this.props;
		const batches = Object.keys(_get(streamMap, [stream, 'batches'], {}));
		return (
			<div className={styles.formContainer}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<div className={cx(styles.field)}>
						<DatePicker
							autoOk
							variant="inline"
							openTo="date"
							disableFuture
							orientation="portrait"
							value={date}
							onChange={this.handleFieldChange('date')}
							inputVariant="outlined"
							style={{width: '100%'}}
							format={DATE_FORMAT}
							label="Date"
						/>
					</div>
				</MuiPickersUtilsProvider>
				<TextField
					select
					label="Stream"
					value={stream}
					variant="outlined"
					classes={{root: cx(styles.field, styles.streamSelect)}}
					onChange={e => this.handleFieldChange('stream')(e.target.value)}
					SelectProps={{
						MenuProps: { MenuListProps:{ classes: {root: styles.selectList}, dense: true } },
					}}
				>
					{streams.map(val => <MenuItem key={val} value={val}>{val}</MenuItem>)}
				</TextField>
				<TextField
					select
					label="Batch"
					value={batch}
					variant="outlined"
					classes={{root: cx(styles.field, styles.batchSelect)}}
					onChange={e => this.handleFieldChange('batch')(e.target.value)}
					SelectProps={{
						MenuProps: { MenuListProps:{ classes: {root: styles.selectList}, dense: true } },
					}}
				>
					{batches.map(val => <MenuItem key={val} value={val}>{getBatchName(val)}</MenuItem>)}
				</TextField>
				<TextField
					label="Topic"
					value={topic}
					multiline
					variant="outlined"
					classes={{root: cx(styles.field)}}
					onChange={e => this.handleFieldChange('topic')(e.target.value)}
				/>
				{stream && batch && (
					fetchingAttendanceStatus ? <Spin spinning indicator={<LoadingOutlined/>}/> :
						<>
							{attendanceStatus === ATTENDANCE_STATUSES.CLOSED && <h3>Attendance has been closed</h3>}
							<Button
								classes={{root: cx(styles.submitButton, attendanceStatus === ATTENDANCE_STATUSES.OPEN && styles.closeAttendanceButton), label: styles.submitButtonText}}
								onClick={this.handleSubmit}
								endIcon={isOpeningAttendance ? <LoadingOutlined/> : <ReadOutlined/>}
							>
								{attendanceStatus === ATTENDANCE_STATUSES.NOT_OPEN ? "Open Attendance" : attendanceStatus === ATTENDANCE_STATUSES.OPEN ? "Close Attendance" : "Re-Open Attendance"}
							</Button>
						</>
				)}
				{openAttendanceStatus === 'success' ? this.renderSuccessModal() : openAttendanceStatus === 'failed' ? this.renderErrorModal() : null}
			</div>
		);
	}
}

OpenAttendance.propTypes = {
	streams: PropTypes.array.isRequired,
	streamMap: PropTypes.object.isRequired,
	isOpeningAttendance: PropTypes.bool,
	openAttendanceStatus: PropTypes.string,
  updateAttendanceStatus: PropTypes.func.isRequired,
	onAttendanceStatusUpdate: PropTypes.func,
}

OpenAttendance.defaultProps = {
	isOpeningAttendance: false,
	openAttendanceStatus: '',
	onAttendanceStatusUpdate: _noop,
}

export default OpenAttendance;
