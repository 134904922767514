import {handleActions} from 'redux-actions';

import ACTION_TYPES from "actions/login.actionTypes";
import PERMISSIONS from "constants/permissions";

const INITIAL_STATE = {
	isAuthenticating: false,
	isAuthenticated: false,
	user: {
		permission: PERMISSIONS.NONE,
	},
	loginError: undefined,
	isLoggingOut: undefined,
	logoutError: undefined,
}

const actionHandlers = {
	[ACTION_TYPES.LOGIN_REQUEST]: (state) => ({
		...state,
		isAuthenticating: true,
	}),
	[ACTION_TYPES.LOGIN_SUCCESS]: (state, {payload: user}) => ({
		...state,
		isAuthenticating: false,
		isAuthenticated: true,
		isValidating: false,
		user,
	}),
	[ACTION_TYPES.LOGIN_FAILURE]: (state, {payload: error}) => ({
		...state,
		isAuthenticating: false,
		isAuthenticated: false,
		loginError: error,
		isValidating: false,
	}),
	[ACTION_TYPES.VERIFY_AUTH_REQUEST]: (state) => ({
		...state,
		isValidating: true,
	}),
	[ACTION_TYPES.VERIFY_AUTH_FAILURE]: (state) => ({
		...state,
		isValidating: false,
	}),
	[ACTION_TYPES.LOGOUT_REQUEST]: state => ({
		...state,
		isLoggingOut: true,
	}),
	[ACTION_TYPES.LOGOUT_SUCCESS]: state => ({
		...state,
		isLoggingOut: false,
		isAuthenticated: false,
		user: { permission: PERMISSIONS.NONE },
	}),
	[ACTION_TYPES.LOGOUT_FAILURE]: state => ({
		...state,
		isLoggingOut: false,
		logoutError: true,
	}),
}

export default handleActions(actionHandlers, INITIAL_STATE);
