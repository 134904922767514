import {handleActions} from 'redux-actions';
import ACTION_TYPES from "./interestedForm.actionTypes";

const INITIAL_STATE = {
	isSubmitting: false,
	submitStatus: '',
}

const actionHandlers = {
	[ACTION_TYPES.SUBMIT_DETAILS_REQUEST]: (state) => ({
		...state,
		isSubmitting: true,
	}),
	[ACTION_TYPES.SUBMIT_DETAILS_FAILURE]: (state) => ({
		...state,
		isSubmitting: false,
		submitStatus: 'failed'
	}),
	[ACTION_TYPES.SUBMIT_DETAILS_SUCCESS]: (state) => ({
		...state,
		isSubmitting: false,
		submitStatus: 'success'
	}),
	[ACTION_TYPES.RESET_INTERESTED_FORM_STATE]: () => INITIAL_STATE,
}

export default handleActions(actionHandlers, INITIAL_STATE);
