import firebaseHttp from "./firebaseFunctions";
import qs from 'qs';

export const deleteUser = (userId) => {
  const path = '/deleteUser';
  const data = {userId};
  return firebaseHttp({
    url: path,
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data)
  });
}
