import React from 'react';

import {errors} from "services/firebase";
import _noop from 'lodash/noop';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}
	
	static getDerivedStateFromError() {
		return { hasError: true };
	}
	
	componentDidCatch({message, stack}, errorInfo) {
		errors.push({message, stack, errorInfo, date: (new Date()).toString()}).catch(_noop);
	}
	
	render() {
		if (this.state.hasError) {
			return <h1 style={{textAlign: 'center', marginTop: '10%'}}>Something went wrong. Please contact the administrator</h1>;
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
