import { combineReducers } from 'redux';

import createAccountReducer from 'pages/commonPages/pages/createAccount/createAccount.reducer';
import interestedFormReducer from 'pages/commonPages/pages/homepage/InterestedForm/interestedForm.reducer'

const rootReducer = combineReducers({
	createAccount: createAccountReducer,
	interestedForm: interestedFormReducer,
});

export default rootReducer;
