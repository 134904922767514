import _uniq from 'lodash/uniq';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _filter from 'lodash/filter';

export const getUpdatedStreamBatchDetails = (students, studentMap, streamMap, stream, batch) => {
  const updatedDetails = {
    [stream]: {
      [batch]: _uniq([
        ..._get(streamMap, [stream, 'batches', batch, 'students'], []),
        ...students,
      ]),
    },
  };
  _forEach(students, (studentId) => {
    const {stream: prevStream, batch: prevBatch} = studentMap[studentId];
    const updatedStudentsList = _get(updatedDetails, [prevStream, prevBatch]);
    if(!prevStream || !prevBatch || (stream === prevStream && batch === prevBatch)) return;
    if (!_isEmpty(updatedStudentsList)) return _set(updatedDetails, [prevStream, prevBatch], _filter(updatedStudentsList, uid => uid !== studentId));
    _set(updatedDetails, [prevStream, prevBatch], _filter(_get(streamMap, [prevStream, 'batches', prevBatch, 'students'], []), uid => uid !== studentId));
  });
  return updatedDetails;
}
