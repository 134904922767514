import React from 'react';
import cx from "classnames";
import logo from "public/logo.svg";

import styles from './logo.module.scss'
import {withRouter} from "react-router-dom";

const defaultHandleLogoClick = (history) => () => {
	history.push('/home')
}

const Logo = ({
	handleLogoClick,
	containerClassName,
	logoClassName,
	titleClassName,
	history,
	noLogo,
	centered,
	vertical,
}) => {
	return (
		<div className={cx(styles.logoContainer, containerClassName, noLogo && styles.noLogoContainer, centered && styles.centered, vertical && styles.vertical)} onClick={handleLogoClick? handleLogoClick: defaultHandleLogoClick(history)}>
			{!noLogo && <img src={logo} alt="logo" className={cx(styles.logoImg, logoClassName)}/>}
			<h1 className={cx(styles.logoTitle, titleClassName)}>
				EasyChemistry Academy
			</h1>
		</div>
	)
};

export default withRouter(Logo);
