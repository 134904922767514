import { AbilityBuilder, Ability } from '@casl/ability';
import PERMISSIONS from "../constants/permissions";

const defineAdminAbilities = (can) => {
	can('access', 'admin');
}

const defineStudentAbilities = (can) => {
	can('access', 'student');
}

export default function defineAbilityFor(userPermission) {
	const { can, rules } = new AbilityBuilder();
	// noinspection JSCheckFunctionSignatures
	can('access', 'common');
	switch (userPermission){
		case PERMISSIONS.ADMIN:
			defineAdminAbilities(can);
			break;
		case PERMISSIONS.STUDENT:
			defineStudentAbilities(can);
			break;
		default:
			break;
	}
	return new Ability(rules);
}
