import React from 'react';
import {
	Layout,
	Button,
} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import styles from './StudentApp.module.css';
import Routes from './routes'
import {connect} from 'react-redux'
import {withRouter} from "react-router-dom";
import {compose} from 'recompose';

import TopBar from 'molecules/TopBar/TopBar'
import SideBar from 'molecules/SideBar/SideBar'
import {fetchClassTypes} from 'actions/app.actions'
import ErrorBoundary from 'organisms/ErrorBoundary';

import {MENU_ITEMS} from "./constants/adminApp.general";
import withIsMobile from "connectors/withIsMobile";
import Logo from "atoms/logo";

const {Header, Content} = Layout;

class StudentApp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			menuCollapsed: true
		}
	}
	
	toggleMenu = () => {
		this.setState((state) => ({menuCollapsed: !state.menuCollapsed}))
	}
	
	handleLogoClick = () => {
		const {history} = this.props;
		const {isMobile} = this.props;
		history.push('/student/home');
		if (isMobile) this.toggleMenu();
	}
	
	renderLogo = () => {
		const {isMobile} = this.props;
		return (
			<Logo
				containerClassName={isMobile && styles.centerLogo}
				handleLogoClick={this.handleLogoClick}
				titleClassName={isMobile && styles.centerTitle}
			/>
		)
	}
	
	renderTopBar = () => {
		const {isMobile} = this.props;
		if (isMobile) return;
		return (
			<TopBar
				menuItems={MENU_ITEMS}
			/>
		)
	}
	
	renderHeader = () => {
		const {isMobile} = this.props;
		return (
			<Header className={styles.header}>
				{isMobile && <Button
					type="link"
					icon={<MenuOutlined/>}
					size="large"
					onClick={this.toggleMenu}
					className={styles.menuButton}
				/>}
				{this.renderLogo()}
				{this.renderTopBar()}
			</Header>
		)
	}
	
	renderSideBar = () => {
		const {isMobile} = this.props;
		if (!isMobile) return;
		return (<SideBar
				collapsed={this.state.menuCollapsed}
				handleMenuClick={this.toggleMenu}
				handleLogoClick={this.handleLogoClick}
				menuItems={MENU_ITEMS}
			/>
		)
	}
	
	renderAppContent = () => {
		return (
			<Content style={{height: '100%'}}>
				<ErrorBoundary>
					<Routes/>
				</ErrorBoundary>
			</Content>
		)
	}
	
	renderApp = () => {
		return (
			<Layout style={{height: '100%'}}>
				{this.renderSideBar()}
				<Layout className={styles.layout} style={{height: '100%'}}>
					{this.renderHeader()}
					{this.renderAppContent()}
				</Layout>
			</Layout>
		)
	}
	
	render() {
		return (
			<React.Fragment>
				{this.renderApp()}
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = {
	fetchClassTypes,
}

export default compose(
	connect(null, mapDispatchToProps),
	withRouter,
	withIsMobile,
)(StudentApp);
