import React, {useState} from 'react';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";

import _isEmpty from 'lodash/isEmpty';

import { Modal, Button} from "antd";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import {getBatchName} from "helpers/general";
import {deleteBatch, getStreamData} from '../../AdminDashboard.actions';

import {DOWNLOAD_ATTENDANCE_QUERY_PARAM} from '../../../../constants/adminPages.general';

import styles from './StreamsList.module.scss';
import {toast} from "react-toastify";

function DeleteBatchModal({
  visible,
  stream,
  batch,
  onCancel,
  history,
  students,
  getStreamData: getStreamDataAction,
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  
  const handleNonEmptyStudents = () => {
    onCancel();
    return Modal.error({
      title: `Cannot delete batch ${getBatchName(batch)}`,
      content: (
        <div>
          <p>This batch has assigned students</p>
          <p>Delete or Reassign students</p>
        </div>
      ),
    });
  }
  
  const handleDeleteBatch = () => {
    if (!_isEmpty(students)) return handleNonEmptyStudents();
    setIsDeleting(true);
    deleteBatch(stream, batch).then(() => {
      onCancel();
      getStreamDataAction();
      toast.success(`Successfully Delete Batch ${getBatchName(batch)}`)
    }).catch(() => {
      toast.error('Error deleting branch');
    }).finally(() => {
      setIsDeleting(false);
    });
  };
  
  const handleDownloadAttendance = () => {
    history.push(`/admin/attendance?${DOWNLOAD_ATTENDANCE_QUERY_PARAM}=true&stream=${stream}&batch=${batch}`);
  }
  
  const renderBody = () => {
    return (
      <div className={styles.removeBatchModalBody}>
        <div>Are You Sure You Want to Remove:</div>
        <div>Stream: <b>{stream}</b></div>
        <div>Batch: <b>{getBatchName(batch)}</b></div>
        <div className={styles.downloadAttendanceNote}>
          <InfoOutlinedIcon/>
          <span className={styles.downloadAttendanceNoteText}> Download the attendance data before deleting batch </span>
        </div>
        <Button
          type="primary"
          className={styles.downloadAttendanceButton}
          onClick={handleDownloadAttendance}
        >
          Download Attendance
        </Button>
      </div>
    )
  }
  
  return (
    <Modal
      title="Remove Batch"
      visible={visible}
      onOk={handleDeleteBatch}
      okText={`Remove ${getBatchName(batch)}`}
      onCancel={onCancel}
      confirmLoading={isDeleting}
      okButtonProps={{danger: true}}
    >
      {renderBody()}
    </Modal>
  );
}

const mapDispatchToProps = {
  getStreamData,
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(DeleteBatchModal);

// export default withRouter(DeleteBatchModal);
