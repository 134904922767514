import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import firebaseConfig from 'constants/firebaseConfig'

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const databaseRef = firebase.database().ref();
export const classes = databaseRef.child("classes");

export const attendanceOfStreamBatchAndDate = (stream, batch, date) => databaseRef.child(`attendance/${stream}/${batch}/${date}`);
export const allAttendance = databaseRef.child('attendance');
export const streamBatchAttendance = (stream, batch) => databaseRef.child(`attendance/${stream}/${batch}`)
export const userDetails = userId => databaseRef.child('users/' + userId);
export const interestedStudentsDetails = (uuid) => databaseRef.child(`interestedStudents/${uuid}`);
export const interestedStudentsList = databaseRef.child('interestedStudents');
export const usersList = databaseRef.child('users');
export const deletedUsers = databaseRef.child('deletedUsers');
export const deletedUserDetails = userId => databaseRef.child('deletedUsers/' + userId);
export const allStreams = databaseRef.child('streams');
export const batchDetails = (stream, batch) => databaseRef.child(`streams/${stream}/batches/${batch}`)
export const studentsOfBatch = (stream, batch) => databaseRef.child(`streams/${stream}/batches/${batch}/students`);
export const errors = databaseRef.child('errors');

export const firebaseAppAuth = firebaseApp.auth();
