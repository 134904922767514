import { format, isValid } from 'date-fns'

import _get from 'lodash/get';

import {
	START_TIME_COLUMN_ID,
	END_TIME_COLUMN_ID,
	STREAM_COLUMN_ID,
	BATCH_COLUMN_ID,
	TOPIC_COLUMN_ID,
	DATE_COLUMN_ID,
	PRESENT_COLUMN_ID,
	ABSENT_COLUMN_ID, DURATION_COLUMN_ID, RAW_DURATION_COLUMN_ID,
} from './attendance';

export const LEVELS_OF_STUDY =  [
	'9th Class',
	'10th Class',
	'11th Class',
	'12th Class',
	'Long-Term',
	'B.Sc Chemistry',
	'M.Sc Chemistry',
];

export const STREAMS = [
	'9th Class - Science',
	'9th Class - Chemistry',
	'10th Class - Science',
	'10th Class - Chemistry',
	'11th Class - JEE',
	'11th Class - NCERT',
	'12th Class - JEE',
	'12th Class - NCERT',
	'Long Term - JEE',
	'SAT Chemistry',
	'AP Chemistry',
]

export const STUDENT_DETAILS_KEY_LABEL_MAP = {
	studentName: "Student",
	parentName: "Parent",
	levelOfStudy: "Level Of Study",
	institute: "School/College",
	mobileNumber: "Mobile Number",
	parentEmail: "Parent's Email",
	city: "City",
	stream: 'Stream',
	batch: 'Batch',
}

export const STUDENT_DETAILS_KEYS = [
	'studentName',
	'parentName',
	'levelOfStudy',
	'institute',
	'mobileNumber',
	'parentEmail',
	'city',
	'stream',
	'batch',
];

export const YEARS = [
	2020,
	2021,
	2022,
	2023,
	2024,
	2025,
];

export const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const STATUSES = {
	SUCCESS: 'success',
	FAILED: 'failed',
}

export const DATE_FORMAT = "Do MMMM, YYYY";

export const NEW_DATE_FORMAT = 'do MMM, yyyy'

export const DATE_DAY_FORMAT = 'EEE, do MMM, yyyy'

export const TIME_FORMAT = "HH:mm";

export const NEW_TIME_FORMAT = 'h:mm aaaa';

export const ATTENDANCE_CSV_COLUMN_CONFIG = [
  {
    id: STREAM_COLUMN_ID,
    name: 'Stream',
  },
  {
    id: BATCH_COLUMN_ID,
    name: 'Batch',
  },
  {
    id: DATE_COLUMN_ID,
    name: 'Date',
    getValue: value => isValid(value) ? format(value, DATE_DAY_FORMAT) : '',
  },
  {
    id: START_TIME_COLUMN_ID,
    name: 'Start Time',
    getValue: value => isValid(value) ? format(value, NEW_TIME_FORMAT): '',
  },
  {
    id: END_TIME_COLUMN_ID,
    name: 'End Time',
    getValue: value => isValid(value) ? format(value, NEW_TIME_FORMAT): '',
  },
	{
		id: DURATION_COLUMN_ID,
		name: 'Duration in mins',
		getValue: (_, row) => {
			const durationInMilliseconds = _get(row, RAW_DURATION_COLUMN_ID);
			if (!durationInMilliseconds) return '';
			return durationInMilliseconds/(1000*60);
		}
	},
  {
    id: TOPIC_COLUMN_ID,
    name: 'Topic',
  },
  {
    id: PRESENT_COLUMN_ID,
    name: 'Present',
  },
  {
    id: ABSENT_COLUMN_ID,
    name: 'Absent'
  },
]
