import {createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import rootReducer from './reducers'
import {verifyAuth} from "./actions/app.actions";

let store;
// store = createStore(rootReducer,compose(applyMiddleware(thunk),window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()));
store = createStore(rootReducer,compose(applyMiddleware(thunk)));
store.dispatch(verifyAuth())
export default store;
