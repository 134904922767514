const ACTION_TYPES = {
	FETCH_INTERESTED_STUDENT_LIST_REQUEST: 'FETCH_INTERESTED_STUDENT_LIST_REQUEST',
	FETCH_INTERESTED_STUDENT_LIST_SUCCESS: 'FETCH_INTERESTED_STUDENT_LIST_SUCCESS',
	FETCH_INTERESTED_STUDENT_LIST_FAILURE: 'FETCH_INTERESTED_STUDENT_LIST_FAILURE',
	FETCH_REGISTERED_STUDENT_LIST_REQUEST: 'FETCH_REGISTERED_STUDENT_LIST_REQUEST',
	FETCH_REGISTERED_STUDENT_LIST_SUCCESS: 'FETCH_REGISTERED_STUDENT_LIST_SUCCESS',
	FETCH_REGISTERED_STUDENT_LIST_FAILURE: 'FETCH_REGISTERED_STUDENT_LIST_FAILURE',
	FETCH_ATTENDANCE_REQUEST: 'FETCH_ATTENDANCE_REQUEST',
	FETCH_ATTENDANCE_SUCCESS: 'FETCH_ATTENDANCE_SUCCESS',
	FETCH_ATTENDANCE_FAILURE: 'FETCH_ATTENDANCE_FAILURE',
	FETCH_STREAM_DATA_REQUEST: 'FETCH_STREAM_DATA_REQUEST',
	FETCH_STREAM_DATA_SUCCESS: 'FETCH_STREAM_DATA_SUCCESS',
	FETCH_STREAM_DATA_FAILURE: 'FETCH_STREAM_DATA_FAILURE',
	UPDATE_ATTENDANCE_REQUEST: 'OPEN_ATTENDANCE_REQUEST',
	UPDATE_ATTENDANCE_SUCCESS: 'OPEN_ATTENDANCE_SUCCESS',
	UPDATE_ATTENDANCE_FAILURE: 'OPEN_ATTENDANCE_FAILURE',
	RESET_OPEN_ATTENDANCE_STATUS: 'RESET_OPEN_ATTENDANCE_STATUS',
}

export default ACTION_TYPES;
