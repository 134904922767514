export const MENU_ITEMS = [
  {
    route: '/student/dashboard',
    key: 'studentDashboard',
    label: 'Dashboard',
  },
  {
    route: '/student/attendance',
    key: 'studentAttendance',
    label: 'Attendance',
  },
]
