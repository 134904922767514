import PropTypes from 'prop-types';
import { format, isValid } from 'date-fns'
import React from "react";

import { LineOutlined } from "@ant-design/icons";

import {DATE_DAY_FORMAT, NEW_TIME_FORMAT} from 'constants/app.general';

DateTimeRenderer.propTypes = {
	cell: PropTypes.object,
	date: PropTypes.bool,
	time: PropTypes.bool,
};

DateTimeRenderer.defaultProps = {
	cell: undefined,
	date: true,
	time: true,
};


function DateTimeRenderer({
	cell,
	date,
	time,
}) {
	if(!cell || !isValid(cell.value)) return <LineOutlined />;
	const { value } = cell;
	let displayFormat = '';
	if(time) displayFormat+= NEW_TIME_FORMAT;
	if(date) displayFormat += DATE_DAY_FORMAT;
	return format(value, displayFormat)
}

export default DateTimeRenderer;
