import _flatMap from 'lodash/flatMap';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';

import {studentsOfBatch, databaseRef} from "services/firebase";
import {studentAdmittedEmailForAdminGenerator, studentAdmittedEmailForStudentGenerator} from "helpers/emailGenerator";
import {sendEmailToAdmin, sendEmailToStudent} from "services/emailServices";

import {getUpdatedStreamBatchDetails} from './AddStreamModal.helper';

const getEmailPromisesForStudent = (studentMap, details) => uid => {
	const student = studentMap[uid];
	const keyLabelMap = {stream: "Stream", batch: "Batch Name"};
	const {subject: studentSubject, body: studentBody} = studentAdmittedEmailForStudentGenerator(student.studentName, details, keyLabelMap, 'student');
	const {subject: parentSubject, body: parentBody} = studentAdmittedEmailForStudentGenerator(student.parentName, details, keyLabelMap, 'parent');
	const {subject: adminSubject, body: adminBody} = studentAdmittedEmailForAdminGenerator(student.studentName, details, keyLabelMap);
	return[
		sendEmailToStudent(student.parentEmail, parentSubject, parentBody),
		sendEmailToStudent(student.studentEmail, studentSubject, studentBody),
		sendEmailToAdmin(adminSubject, adminBody),
	]
}

export const submitBatchDetails = ({stream, batch, batchDetails, students}, streamMap, studentMap) => {
  const userUpdates = {};
	students.forEach(uid => {
	  const { stream: prevStream, batch: prevBatch, previousBatches = [] } = _get(studentMap, uid, {});
		_set(userUpdates, `/users/${uid}/stream`, stream);
    _set(userUpdates, `/users/${uid}/batch`, batch);
    if (!prevStream || !prevBatch) return;
    _set(userUpdates, `/users/${uid}/previousBatches`, _uniqWith([...previousBatches, {stream: prevStream, batch: prevBatch}], _isEqual));
	});
	const updatedStreamBatchDetails = getUpdatedStreamBatchDetails(students, studentMap, streamMap, stream, batch);
  const promises = [
    ...(_flatMap(updatedStreamBatchDetails, (batches, streamToUpdate) => {
      return _flatMap(batches, (updatedStudents, batchToUpdate) => {
        return [
          studentsOfBatch(streamToUpdate, batchToUpdate).set(updatedStudents)
        ];
      })
    })),
		databaseRef.update(userUpdates),
		];
	const emailPromises = [
		_flatMap(students, getEmailPromisesForStudent(studentMap, {stream, batch}))
	]
	return Promise.all(promises).then(() => Promise.all(emailPromises));
};
