import React, {Component} from 'react';
import cx from "classnames";
import {connect} from "react-redux";
import {defaultMemoize} from "reselect";

import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import Button from "@material-ui/core/Button";
import {Collapse, Spin} from "antd";
import IconButton from "@material-ui/core/IconButton";
import {
  FrownOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import AirlineSeatFlatIcon from '@material-ui/icons/AirlineSeatFlat';
import RefreshIcon from '@material-ui/icons/Refresh';

import {getBatchName} from "helpers/general";

import {REDUX_PATH_KEY as adminReduxKey} from "pages/adminPages/constants/adminPages.general";

import AddStreamModal from "../RegisteredStudents/RegisteredStudentItem/AddStreamModal";
import DeleteBatchModal from "./DeleteBatchModal";
import StudentDetails from "../../molecules/StudentDetails";
import {getStreamData} from "../../AdminDashboard.actions";
import { getIsSomeBatchInactive, getIsSomeBatchStudentListEmpty } from "./StreamsList.helper";
import { fetchAllAttendance } from "../../../../actions/attendance";

import {REDUX_PATH_KEY as adminDashboardReduxKey} from "../../constants/adminDashboard.general";

import styles from "./StreamsList.module.scss";

const {Panel} = Collapse;

class StreamsList extends Component {
  state = {
    showRemoveBatchModal: false,
    showReassignStudentBatchModal: false,
    attendanceByStream: {},
  }
  
  componentDidMount() {
    fetchAllAttendance().then((attendanceData) => {
      this.setState({
        attendanceByStream: attendanceData,
      });
    })
  }
  
  toggleReassignStudentsBatchModal = (stream, batch, students) => () => {
    this.setState(state => ({
      showReassignStudentBatchModal: !state.showReassignStudentBatchModal,
      reassignStudentsBatchDetails: {
        stream,
        batch,
        students,
      },
    }));
  }
  
  toggleRemoveBatchModal = (stream, batch) => () => {
    this.setState(state => ({
      showRemoveBatchModal: !state.showRemoveBatchModal,
      removeBatchDetails: { stream, batch },
    }));
  }
  
  renderStudentItem = (studentId) => {
    const { registeredStudentsMap } = this.props;
    const studentDetails = registeredStudentsMap[studentId];
    return (
      <Panel
        key={studentId}
        header={studentDetails.studentName}
        className={cx(styles.listItem)}
      >
        <StudentDetails
          studentDetails={studentDetails}
        />
      </Panel>
    )
  }
  
  renderListItemStatuses = (isInactive, hasNoAssignedStudents) => {
    return (
      <>
        {isInactive && <AirlineSeatFlatIcon />}
        {hasNoAssignedStudents && <WarningOutlined style={{fontSize: '1.5em'}}/>}
      </>
    )
  }
  
  renderBatchItem = (stream, batchInactivityMap) => (batchDetails, batch) => {
    const { students } = batchDetails;
    const isBatchInactive = _get(batchInactivityMap, batch);
    const hasNoAssignedStudents = _isEmpty(students);
    return (
      <Panel
        key={batch}
        header={getBatchName(batch)}
        className={cx(styles.listItem)}
        extra={this.renderListItemStatuses(isBatchInactive, hasNoAssignedStudents)}
      >
        <div className={styles.batchItemBody}>
          {isBatchInactive && (
            <div className={styles.inactiveBatchMessage}>
              Inactive Batch
            </div>
          )}
          {!hasNoAssignedStudents && <h3 className={styles.batchStudentHeading}>Students:</h3>}
          <Collapse
            className={styles.list}
            accordion
            destroyInactivePanel
          >
            {_map(students, this.renderStudentItem)}
          </Collapse>
          <div className={styles.batchActionsContainer}>
            {!hasNoAssignedStudents && (
              <Button
                variant="outlined"
                classes={{
                  outlined: cx(styles.reassignStudentsButton, styles.batchActionButton),
                  label: styles.reassignStudentsButtonLabel
                }}
                onClick={this.toggleReassignStudentsBatchModal(stream, batch, students)}
              >
                Reassign Students
              </Button>
            )}
            <Button
              variant="outlined"
              classes={{outlined: cx(styles.removeBatchButton, styles.batchActionButton), label: styles.removeBatchButtonLabel}}
              onClick={this.toggleRemoveBatchModal(stream, batch)}
            >
              Remove Batch
            </Button>
          </div>
        </div>
      </Panel>
    )
  }
  
	renderStreamItem = (streamDetails, stream) => {
    const {attendanceByStream} = this.state;
    const batches = _get(streamDetails, 'batches');
    const isSomeBatchStudentListEmpty = getIsSomeBatchStudentListEmpty(batches);
    const {isSomeBatchInactive, batchInactivityMap} = getIsSomeBatchInactive(batches, _get(attendanceByStream, stream));
		return (
			<Panel
        key={stream}
				header={stream}
				className={cx(styles.streamItem)}
        extra={this.renderListItemStatuses(isSomeBatchInactive, isSomeBatchStudentListEmpty)}
			>
        <Collapse
          className={styles.list}
          accordion
          destroyInactivePanel
        >
          {_map(batches, this.renderBatchItem(stream, batchInactivityMap))}
        </Collapse>
			</Panel>
		);
	}
	
	renderList = () => {
		const {streamMap, streamDataFetchError} = this.props;
		if (streamDataFetchError) {
			return <h2 className={styles.errorMessage}><FrownOutlined/> Error Fetching Data</h2>
		}
		return (
			<div className={styles.listContainer}>
				<Collapse
					className={cx(styles.list)}
					accordion
					destroyInactivePanel
				>
					{_map(streamMap, this.renderStreamItem)}
				</Collapse>
			</div>
		)
	}
	
	reloadStreamsList = () => {
		const {getStreamData} = this.props;
    getStreamData();
	}
	
	renderHeader = () => {
		return (
			<div className={styles.header}>
				<h2>Current Streams</h2>
				<IconButton
					size="small"
					classes={{root: styles.reloadButton}}
					onClick={this.reloadStreamsList}
				>
					<RefreshIcon/>
				</IconButton>
			</div>
		)
	}
	
	renderLoader = () => (
    <Spin
      spinning
      tip="Fetching Streams"
      className={styles.loader}
      indicator={<LoadingOutlined style={{fontSize: '2rem'}}/>}
    />
  )
	
	renderBody = () => {
    const {isFetchingStreamData} = this.props;
    if (isFetchingStreamData) return this.renderLoader();
    return this.renderList();
  }
  
  getStreamBatchStudents = defaultMemoize((streamMap, stream, batch) => _get(streamMap, [stream, 'batches', batch, 'students']))
  
  renderRemoveBatchModal = () => {
    const { streamMap } = this.props;
    const { removeBatchDetails } = this.state;
    const { stream, batch } = removeBatchDetails;
    return (
      <DeleteBatchModal
        visible
        stream={stream}
        batch={batch}
        onCancel={this.toggleRemoveBatchModal()}
        students={this.getStreamBatchStudents(streamMap, stream, batch)}
      />
    )
  }
  
  renderReassignStudentBatchModal = () => {
    const { stream, batch, students } = _get(this.state, 'reassignStudentsBatchDetails');
    return (
      <AddStreamModal
        visible
        defaultStudents={students}
        defaultStream={stream}
        defaultBatch={batch}
        onClose={this.toggleReassignStudentsBatchModal()}
      />
    );
  }
	
	render() {
		const {className, noHeader} = this.props;
		const { showReassignStudentBatchModal, showRemoveBatchModal } = this.state;
		return (
			<div className={cx(className)}>
				<div className={cx(styles.container)}>
					{!noHeader && this.renderHeader()}
          {this.renderBody()}
          {showRemoveBatchModal && this.renderRemoveBatchModal()}
          {showReassignStudentBatchModal && this.renderReassignStudentBatchModal()}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
  isFetchingStreamData: _get(state, [adminReduxKey, adminDashboardReduxKey, 'isFetchingStreamData']),
  streamMap: _get(state, [adminReduxKey, adminDashboardReduxKey, 'streamMap']),
  streamDataFetchError: _get(state, [adminReduxKey, adminDashboardReduxKey, 'streamDataFetchError']),
  registeredStudentsMap: _get(state, [adminReduxKey, adminDashboardReduxKey, 'registeredStudentsMap']),
});

const mapDispatchToProps = {
  getStreamData,
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamsList);
