import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';

import Button from "@material-ui/core/Button";

import Logo from "atoms/logo";
import AbilityContext from "services/abilityContext";
import {notPermittedImage} from 'public';

import styles from './NotPermitted.module.scss';
import {getUserHomeRoute} from "../login/login.helper";
import withIsMobile from "../../../../connectors/withIsMobile";


class NotPermitted extends Component {
	static contextType = AbilityContext;
	
	handleGoBack = () => {
		const { history } = this.props;
		const ability = this.context;
		history.push(getUserHomeRoute(ability));
	}
	
	render() {
		const {isMobile} = this.props;
		return (
			<>
				<Logo
					centered
					containerClassName={styles.logoContainer}
					vertical={isMobile}
				/>
				<div className={styles.container}>
					<img src={notPermittedImage} alt="Not Permitted" className={styles.img}/>
					<h1>You are not allowed in here</h1>
					<h2>Solemnly Swore You're Upto No Good, did you?</h2>
					<Button
						variant="outlined"
						classes={{
							label: styles.buttonText,
							root: styles.button,
						}}
						onClick={this.handleGoBack}
					>
						Go back to where you belong
					</Button>
				</div>
			</>
		);
	}
}

export default compose(
	withRouter,
	withIsMobile,
)(NotPermitted);
