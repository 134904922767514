import React from 'react';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import styles from './AttendanceStatus.module.scss';
import {ATTENDANCE_STATUS_COLOR_MAP, ATTENDANCE_STATUS_LABELS} from "../../constants/attendance";

function AttendanceStatus({cell: {value}}) {
  
  return (
    <div className={styles.container}>
      <FiberManualRecordIcon htmlColor={ATTENDANCE_STATUS_COLOR_MAP[value]}/>
      <div className={styles.attendanceLabel}>{ATTENDANCE_STATUS_LABELS[value]}</div>
    </div>
  );
}

export default AttendanceStatus;
