import React, {Component} from 'react';
import AdminDashboard from "pages/adminPages/pages/adminDashboard";
import RouteSwitch from "atoms/routeSwitch";
import Attendance from "pages/adminPages/pages/attendance";

const attendanceRoute = (componentProps) => ({
	path: '/attendance',
	component: Attendance,
	componentProps: componentProps,
})

const dashboardRoute = (componentProps) => ({
	path: '/dashboard',
	component: AdminDashboard,
	componentProps: componentProps,
});

function getRoutes(componentProps) {
	return [
		dashboardRoute(componentProps),
		attendanceRoute(componentProps)
	];
}

class Routes extends Component {
	render() {
		return (
			<>
				<RouteSwitch
					routes={getRoutes({...this.props})}
					redirectPath="/admin/dashboard"
				/>
			</>
		);
	}
}

export default Routes;
