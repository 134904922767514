import React, {Component} from 'react';
import RouteSwitch from "atoms/routeSwitch";
import StudentDashboard from "../../studentDashboard";
import Attendance from '../../attendance';

const dashboardRoute = (componentProps) => ({
	path: '/dashboard',
	component: StudentDashboard,
	componentProps: {componentProps},
	accessSubject: 'student',
});

const attendanceRoute = (componentProps) => ({
  path: '/attendance',
  component: Attendance,
  componentProps: {componentProps},
  accessSubject: 'student',
});

const getRoutes = (componentProps) => ([
	dashboardRoute(componentProps),
  attendanceRoute(componentProps),
]);

class Routes extends Component {
	render() {
		return (
			<RouteSwitch
				routes={getRoutes({...this.props})}
				redirectPath="/student/dashboard"
			/>
		);
	}
}

export default Routes;
