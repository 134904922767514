import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useFlexLayout,
} from "react-table";

import _get from 'lodash/get';
import _size from 'lodash/size';

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import {
  FastBackwardOutlined,
  FastForwardOutlined,
  LeftOutlined,
  RightOutlined,
  LineOutlined,
} from '@ant-design/icons';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import DetailsIcon from '@material-ui/icons/Details';

import _map from "lodash/map";
import _constant from 'lodash/constant';
import _reduce from 'lodash/reduce';
import _flatMapDeep from "lodash/flatMapDeep";

import {STUDENT_COLUMNS, ADMIN_COLUMNS} from './AttendanceTable.helpers';
import {PAGE_SIZE_OPTIONS} from "./AttendanceTable.constants";
import {
  DATE_COLUMN_ID,
  PRESENT_COLUMN_ID,
  RAW_DURATION_COLUMN_ID,
  START_TIME_COLUMN_ID
} from '../../constants/attendance';

import styles from './attendanceTable.module.scss';
import {convertMillisecondsToHoursAndMins} from "../../helpers/general";

function AttendanceTable({data, isStudent}) {
  const tableInstance = useTable(
    {
      columns: isStudent ? STUDENT_COLUMNS : ADMIN_COLUMNS,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
        sortBy: [
          {
            id: DATE_COLUMN_ID,
            desc: true
          },
          {
            id: START_TIME_COLUMN_ID,
            desc: true,
          }
        ],
      },
      isMultiSortEvent: _constant(true),
      defaultColumn: {
        Cell: React.memo(({cell: {value}}) => value ? String(value) : <LineOutlined/>),
      }
    },
    useFilters,
    useSortBy,
    usePagination,
    useFlexLayout,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    state: {
      pageIndex,
      pageSize,
    }
  } = tableInstance

  const {hoursCount, undefinedDurationCount} = useMemo(() => {
    const {totalMilliseconds, undefinedDurationCount} = _reduce(rows, ({
      totalMilliseconds: totalMillisecondsAcc,
      undefinedDurationCount: undefinedDurationCountAcc
    }, {original}) => {
      const durationToAdd = _get(original, RAW_DURATION_COLUMN_ID);
      const isUndefinedDuration = !durationToAdd  && _size(_get(original, PRESENT_COLUMN_ID)) > 0;
      return ({
        totalMilliseconds: totalMillisecondsAcc + (durationToAdd || 0),
        undefinedDurationCount: isUndefinedDuration ? undefinedDurationCountAcc + 1 : undefinedDurationCountAcc,
      });
    }, {totalMilliseconds: 0, undefinedDurationCount: 0});
    return {
      hoursCount: convertMillisecondsToHoursAndMins(totalMilliseconds),
      undefinedDurationCount,
    };
  }, [rows])

  const handlePageSizeChange = (event) => {
    const value = event.target.value;
    setPageSize(value);
  }

  const handleGoToFirstPage = () => gotoPage(0);

  const handleGoToPreviousPage = () => previousPage();

  const handleGoToNextPage = () => nextPage();

  const handleGoToLastPage = () => gotoPage(pageCount - 1);

  const renderPageCountSelect = () => {
    return (
      <TextField
        select
        label="View"
        variant="outlined"
        onChange={handlePageSizeChange}
        classes={{root: styles.field}}
        value={pageSize}
        SelectProps={{
          MenuProps: {MenuListProps: {classes: {root: styles.selectField}, dense: true}},
          autoWidth: true,
        }}
      >
        {_map(PAGE_SIZE_OPTIONS, val => <MenuItem key={val} value={val}>{`Show ${val} Items`}</MenuItem>)}
      </TextField>
    )
  }

  const renderPageSelect = () => {
    return (
      <div className={styles.pageSelectToolbar}>
        <IconButton
          onClick={handleGoToFirstPage}
          disabled={!canPreviousPage}

        >
          <FastBackwardOutlined/>
        </IconButton>
        <IconButton
          onClick={handleGoToPreviousPage}
          disabled={!canPreviousPage}
        >
          <LeftOutlined/>
        </IconButton>
        <div className={styles.pageIndexSelectContainer}>
          Page {pageIndex + 1} / {pageCount}
        </div>
        <IconButton
          onClick={handleGoToNextPage}
          disabled={!canNextPage}
        >
          <RightOutlined/>
        </IconButton>
        <IconButton
          onClick={handleGoToLastPage}
          disabled={!canNextPage}
        >
          <FastForwardOutlined/>
        </IconButton>
      </div>
    )
  }

  const renderFilters = () => {
    const columnsWithFilter = _flatMapDeep(_map(headerGroups, headerGroup => _reduce(headerGroup.headers, (acc, column) => column.canFilter ? [...acc, column] : acc, [])));
    return (
      <div className={styles.filtersContainer}>
        {_map(columnsWithFilter, (column) => <div className={styles.filter}
                                                  key={column.id}>{column.render('Filter')}</div>)}
      </div>
    );
  }

  const renderToolbar = () => {
    return (
      <div className={styles.toolbar}>
        {renderPageSelect()}
        {!isStudent && renderFilters()}
        {renderPageCountSelect()}
      </div>
    )
  }

  const renderHoursCount = () => (
    <div className={styles.hoursCountContainer}>
      <h3 className={styles.hoursCount}>Total Hours: {hoursCount}</h3>
      {undefinedDurationCount && <b>{undefinedDurationCount} classes unclosed</b>}
    </div>
  );

  return (
    <div className={styles.container}>
      {renderToolbar()}
      {renderHoursCount()}
      <table {...getTableProps({
        className: styles.table,
      })}>
        <thead>
        {
          headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps({
              className: styles.headerGroup
            })}>
              {
                headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps({
                    className: styles.header,
                    ...column.getSortByToggleProps(),
                  })}>
                    <div className={styles.columnHeader}>
                      {column.render('Header')}
                      {column.isSorted ? column.isSortedDesc ?
                        <DetailsIcon className={styles.sortIcon}/> :
                        <ChangeHistoryIcon className={styles.sortIcon}/> :
                        <ChangeHistoryIcon style={{opacity: 0}} className={styles.sortIcon}/>}
                    </div>
                  </th>
                ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {
          page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps({
                className: styles.row,
              })}>
                {
                  row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps({
                        className: styles.cell,
                      })}>
                        {
                          cell.render('Cell')}
                      </td>
                    )
                  })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

AttendanceTable.propTypes = {
  data: PropTypes.array,
};

AttendanceTable.defaultProps = {
  data: [],
}

export default AttendanceTable;
