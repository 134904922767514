import React, {Component} from 'react';
import Dialog from "@material-ui/core/Dialog";
import StudentDetailsForm from "pages/commonPages/organisms/StudentDetailsForm";
import _noop from 'lodash/noop'
import styles from './interestedForm.module.scss';
import PageHeader from "molecules/pageHeader";
import {compose} from 'recompose';
import withIsMobile from "../../../../../connectors/withIsMobile";
import {connect} from "react-redux";
import _get from 'lodash/get';
import {submitDetails, resetState} from "./interestedForm.actions";
import {SmileTwoTone, FrownTwoTone} from "@ant-design/icons";
import {Modal} from "antd";

class InterestedForm extends Component {
	
	renderSubmitSuccessModal = () => {
		const { resetState,onClose } = this.props;
		setTimeout(()=>{resetState(); onClose()}, 1000);
		return(
			<Modal
				visible
				closable={false}
				footer={null}
				centered={true}
				maskClosable={false}
				width={350}
				zIndex={9000}
			>
				<SmileTwoTone className={styles.modalIcon}/>
				<span className={styles.modalMessage}>Submitted Successfully</span>
			</Modal>
		)
	}
	
	renderSubmitFailureModal = () => {
		const { resetState } = this.props;
		setTimeout(resetState, 1000);
		return (
			<Modal
				visible
				closable={false}
				footer={null}
				centered={true}
				maskClosable={false}
				width={350}
				zIndex={9000}
			>
				<FrownTwoTone className={styles.modalIcon}/>
				<span className={styles.modalMessage}>Unable to Submit</span>
			</Modal>
		)
	}
	
	handleSubmit = (details) => {
		const {submitDetails} = this.props;
		submitDetails(details);
	}
	
	render() {
		const {
			visible,
			onClose,
			isMobile,
			isSubmitting,
			submitStatus,
		} = this.props;
		return (
			<>
				<Dialog
					fullWidth={!isMobile}
					fullScreen={isMobile}
					open={visible}
				>
					<PageHeader
						title="Show your Interest"
						hasBack
						handleGoBack={onClose}
						className={styles.header}
						backIconClassName={styles.backIcon}
					/>
					<StudentDetailsForm
						handleSubmit={this.handleSubmit}
						isSubmitting={isSubmitting}
						handleUnmount={_noop}
						submitButtonLabel="Submit"
						className={styles.formContainer}
					/>
				</Dialog>
				{submitStatus ==='success' && this.renderSubmitSuccessModal()}
				{submitStatus ==='failed' && this.renderSubmitFailureModal()}
			</>
		);
	}
}

const mapStateToProps = state => ({
	isSubmitting: _get(state, ['common', 'interestedForm', 'isSubmitting']),
	submitStatus: _get(state, ['common', 'interestedForm', 'submitStatus'])
});

const mapDispatchToProps = {
	submitDetails,
	resetState
}

export default compose(
	withIsMobile,
	connect(mapStateToProps, mapDispatchToProps),
)(InterestedForm);
