import React, {Component} from 'react';
import cx from "classnames";
import {connect} from "react-redux";

import _get from 'lodash/get';

import {Collapse, Spin} from "antd";
import {
	FrownOutlined,
	LoadingOutlined,
	WarningOutlined
} from '@ant-design/icons';
import AirlineSeatFlatIcon from '@material-ui/icons/AirlineSeatFlat';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from "@material-ui/core/IconButton";

import RegisteredStudentItem from "./RegisteredStudentItem";
import {fetchRegisteredStudentList} from "../../AdminDashboard.actions";
import {isStudentInactive, compareRegisteredStudentForSort} from "./RegisteredStudents.helper";

import {REDUX_PATH_KEY as adminDashboardReduxKey} from "../../constants/adminDashboard.general";
import {REDUX_PATH_KEY as adminReduxKey} from "../../../../constants/adminPages.general";

import styles from "./RegisteredStudents.module.scss";

const {Panel} = Collapse;

class RegisteredStudents extends Component {
  getStudentStatusIcon = (studentDetails) => {
    const isAdmitted = studentDetails.batch && studentDetails.stream;
    const isInactive = isStudentInactive(studentDetails);
    return (
      <>
        {!isAdmitted && <WarningOutlined style={{fontSize: '1.5em'}}/>}
        {isInactive && <AirlineSeatFlatIcon />}
      </>
    )
  }
  
	renderListItem = (studentDetails, index) => (
    <Panel
      key={index}
      header={studentDetails.studentName}
      className={cx(styles.registeredStudentItem)}
      extra={this.getStudentStatusIcon(studentDetails)}
    >
      <RegisteredStudentItem
        studentDetails={studentDetails}
      />
    </Panel>
  )
	
	renderList = () => {
		const {registeredStudentList, isRegisteredStudentListFetchError} = this.props;
		if (isRegisteredStudentListFetchError) {
			return <h2 className={styles.errorMessage}><FrownOutlined/> Error Fetching Data</h2>
		}
		return (
			<div className={styles.listContainer}>
				<Collapse
					className={cx(styles.registeredStudentList)}
					accordion
					destroyInactivePanel
				>
					{registeredStudentList.map(this.renderListItem)}
				</Collapse>
			</div>
		)
	}
	
	reloadRegisteredStudentList = () => {
		const {fetchRegisteredStudentList} = this.props;
		fetchRegisteredStudentList();
	}
	
	renderHeader = () => {
		return (
			<div className={styles.header}>
				<h2>Registered Students</h2>
				<IconButton
					size="small"
					classes={{root: styles.reloadButton}}
					onClick={this.reloadRegisteredStudentList}
				>
					<RefreshIcon/>
				</IconButton>
			</div>
		)
	}
	
	renderBody = () => {
    const {isFetchingRegisteredStudentList} = this.props;
	  return !isFetchingRegisteredStudentList ? this.renderList() : (
      <Spin
        spinning
        tip="Fetching Registered Students"
        className={styles.loader}
        indicator={<LoadingOutlined style={{fontSize: '2rem'}}/>}
      />
    )
  }
	
	render() {
		const {className, noHeader} = this.props;
		return (
			<div className={cx(className)}>
				<div className={cx(styles.container)}>
					{ !noHeader && this.renderHeader()}
					<div className={styles.body}>
            {this.renderBody()}
          </div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	isFetchingRegisteredStudentList: _get(state, [adminReduxKey, adminDashboardReduxKey, 'isFetchingRegisteredStudentList']),
	registeredStudentList: _get(state, [adminReduxKey, adminDashboardReduxKey, 'registeredStudentsList'], []).sort(compareRegisteredStudentForSort),
	isRegisteredStudentListFetchError: _get(state, [adminReduxKey, adminDashboardReduxKey, 'isRegisteredStudentListFetchError']),
});

const mapDispatchToProps = {
	fetchRegisteredStudentList,
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredStudents);
