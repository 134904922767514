import React from 'react';
import {compose} from 'recompose';
import {
	Route,
	withRouter
} from 'react-router-dom';


function ProtectedRoute({
	isAuthenticated,
	component: Component,
	componentProps,
	match,
	location,
	history,
	path,
	...extraProps
}) {
	return (
		<Route
			{...extraProps}
			path={path}
			render={() => (<Component {...componentProps}/>)
			}
		/>
	)
}

export default compose(
	withRouter,
)(ProtectedRoute);
