import moment from 'moment';
import {isValid} from 'date-fns';

import _flatMapDeep from 'lodash/flatMapDeep';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _without from 'lodash/without';
import _uniq from 'lodash/uniq';
import _compact from 'lodash/compact';

import {convertMillisecondsToHoursAndMins, getBatchName} from 'helpers/general';

import {
    START_TIME_COLUMN_ID,
    END_TIME_COLUMN_ID,
    STREAM_COLUMN_ID,
    BATCH_COLUMN_ID,
    TOPIC_COLUMN_ID,
    DATE_COLUMN_ID,
    DURATION_COLUMN_ID,
    PRESENT_COLUMN_ID,
    ATTENDANCE_STATUS_COLUMN_ID,
    ATTENDANCE_STATUSES,
    RAW_DURATION_COLUMN_ID,
} from 'constants/attendance';
import {DATE_FORMAT, TIME_FORMAT} from "../constants/app.general";
import {ABSENT_COLUMN_ID} from "../constants/attendance";

export const getAdminAttendanceEvents = (allAttendance, streamsData, allUsers) => _flatMapDeep(allAttendance, (streamDetails, stream) => _map(streamDetails, (batchDetails, batch) => _map(batchDetails, ({
                                                                                                                                                                                                              startTime,
                                                                                                                                                                                                              endTime,
                                                                                                                                                                                                              open,
                                                                                                                                                                                                              topic,
                                                                                                                                                                                                              ...rest
                                                                                                                                                                                                          }, date) => {
    const startMoment = moment(`${date} ${startTime}`, `${DATE_FORMAT} ${TIME_FORMAT}`);
    const endMoment = moment(`${date} ${endTime}`, `${DATE_FORMAT} ${TIME_FORMAT}`);
    const present = _map(rest, (_, studentId) => studentId);
    const allStudents = _get(streamsData, [stream, 'batches', batch, 'students']);
    return ({
        start: startMoment.toDate(),
        end: endMoment.toDate(),
        title: `${stream} - ${getBatchName(batch)}`,
        stream,
        open,
        batch: getBatchName(batch),
        topic: topic,
        date: moment(date, DATE_FORMAT).toDate(),
        duration: open || !isValid(startMoment.toDate()) || !isValid(endMoment.toDate()) ? null : moment.utc(moment.duration(endMoment.diff(startMoment)).as('milliseconds')).format("hh:mm"),
        present: present.map(studentId => _get(allUsers, [studentId, 'studentName']) ? _get(allUsers, [studentId, 'studentName']) : studentId),
        absent: _uniq(_without(allStudents, ...present)).map(studentId => _get(allUsers, [studentId, 'studentName']) ? _get(allUsers, [studentId, 'studentName']) : studentId),
    })
})));

export const getStudentAttendanceEvents = (attendance, {
    uid,
    stream,
    batch
}, absentClassName) => _compact(_map(attendance, (attendanceDetails, date) => {
    const {startTime, endTime, topic, open} = attendanceDetails;
    const startMoment = moment(`${date} ${startTime}`, `${DATE_FORMAT} ${TIME_FORMAT}`);
    const endMoment = moment(`${date} ${endTime}`, `${DATE_FORMAT} ${TIME_FORMAT}`);
    return ({
        start: startMoment.toDate(),
        end: endMoment.toDate(),
        title: topic ? topic : date,
        stream,
        open,
        batch: getBatchName(batch),
        topic,
        date: moment(date, DATE_FORMAT).toDate(),
        duration: open || !isValid(startMoment.toDate()) || !isValid(endMoment.toDate()) ? null : moment.utc(moment.duration(endMoment.diff(startMoment)).as('milliseconds')).format("hh:mm"),
        className: attendanceDetails[uid] ? '' : absentClassName,
        attendanceStatus: attendanceDetails[uid] ? ATTENDANCE_STATUSES.PRESENT : ATTENDANCE_STATUSES.ABSENT,
    })
}));

export const getAdminAttendanceRowsData = (allAttendance, streamsData, allUsers) => {
    return _flatMapDeep(allAttendance, (streamDetails, stream) => _map(streamDetails, (batchDetails, batch) => _map(batchDetails, ({
       startTime,
       endTime,
       open,
       topic,
       ...rest
   }, date) => {
        const startMoment = moment(startTime, TIME_FORMAT);
        const endMoment = moment(endTime, TIME_FORMAT);
        const present = _map(rest, (_, studentId) => studentId);
        const allStudents = _get(streamsData, [stream, 'batches', batch, 'students']);
        const durationInMilliseconds = open || !isValid(startMoment.toDate()) || !isValid(endMoment.toDate()) ? null : endMoment.diff(startMoment);
        return ({
            [START_TIME_COLUMN_ID]: startMoment.toDate(),
            [END_TIME_COLUMN_ID]: open ? null : endMoment.toDate(),
            [STREAM_COLUMN_ID]: stream,
            [BATCH_COLUMN_ID]: getBatchName(batch),
            [TOPIC_COLUMN_ID]: topic,
            [DATE_COLUMN_ID]: moment(date, DATE_FORMAT).toDate(),
            [DURATION_COLUMN_ID]: durationInMilliseconds ? convertMillisecondsToHoursAndMins(durationInMilliseconds) : null,
            [RAW_DURATION_COLUMN_ID]: durationInMilliseconds,
            [PRESENT_COLUMN_ID]: present.map(studentId => _get(allUsers, [studentId, 'studentName']) ? _get(allUsers, [studentId, 'studentName']) : studentId),
            [ABSENT_COLUMN_ID]: _uniq(_without(allStudents, ...present)).map(studentId => _get(allUsers, [studentId, 'studentName']) ? _get(allUsers, [studentId, 'studentName']) : studentId),
        })
    })))
};

export const getStudentAttendanceRowsData = (attendance, {
    uid,
    stream,
    batch
}) => _map(attendance, (attendanceDetails, date) => {
    const {startTime, endTime, topic, open} = attendanceDetails;
    const startMoment = moment(startTime, TIME_FORMAT);
    const endMoment = moment(endTime, TIME_FORMAT);
    const durationInMilliseconds = open || !isValid(startMoment.toDate()) || !isValid(endMoment.toDate()) ? null : endMoment.diff(startMoment);
    return ({
        [START_TIME_COLUMN_ID]: startMoment.toDate(),
        [END_TIME_COLUMN_ID]: open ? null : endMoment.toDate(),
        [STREAM_COLUMN_ID]: stream,
        [BATCH_COLUMN_ID]: getBatchName(batch),
        [TOPIC_COLUMN_ID]: topic,
        [DATE_COLUMN_ID]: moment(date, DATE_FORMAT).toDate(),
        [DURATION_COLUMN_ID]: durationInMilliseconds ? convertMillisecondsToHoursAndMins(durationInMilliseconds) : null,
        [RAW_DURATION_COLUMN_ID]: durationInMilliseconds,
        [ATTENDANCE_STATUS_COLUMN_ID]: attendanceDetails[uid] ? ATTENDANCE_STATUSES.PRESENT : ATTENDANCE_STATUSES.ABSENT,
    })
});
