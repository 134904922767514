export const START_TIME_COLUMN_ID = 'startTime';
export const END_TIME_COLUMN_ID = 'endTime';
export const STREAM_COLUMN_ID = 'stream';
export const BATCH_COLUMN_ID = 'batch';
export const TOPIC_COLUMN_ID = 'topic';
export const DATE_COLUMN_ID = 'date';
export const DURATION_COLUMN_ID = 'duration';
export const RAW_DURATION_COLUMN_ID = 'rawDuration';
export const PRESENT_COLUMN_ID = 'present';
export const ABSENT_COLUMN_ID = 'absent';
export const ATTENDANCE_STATUS_COLUMN_ID = 'attendanceStatus';

export const ATTENDANCE_STATUSES = {
  PRESENT: 'PRESENT',
  ABSENT: 'ABSENT',
};

export const ATTENDANCE_STATUS_LABELS = {
  [ATTENDANCE_STATUSES.PRESENT]: 'Present',
  [ATTENDANCE_STATUSES.ABSENT]: 'Absent',
}

export const ATTENDANCE_STATUS_COLOR_MAP = {
  [ATTENDANCE_STATUSES.PRESENT]: '#04d404',
  [ATTENDANCE_STATUSES.ABSENT]: '#f34a4c',
}
