const accountButtonStyle = {
	position: 'absolute',
	color: '#81e7d2',
	borderColor: 'rgb(29, 167, 167)',
}

export const fullScreenAccountButtonStyle = {
	...accountButtonStyle,
	top: '3vh',
	right: '2vw',
};

export const mobileAccountButtonStyle = {
	...accountButtonStyle,
	fontSize: '0.8em',
	width: '26vw',
	top: '2vh',
	right: '2vw',
}

export const flipBoundaries = [
	{
		start: 0.5,
		bottomEnd: 0.9,
		topEnd: 0.5,
	},
	{
		start: 1,
		bottomEnd: 1.2,
		topEnd: 0.9,
	},
	{
		start: 1.3,
		bottomEnd: 1.5,
		topEnd: 1.1,
	},
]

export const initFlipStatuses = {
	INIT_FLIP: 'INIT_FLIP',
	INIT_FLIP_BACK: 'INIT_FLIP_BACK',
}
