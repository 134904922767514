import React, {Component} from 'react';
import cx from "classnames";
import {toast} from "react-toastify";
import {connect} from 'react-redux';

import Button from "@material-ui/core/Button";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Collapse, Modal} from 'antd';

import _get from 'lodash/get';

import AddStreamModal from "./AddStreamModal";
import {getBatchName} from "helpers/general";
import StudentDetails from "../../../molecules/StudentDetails";
import {removeStudent} from "../../../AdminDashboard.actions";
import {fetchRegisteredStudentList, getStreamData} from "../../../AdminDashboard.actions";

import styles from "./RegisteredStudentItem.module.scss";
import {isStudentInactive} from "../RegisteredStudents.helper";

const {Panel} = Collapse;

class RegisteredStudentItem extends Component {
	state = {
		isAddStreamModalVisible: false,
	}
	
	renderStreamDetails = () => {
		const {stream, batch} = this.props.studentDetails;
		if (!stream || !batch) return;
		return (
			<div className={cx(styles.streamDetailsContainer)}>
				<div><b>Stream:</b> {stream}</div>
				<div><b>Batch:</b> {getBatchName(batch)}</div>
			</div>
		)
	}
	
	toggleAddStreamModal = () => {
		this.setState( state => ({
			isAddStreamModalVisible: !state.isAddStreamModalVisible,
		}));
	}
	
  renderStatusMessages = () => {
	  const { studentDetails } = this.props;
	  const { stream, batch, lastPresentDate } = studentDetails;
	  return (
	    <>
        {(!stream || !batch) && (
          <div className={cx(styles.statusMessageContainer)}>
            No Stream Assigned
          </div>
        )}
        {isStudentInactive(studentDetails) && (
          <div className={cx(styles.statusMessageContainer, styles.inactiveStudentMessage)}>
            Inactive Student
          </div>
        )}
        <div className={cx(styles.statusMessageContainer)}>
          Last Present Date: {lastPresentDate}
        </div>
      </>
    )
  }
  
  renderStudentActions = () => {
    const {stream, batch} = this.props.studentDetails;
	  return (
	    <div className={styles.studentActionsContainer}>
        <Button
          variant="outlined"
          classes={{outlined: cx(styles.addStreamButton, styles.studentActionButton), label: styles.addStreamButtonLabel}}
          onClick={this.toggleAddStreamModal}
        >
          {stream && batch ? 'Change Stream' : 'Assign Stream'}
        </Button>
        <Button
          variant="outlined"
          classes={{outlined: cx(styles.removeStudentButton, styles.studentActionButton), label: styles.removeStudentButtonLabel}}
          onClick={this.renderRemoveStudentModal}
        >
          Remove Student
        </Button>
      </div>
    )
  }
	
	renderStudentDetails = () => {
		const {studentDetails} = this.props;
		return (
			<Collapse
				className={cx(styles.studentDetailsCollapseContainer)}
			>
				<Panel
					header="Student Details"
					key="studentDetails"
					className={cx(styles.studentDetailsPanel)}
				>
					<StudentDetails
						studentDetails={studentDetails}
					/>
				</Panel>
			</Collapse>
		)
	}
	
	renderAddStreamModal = () => {
		const {uid, stream, batch} = _get(this.props, ['studentDetails']);
		return <AddStreamModal
			visible
			onClose={this.toggleAddStreamModal}
			defaultStudents={[uid]}
      defaultStream={stream}
      defaultBatch={batch}
		/>
	}
  
  handleRemoveStudentSuccess = () => {
    const { fetchRegisteredStudentList, getStreamData } = this.props;
    toast.success('Successfully Removed Student');
    fetchRegisteredStudentList();
    getStreamData();
  }
  
  handleRemoveStudentFailure = () => {
    toast.error('Error removing student');
  }
  
  renderRemoveStudentModal = () => {
	  const { studentDetails } = this.props;
    const {studentName} = studentDetails;
    Modal.confirm({
      title: `Do you want to remove the student - ${studentName}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Click Yes to delete all data of the student',
      okText: 'Yes',
      width: 500,
      onOk: () => {
        return removeStudent(studentDetails).then(this.handleRemoveStudentSuccess).catch(this.handleRemoveStudentFailure);
      },
    })
  }
	
	render() {
		const {isAddStreamModalVisible} = this.state;
		return (
			<div className={cx(styles.registeredStudentDescription)}>
        {this.renderStreamDetails()}
        {this.renderStatusMessages()}
        {this.renderStudentActions()}
				{this.renderStudentDetails()}
				{isAddStreamModalVisible && this.renderAddStreamModal()}
			</div>
		);
	}
}

const mapDispatchToProps = {
  fetchRegisteredStudentList,
  getStreamData,
}

export default connect(null, mapDispatchToProps)(RegisteredStudentItem);
