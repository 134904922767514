import {handleActions} from 'redux-actions';
import ACTION_TYPES from "./createAccount.actionTypes";

const INITIAL_STATE = {
	isSubmittingCredentials: false,
	isSubmittingDetails: false,
	isCheckingEmail: false,
}

const actionHandlers = {
	[ACTION_TYPES.CREDENTIALS_SUBMIT_REQUEST]: (state) => ({
		...state,
		isSubmittingCredentials: true
	}),
	[ACTION_TYPES.CREDENTIALS_SUBMIT_SUCCESS]: (state) => ({
		...state,
		isSubmittingCredentials: false,
		credentialsSubmitStatus: 'success',
	}),
	[ACTION_TYPES.CREDENTIALS_SUBMIT_FAILURE]: (state, {payload: {errorComponent, errorMessage}}) => ({
		...state,
		isSubmittingCredentials: false,
		credentialsSubmitStatus: 'failed',
		errorMessage,
		errorComponent,
	}),
	[ACTION_TYPES.DETAILS_SUBMIT_REQUEST]: (state) => ({
		...state,
		isSubmittingDetails: true,
	}),
	[ACTION_TYPES.DETAILS_SUBMIT_SUCCESS]: (state) => ({
		...state,
		isSubmittingDetails: false,
		detailsSubmitStatus: 'success',
	}),
	[ACTION_TYPES.DETAILS_SUBMIT_FAILURE]: (state) => ({
		...state,
		isSubmittingDetails: false,
		detailsSubmitStatus: 'failed',
	}),
	[ACTION_TYPES.EMAIL_CHECK_REQUEST]: (state) => ({
		...state,
		isCheckingEmail: true,
	}),
	[ACTION_TYPES.EMAIL_CHECK_SUCCESS]: (state) => ({
		...state,
		isCheckingEmail: false,
		emailCheckStatus: 'success',
	}),
	[ACTION_TYPES.EMAIL_CHECK_FAILURE]: (state, {errorMessage, errorComponent}) => ({
		...state,
		emailCheckStatus: 'failed',
		isCheckingEmail: false,
		errorMessage,
		errorComponent,
	}),
	[ACTION_TYPES.RESET_CREATE_ACCOUNT_STATE]: ()=>INITIAL_STATE,
	[ACTION_TYPES.REMOVE_CREDENTIALS_ERROR]: (state) => ({
		...state,
		errorMessage: undefined,
		errorComponent: undefined,
	})
}

export default handleActions(actionHandlers, INITIAL_STATE)
