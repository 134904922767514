import React, {useState} from 'react';
import {Calendar, dateFnsLocalizer} from 'react-big-calendar'
import {Modal} from "antd";
import PropTypes from "prop-types";
import cx from "classnames";
import {defaultMemoize} from 'reselect';
import "react-big-calendar/lib/css/react-big-calendar.css";

import format from 'date-fns/format'
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'

import _get from 'lodash/get';

import styles from './AttendanceCalendar.module.scss';
import {NEW_DATE_FORMAT, NEW_TIME_FORMAT} from "../../constants/app.general";
import StudentListRenderer from "../../molecules/StudentListRenderer";
import AttendanceStatus from "../../molecules/AttendanceStatus";

const locales = {
  'en-US': require('date-fns/locale/en-US'),
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

function AttendanceCalendar({
  events,
  className,
  isStudent = false,
}) {
  const [selectedEvent, setSelectedEvent] = useState();
  const [isEventModalVisible, setIsEventModalVisible] = useState(false);
  
  const renderEventModal = () => {
    const date = _get(selectedEvent, 'date');
    const start = _get(selectedEvent, 'start');
    const end = _get(selectedEvent, 'end');
    const topic = _get(selectedEvent, 'topic');
    const open = _get(selectedEvent, 'open');
    return (
      <Modal
        visible={isEventModalVisible}
        footer={null}
        centered={true}
        maskClosable={false}
        width={600}
        zIndex={3000}
        onCancel={() => setIsEventModalVisible(false)}
        className={styles.eventModal}
        title="Event Details"
      >
        <div className={styles.modalContainer}>
          <div className={styles.modalSection}>
            <div className={styles.modalDetail}>
              <div className={styles.modalDetailLabel}>Stream</div>
              <div className={styles.modalDetailValue}>{_get(selectedEvent, 'stream', '-')}</div>
            </div>
            <div className={styles.modalDetail}>
              <div className={styles.modalDetailLabel}>Batch</div>
              <div className={styles.modalDetailValue}>{_get(selectedEvent, 'batch', '-')}</div>
            </div>
          </div>
          <div className={styles.modalSection}>
            <div className={styles.modalDetail}>
              <div className={styles.modalDetailLabel}>Date</div>
              <div className={styles.modalDetailValue}>{isValid(date) ? format(date, NEW_DATE_FORMAT) : '-'}</div>
            </div>
            <div className={styles.modalDetail}>
              <div className={styles.modalDetailLabel}>Start Time</div>
              <div className={styles.modalDetailValue}>{isValid(start) ? format(start, NEW_TIME_FORMAT) : '-'}</div>
            </div>
            <div className={styles.modalDetail}>
              <div className={styles.modalDetailLabel}>End Time</div>
              <div
                className={styles.modalDetailValue}>{isValid(end) && !open ? format(end, NEW_TIME_FORMAT) : '-'}</div>
            </div>
            <div className={styles.modalDetail}>
              <div className={styles.modalDetailLabel}>Duration</div>
              <div className={styles.modalDetailValue}>{open ? '-' : _get(selectedEvent, 'duration', '-')}</div>
            </div>
          </div>
          <div className={styles.modalSection}>
            <div className={styles.modalDetail}>
              <div className={styles.modalDetailLabel}>Topic</div>
              <div className={styles.modalDetailValue}>{topic ? _get(selectedEvent, 'topic') : '-'}</div>
            </div>
          </div>
          {
            !isStudent ? (<div className={styles.modalSection}>
                <div className={styles.modalDetail}>
                  <div className={styles.modalDetailLabel}>Present</div>
                  <div className={styles.modalDetailValue}>
                    <StudentListRenderer
                      cell={{value: _get(selectedEvent, 'present')}}
                    />
                  </div>
                </div>
                <div className={styles.modalDetail}>
                  <div className={styles.modalDetailLabel}>Absent</div>
                  <div className={styles.modalDetailValue}>
                    <StudentListRenderer
                      cell={{value: _get(selectedEvent, 'absent')}}
                    />
                  </div>
                </div>
              </div>)
              : (<div className={styles.modalSection}>
                <div className={styles.modalDetail}>
                  <div className={styles.modalDetailLabel}>Attendance Status</div>
                  <div className={styles.modalDetailValue}>
                    <AttendanceStatus
                      cell={{value: _get(selectedEvent, 'attendanceStatus')}}
                    />
                  </div>
                </div>
              </div>)
          }
        
        </div>
      </Modal>
    )
  }
  
  return (
    <>
      <Calendar
        className={cx(styles.calendar, className)}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        events={events}
        onSelectEvent={(eventDetails) => {
          setSelectedEvent(eventDetails);
          setIsEventModalVisible(true);
        }}
        eventPropGetter={defaultMemoize(({className}) => ({className}))}
        views={[
          'month',
          'week',
          'day',
        ]}
      />
      {renderEventModal()}
    </>
  );
}

AttendanceCalendar.propTypes = {
  events: PropTypes.array,
};

AttendanceCalendar.defaultProps = {
  events: [],
}


export default AttendanceCalendar;
