import React, {Component} from 'react';
import withIsMobile from "connectors/withIsMobile";
import cx from 'classnames';
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import _get from 'lodash/get'
import _noop from 'lodash/noop'
import {toast} from "react-toastify";

import styles from './createAccount.module.scss';
import logo from 'public/logo.svg';
import {
	checkIfEmailAvailable,
	submitUserCredentials,
	submitUserDetails,
	resetState,
	removeCredentialsError
} from "./createAccount.actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import {Modal, Steps} from "antd";
import {LeftOutlined, SmileTwoTone} from "@ant-design/icons";
import {withRouter} from "react-router-dom";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import SolutionOutlined from "@ant-design/icons/lib/icons/SolutionOutlined";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import IconButton from "@material-ui/core/IconButton";
import StudentDetailsForm from "../../organisms/StudentDetailsForm";

const {Step} = Steps;

class CreateAccount extends Component {
	state = {
		isCredentialsDone: false,
		credentialValues: {
			email: "",
			password: "",
			confirmPassword: "",
		},
		currentPage: 0,
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.errorMessage !== this.props.errorMessage || prevProps.errorComponent !== this.props.errorComponent) {
			const {errorMessage, errorComponent} = this.props;
			this.setState({errorMessage, errorComponent})
			if(errorComponent){
				this.setState({currentPage:0})
			}
		}
	}
	
	componentWillUnmount() {
		const {resetState} = this.props;
		resetState();
	}
	
	renderLogo = () => {
		const {isMobile} = this.props;
		return (
			<div
				className={cx(styles.logoContainer, !isMobile && styles.fullLogoContainer, isMobile && styles.mobileLogoContainer)}>
				<img src={logo} alt="logo" className={cx(styles.logoImg)}/>
				<h1 className={cx(styles.logoTitle, isMobile && styles.mobileLogoTitle)}>EasyChemistry Academy</h1>
			</div>
		)
	}
	
	renderTitle = () => {
		const {isMobile} = this.props;
		return (
			<div className={cx(styles.titleContainer, !isMobile && styles.fullPageTitleContainer)}>
				<h1 className={cx(styles.pageTitle, isMobile && styles.mobilePageTitle)}>Create Account</h1>
			</div>
		)
	}
	
	goBackToHomepage = () => {
		const {history} = this.props;
		history.goBack();
	}
	
	renderHeadingSection = () => {
		const {isMobile} = this.props;
		return (
			<div
				className={cx(styles.headingSection, !isMobile && styles.fullHeadingSection, isMobile && styles.mobileHeadingSection)}>
				<IconButton
					classes={{
						root: styles.backButton,
						label: cx(styles.backButtonLabel, isMobile && styles.mobileBackButtonLabel),
					}}
					onClick={this.goBackToHomepage}
				>
					<LeftOutlined/>
				</IconButton>
				{this.renderLogo()}
				{this.renderTitle()}
			</div>
		)
	}
	
	handleEmailCheck = () => {
		const {emailCheckStatus, errorMessage} = this.props;
		if (emailCheckStatus === 'success') {
			this.setState({
				isCredentialsDone: true,
				currentPage: 1,
			});
		}
		else if(!errorMessage || !errorMessage.length){
			toast.error('Unable to Create Account');
		}
	}
	
	handleCredentialsChange = field => (event) => {
		const {removeCredentialsError} = this.props;
		const {errorComponent, errorMessage} = this.state;
		const value = event.target.value;
		this.setState(state => ({
			...state,
			credentialValues: {
				...state.credentialValues,
				[field]: value,
			},
			errorComponent: undefined,
			errorMessage: undefined,
		}))
		if(errorComponent || errorMessage) removeCredentialsError();
	}
	
	handleCredentialsSubmit = () => {
		const {email, password, confirmPassword} = _get(this.state, 'credentialValues');
		const {checkIfEmailAvailable} = this.props;
		if(password !== confirmPassword){
			const errorMessage = "Passwords don't match";
			const errorComponent = "confirmPassword";
			this.setState({errorMessage, errorComponent});
			return;
		}
		checkIfEmailAvailable(email).then(this.handleEmailCheck);
		this.setState({email, password});
	}
	
	renderCredentialsForm = () => {
		const {isCheckingEmail} = this.props;
		const {errorMessage, errorComponent} = this.state;
		const {email, password, confirmPassword} = _get(this.state, 'credentialValues');
		const formError = errorComponent === 'email' || errorComponent === 'password' || errorComponent === 'confirmPassword';
		const onEnterFunction = email.length && password.length && confirmPassword.length && ((e) => {
			if(e.keyCode === 13){
				this.handleCredentialsSubmit();
			}
		});
		return (
			<div className={styles.credentialsFormContainer}>
				<TextField
					label="Student's Email"
					variant='outlined'
					required={true}
					size='small'
					margin='normal'
					className={cx(styles.credentialsField)}
					onChange={this.handleCredentialsChange('email')}
					value={email}
					error={errorComponent === 'email'}
					helperText={errorComponent === 'email' ? errorMessage : ' '}
					InputProps={{
						style: {backgroundColor: "rgba(255,255,255,0.3)"},
					}}
					onKeyDown={onEnterFunction}
				/>
				<TextField
					label="Password"
					variant='outlined'
					required={true}
					type="password"
					size='small'
					margin='normal'
					className={cx(styles.credentialsField)}
					onChange={this.handleCredentialsChange('password')}
					value={password}
					error={errorComponent === 'password'}
					helperText={errorComponent === 'password' ? errorMessage : ' '}
					InputProps={{
						style: {backgroundColor: "rgba(255,255,255,0.3)"},
					}}
					onKeyDown={onEnterFunction}
				/>
				<TextField
					label="Confirm Password"
					variant='outlined'
					required={true}
					type="password"
					size='small'
					margin='normal'
					className={cx(styles.credentialsField)}
					onChange={this.handleCredentialsChange('confirmPassword')}
					value={confirmPassword}
					error={errorComponent === 'confirmPassword'}
					helperText={errorComponent === 'confirmPassword' ? errorMessage : ' '}
					InputProps={{
						style: {backgroundColor: "rgba(255,255,255,0.3)"},
					}}
					onKeyDown={onEnterFunction}
				/>
				<Button
					variant="contained"
					color="primary"
					style={{backgroundColor: 'rgb(41,127,245)', textTransform: 'none'}}
					className={cx(styles.credentialsSubmitButton, (!password.length || !email.length || !confirmPassword.length) && styles.invisible)}
					endIcon={isCheckingEmail ? <CircularProgress size={15} style={{color: "white"}}/> :
						<ArrowForwardIosIcon style={{fontSize: 15}}/>}
					size="small"
					disabled={!password.length || !email.length || !confirmPassword.length || formError}
					onClick={this.handleCredentialsSubmit}
				>
					Next
				</Button>
			</div>
		)
	};
	
	handleDetailsSubmit = (details) => {
		const {email, password} = this.state;
		const {submitUserDetails, submitUserCredentials} = this.props;
		submitUserCredentials(email, password)
			.then(submitUserDetails(email, password, details))
			.catch(_noop);
	}
	
	handleDetailsFormUnmount = (details) => {
		this.setState({
			studentDetails: details,
		})
	}
	
	renderDetailsForm = () => {
		const {isSubmittingDetails, isSubmittingCredentials} = this.props;
		const{studentDetails} = this.state;
		return (
			<StudentDetailsForm
				handleSubmit={this.handleDetailsSubmit}
				studentDetails={studentDetails}
				handleUnmount={this.handleDetailsFormUnmount}
				isSubmitting={isSubmittingDetails || isSubmittingCredentials}
			/>
		);
	}
	
	onStepChange = (nextStep) => {
		this.setState(
				{ currentPage: nextStep }
			)
	}
	
	renderSteps = () => {
		const {currentPage, isCredentialsDone} = this.state;
		const {isCheckingEmail, isMobile} = this.props;
		return(
			<Steps
				type={isMobile? null: "navigation"}
				size="small"
				current={currentPage}
				onChange={this.onStepChange}
				className={cx("site-navigation-steps",styles.stepsContainer, isMobile && styles.mobileStepsContainer)}
				progressDot={isMobile}
				direction="horizontal"
				
			>
				<Step
					title="Credentials"
					icon={isCheckingEmail? <LoadingOutlined />: <UserOutlined />}
				/>
				<Step
					title="Student Details"
					disabled={!isCredentialsDone}
					icon={<SolutionOutlined />}
				/>
			</Steps>
		)
	}
	
	renderFormSection = () => {
		const {isMobile} = this.props;
		const {currentPage} = this.state;
		return (
			<div
				className={cx(styles.formSection, !isMobile && styles.fullFormSection, isMobile && styles.mobileFormSection)}>
				{this.renderSteps()}
				{currentPage === 0 && this.renderCredentialsForm()}
				{currentPage === 1 && this.renderDetailsForm()}
			</div>
		)
	}
	
	renderSubmitSuccessModal = () => {
		const {history} = this.props;
		setTimeout(() => {history.push('/login')}, 2000)
		return (<Modal
			visible
			closable={false}
			footer={null}
			centered={true}
			maskClosable={false}
			width={350}
		>
			<SmileTwoTone className={styles.modalIcon}/>
			<span className={styles.modalMessage}>Account Created Successfully</span>
		</Modal>)
	}
	
	render() {
		const {detailsSubmitStatus} = this.props;
		return (
			<div className={styles.createAccountContainer}>
				{this.renderHeadingSection()}
				{this.renderFormSection()}
				{detailsSubmitStatus === 'success'? this.renderSubmitSuccessModal(): null}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return ({
		isSubmittingCredentials: _get(state, ['common', 'createAccount', 'isSubmittingCredentials']),
		credentialsSubmitStatus: _get(state, ['common', 'createAccount', 'credentialsSubmitStatus']),
		isSubmittingDetails: _get(state, ['common', 'createAccount', 'isSubmittingDetails']),
		detailsSubmitStatus: _get(state, ['common', 'createAccount', 'detailsSubmitStatus']),
		errorMessage: _get(state, ['common', 'createAccount', 'errorMessage']),
		errorComponent: _get(state, ['common', 'createAccount', 'errorComponent']),
		isCheckingEmail: _get(state, ['common', 'createAccount', 'isCheckingEmail']),
		emailCheckStatus: _get(state, ['common', 'createAccount', 'emailCheckStatus']),
		user: _get(state, ['auth','user']),
	})
};

const mapDispatchToProps = {
	submitUserDetails,
	checkIfEmailAvailable,
	submitUserCredentials,
	resetState,
	removeCredentialsError,
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withIsMobile,
	withRouter,
)(CreateAccount);
