export const MENU_ITEMS = [
	{
		route: '/admin/dashboard',
		key: 'adminDashboard',
		label: 'Dashboard',
	},
	{
		route: '/admin/attendance',
		key: 'adminAttendance',
		label: 'Attendance',
	},
]
