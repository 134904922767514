import React from 'react';
import {Modal} from "antd";
import {FrownTwoTone, SmileTwoTone} from "@ant-design/icons";
import styles from "./StatusModal.module.scss";
import _noop from 'lodash/noop';

const StatusModal = ({onClose = _noop, time = 2000, type, message}) => {
	setTimeout(() => onClose(), time);
	let Icon;
	if(type === 'success'){
		Icon = SmileTwoTone;
	}
	else if(type === 'error'){
		Icon= FrownTwoTone;
	}
	return (<Modal
		visible
		closable={false}
		footer={null}
		centered={true}
		maskClosable={false}
		width={400}
		zIndex={3000}
	>
		<Icon className={styles.modalIcon}/>
		<span className={styles.modalMessage}>{message}</span>
	</Modal>)
};

export default StatusModal;
