import React, {Component} from 'react';
import cx from 'classnames';
import {withRouter} from "react-router-dom";
import {toast} from "react-toastify";

import _map from 'lodash/map';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {Spin} from "antd";
import Dialog from "@material-ui/core/Dialog";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";

import PageHeader from "molecules/pageHeader";
import AttendanceCalendar from "organisms/AttendanceCalendar/AttendanceCalendar";
import OpenAttendance from "../adminDashboard/organisms/AttendanceDashboard/OpenAttendance/OpenAttendance";
import AttendanceTable from "organisms/AttendanceTable";

import {fetchStreamData, setAttendanceStatus} from "actions/app.actions";
import {getAdminAttendanceEvents, getAdminAttendanceRowsData} from "helpers/attendance";

import {STATUSES} from "constants/app.general";

import DownloadAttendanceModal from "../adminDashboard/organisms/DownloadAttendanceModal";
import {
  fetchAllRegisteredUsers,
  fetchAllDeletedUsers,
} from "../../actions/users";
import {
  fetchAllAttendance,
} from "../../actions/attendance";

import {VIEW_OPTIONS, VIEW_OPTIONS_VS_LABEL} from "./Attendance.constants";
import {DOWNLOAD_ATTENDANCE_QUERY_PARAM} from '../../constants/adminPages.general';

import styles from './Attendance.module.scss'


class Attendance extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const searchParams = new URLSearchParams(location.search);
    const showDownloadAttendanceModal = searchParams.get(DOWNLOAD_ATTENDANCE_QUERY_PARAM);
    const downloadAttendanceStream = searchParams.get('stream');
    const downloadAttendanceBatch = searchParams.get('batch');
    const baseState = {
      selectedView: VIEW_OPTIONS.TABLE_LIST_VIEW,
      showOpenAttendanceModal: false,
      streams: [],
      streamMap: {},
      isOpeningAttendance: false,
      openAttendanceStatus: '',
      fetchedStreamData: false,
      attendanceEvents: [],
      loading: true,
      showDownloadAttendanceModal: false,
    };
    if (_isNil(showDownloadAttendanceModal) || _isNil(downloadAttendanceBatch) || _isNil(downloadAttendanceStream)) this.state = baseState;
    this.state = {
      ...baseState,
      showDownloadAttendanceModal,
      downloadAttendanceStream,
      downloadAttendanceBatch,
    }
  }

	componentDidMount(){
		Promise.all([fetchAllAttendance(), fetchStreamData(), fetchAllRegisteredUsers(), fetchAllDeletedUsers()]).then(([allAttendanceData, {streamsData, streams}, allRegisteredUsers, allDeletedStudents]) => {
			const attendanceEvents = getAdminAttendanceEvents(allAttendanceData, streamsData, {...allRegisteredUsers, ...allDeletedStudents});
			const attendanceRows = getAdminAttendanceRowsData(allAttendanceData, streamsData, {...allRegisteredUsers, ...allDeletedStudents});
			this.setState({
				attendanceEvents,
				attendanceRows,
				loading: false,
        streamMap: streamsData,
        streams,
        fetchedStreamData: true,
			})
		}).catch(() => toast.error('Error Fetching Attendance data...'));
	}
	
	renderCalendar = () => {
		const { attendanceEvents } = this.state;
		return <AttendanceCalendar
			className={styles.attendanceCalendar}
			events={attendanceEvents}
		/>
	}
	
	handleViewChange = (event) => {
		const selectedView = _get(event, 'target.value');
		this.setState({
			selectedView,
		})
	}
	
	toggleOpenAttendanceModal = () => {
		const { showOpenAttendanceModal, fetchedStreamData } = this.state;
		this.setState(state => ({ showOpenAttendanceModal: !state.showOpenAttendanceModal }));
		if(!showOpenAttendanceModal && !fetchedStreamData ){
			fetchStreamData().then(({streamsData, streams}) => {
				this.setState({
					streamMap: streamsData,
					streams,
					fetchedStreamData: true,
				})
			});
		}
	}
  
  toggleDownloadAttendanceModal = () => {
    this.setState(state => ({ showDownloadAttendanceModal: !state.showDownloadAttendanceModal }));
  }
	
	renderToolbar = () => {
		const { selectedView } = this.state;
		return (
			<div className={styles.toolbar}>
				<TextField
					select
					label="View"
					variant="outlined"
					onChange={this.handleViewChange}
					classes={{root: styles.viewSelectField}}
					value={selectedView}
					SelectProps={{
						MenuProps: { MenuListProps:{ classes: {root: styles.selectViewList}, dense: true } },
					}}
				>
					{_map(VIEW_OPTIONS, val => <MenuItem key={val} value={val}>{VIEW_OPTIONS_VS_LABEL[val]}</MenuItem>)}
				</TextField>
        <div className={styles.rightToolbar}>
          {(
            <Button
              variant="outlined"
              classes={{root: cx(styles.buttonRoot, styles.downloadAttendanceButtonRoot), label: styles.buttonLabel}}
              onClick={this.toggleDownloadAttendanceModal}
            >
              Download Attendance
            </Button>
          )}
          <Button
            variant="outlined"
            classes={{root: styles.buttonRoot, label: styles.buttonLabel}}
            onClick={this.toggleOpenAttendanceModal}
          >
            Open Attendance
          </Button>
        </div>
			</div>
		);
	}
	
	renderTableListView = () => {
		const { attendanceRows } = this.state;
		
		return (
			<AttendanceTable
				data={attendanceRows}
			/>
		)
	}
	
	renderSelectedViewBody = () => {
		const { selectedView } = this.state;
		switch(selectedView) {
			case VIEW_OPTIONS.TABLE_LIST_VIEW:
				return this.renderTableListView();
			case VIEW_OPTIONS.CALENDAR_VIEW:
				return this.renderCalendar();
			default:
				return null;
		}
	}
	
	renderBody = () => {
		const { loading } = this.state;
		if (loading) {
			return <Spin
				spinning
				indicator={<LoadingOutlined/>}
				tip="Fetching Attendance Details"
        className={styles.loadingSpinner}
			/>
		}
		return (
			<div className={styles.body}>
				{this.renderToolbar()}
				{this.renderSelectedViewBody()}
			</div>
		)
	}
	
	handleAttendanceStatusUpdate = (date, stream, batch, topic, openStatus, studentId, isStudentPresent) => {
		this.setState({ isOpeningAttendance: true });
		setAttendanceStatus(date, stream, batch, topic, openStatus, studentId, isStudentPresent).then(() => {
			this.setState({ openAttendanceStatus: STATUSES.SUCCESS });
		}).catch(() => {
			this.setState({openAttendanceStatus: STATUSES.FAILED});
		}).finally(() => {
			this.setState({ isOpeningAttendance: false });
		})
	}
	
	handleAttendanceStatusUpdateComplete = () => {
		const { openAttendanceStatus } = this.state;
		this.setState({ openAttendanceStatus: '' });
		if(openAttendanceStatus === STATUSES.SUCCESS){
			this.toggleOpenAttendanceModal();
		}
	}
	
	renderOpenAttendanceModal = () => {
		const {
			showOpenAttendanceModal,
			fetchedStreamData,
			streamMap,
			streams,
			isOpeningAttendance,
			openAttendanceStatus,
		} = this.state;
		return (
			<Dialog
				fullWidth
				open={showOpenAttendanceModal}
			>
				<PageHeader
					title="Open Attendance"
					hasBack
					handleGoBack={this.toggleOpenAttendanceModal}
					className={styles.openAttendanceDialogHeader}
					backIconClassName={styles.openAttendanceDialogBackIcon}
				/>
				<div className={styles.openAttendanceDialogBody}>
					{!fetchedStreamData ?
						<Spin
						tip="Loading..."
					/> :
						<OpenAttendance
              updateAttendanceStatus={this.handleAttendanceStatusUpdate}
							streamMap={streamMap}
							streams={streams}
							isOpeningAttendance={isOpeningAttendance}
							openAttendanceStatus={openAttendanceStatus}
							onAttendanceStatusUpdate={this.handleAttendanceStatusUpdateComplete}
						/>
					}
				</div>
			</Dialog>
		)
	}
  
  renderDownloadAttendanceModal = () => {
    const {
      showDownloadAttendanceModal,
      streamMap,
      attendanceRows,
      downloadAttendanceStream,
      downloadAttendanceBatch,
    } = this.state;
    return (
      <DownloadAttendanceModal
        visible={showDownloadAttendanceModal}
        onClose={this.toggleDownloadAttendanceModal}
        streamMap={streamMap}
        attendanceRows={attendanceRows}
        stream={downloadAttendanceStream}
        batch={downloadAttendanceBatch}
      />
    )
  }
	
	render() {
		return (
			<div className={styles.attendancePage}>
				<PageHeader title="Attendance"/>
				{this.renderBody()}
				{this.renderOpenAttendanceModal()}
        {this.renderDownloadAttendanceModal()}
			</div>
		);
	}
}

export default withRouter(Attendance);
