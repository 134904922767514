import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'
import produce from 'immer';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';
import _toLength from 'lodash/toLength';
import {compose} from 'recompose';
import _identity from 'lodash/identity';
import _noop from 'lodash/noop';

import {LAYOUT_TYPES} from './constants/Form.general';
import styles from './Form.module.css';
import {Button, Modal} from "antd";
import {CheckCircleTwoTone, CheckOutlined} from "@ant-design/icons";
import withIsMobile from "../../connectors/withIsMobile";

const {confirm} = Modal;

/*
Accepts a layout array and initial form values

	[
		{
			type: Container
			className: xxxx,
			Renderer: Component can be given to be used as container, if undefined a div is used
			renderOptions: for the renderer
			key: Used to recognise the relative path of the value to be stored
			children:[
				{
					type: field
					key: xxxx,
					Renderer: xxxx,
					renderOptions: xxxx,
					containerClassName: xxx,
					valueFromResponse: to convert the response from onChange to required value, if undefined identity is used,
					transformValueToRenderFormat: to convert stored value to value accepted by renderer, if undefined identity is used,
				},
				{
					type: button
					onClick: lajsdf;lk
				}
			]
		}
	]
 */

class Form extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formValues: this.props.initialFormValues,
			requiredFieldPaths: [],
			emptyRequiredFields: [],
		}
	}
	
	componentWillUnmount() {
		const {onUnmount} = this.props;
		const {formValues} = this.state;
		onUnmount(formValues);
	}
	
	handleChange = fieldPath => (value) => {
		this.setState(state => produce(state, draft => {
			_set(draft, fieldPath, value);
		}))
		const {removeRequiredErrorOnSingleChange} = this.props;
		if (removeRequiredErrorOnSingleChange) {
			this.setState({
				emptyRequiredFields: [],
			});
			return;
		}
		const {emptyRequiredFields} = this.state;
		if (emptyRequiredFields.includes(fieldPath)) {
			this.setState(produce((draft => {
				draft.emptyRequiredFields.splice(draft.emptyRequiredFields.indexOf(fieldPath), 1);
			})))
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps !== this.props) {
			this.updateRequiredFields();
		}
	}
	
	updateRequiredFields = () => {
		const {layout} = this.props;
		const requiredFieldPaths = this.identifyRequiredFields(layout, 'formValues');
		this.setState({
			requiredFieldPaths,
		});
	}
	
	identifyRequiredFields = (layout, layoutPath) => {
		return layout.reduce((acc, layoutObj) => {
			switch (layoutObj.type) {
				case LAYOUT_TYPES.CONTAINER:
					const childFieldPath = layoutObj.key ? layoutPath + '.' + layoutObj.key : layoutPath;
					const requiredFields = this.identifyRequiredFields(layoutObj.children, childFieldPath);
					if (requiredFields) {
						return acc.concat(requiredFields);
					}
					return acc;
				case LAYOUT_TYPES.FIELD:
					const fieldPath = layoutPath + '.' + layoutObj.key;
					if (layoutObj.required) {
						return acc.concat(fieldPath);
					}
					return acc;
				default:
					return acc;
			}
		}, []);
	}
	
	renderField = (fieldObj, valuePath) => {
		const {emptyRequiredFields} = this.state;
		const {hideRequired, noRequiredErrorDisplay, noRequiredErrorText} = this.props;
		const {
			Renderer,
			renderOptions,
			valueFromResponse,
			transformValueToRenderFormat,
			label,
			required
		} = fieldObj;
		const fieldValuePath = valuePath + '.' + fieldObj.key;
		const emptyError = emptyRequiredFields.includes(fieldValuePath);
		const extractValue = valueFromResponse ? valueFromResponse : _identity;
		const valueToRenderFormat = transformValueToRenderFormat ? transformValueToRenderFormat : _identity;
		const onChangeHandler = compose(
			this.handleChange(fieldValuePath),
			extractValue
		);
		const currentValue = _get(this.state, fieldValuePath);
		return (
			<div key={fieldValuePath} className={cx(fieldObj.containerClassName, styles.fieldContainer)}>
				<div className={cx(fieldObj.labelClassName, styles.fieldLabel)}>
					{label}
					{required && !hideRequired ? <span style={{color: 'red', fontWeight: 'normal'}}>*</span> : null}
					{emptyError && !noRequiredErrorDisplay &&
					<span className={styles.emptyErrorMessage}> This Field is Mandatory</span>}
				</div>
				<Renderer
					error={emptyError}
					helperText={emptyError && !noRequiredErrorText && "Mandatory Field"}
					onChange={onChangeHandler}
					value={valueToRenderFormat(currentValue)}
					{...renderOptions}
					className={cx(emptyError && !noRequiredErrorDisplay && styles.emptyFieldError, _get(renderOptions, 'className'), styles.field)}
				>
					{fieldObj.children}
				</Renderer>
			</div>
		)
	}
	
	renderButton = (buttonObj, valuePath) => {
		const {
			onClick,
			Renderer,
			renderOptions,
		} = buttonObj;
		return (
			<Renderer
				key={valuePath + '.' + buttonObj.key}
				{...renderOptions}
				onClick={onClick}
				className={cx(_get(renderOptions, 'className'), styles.button)}
			>
				{buttonObj.children}
			</Renderer>
		)
	}
	
	renderLayout = (layout, valuePath) => {
		if (!layout) return;
		return layout.map(layoutObj => {
			switch (layoutObj.type) {
				case LAYOUT_TYPES.CONTAINER:
					const childValuePath = layoutObj.key ? valuePath + '.' + layoutObj.key : valuePath;
					const {Renderer, renderOptions} = layoutObj;
					if (Renderer) {
						return (<Renderer
							key={childValuePath}
							className={cx(layoutObj.className, styles.section)}
							{...renderOptions}
						>
							{this.renderLayout(layoutObj.children, childValuePath)}
						</Renderer>);
					}
					return (<div className={styles.section}>
						{layoutObj.label ?
							<span className={cx(layoutObj.labelClassName, styles.sectionLabel)}>{layoutObj.label}</span> : null}
						{this.renderLayout(layoutObj.children, childValuePath)}
					</div>)
				case LAYOUT_TYPES.FIELD:
					return this.renderField(layoutObj, valuePath);
				case LAYOUT_TYPES.BUTTON:
					return this.renderButton(layoutObj, valuePath)
				default:
					return null;
			}
		})
	}
	
	isFieldEmpty = (fieldPath) => {
		const fieldValue = _get(this.state, fieldPath);
		if (_isNil(fieldValue) && _toLength(fieldValue) === 0 && _isEmpty(fieldValue)) {
			return fieldPath;
		}
		return false;
	}
	
	checkRequiredFields = () => {
		const {requiredFieldPaths} = this.state;
		const emptyRequiredFields = _compact(requiredFieldPaths.map(this.isFieldEmpty));
		if (emptyRequiredFields.length === 0) return true;
		this.setState({
			emptyRequiredFields,
		})
		return false;
	};
	
	handleSubmit = () => {
		const {onSubmit, submitMessage, noConfirmSubmit} = this.props;
		const {formValues} = this.state;
		if (noConfirmSubmit && this.checkRequiredFields()) {
			onSubmit(formValues);
			return;
		}
		if (this.checkRequiredFields()) {
			confirm({
				title: submitMessage ? submitMessage : 'Submit?',
				icon: <CheckCircleTwoTone twoToneColor="#52c41a"/>,
				centered: true,
				okText: "Submit",
				onOk() {
					onSubmit(formValues);
				}
			});
		}
	}
	
	renderCustomSubmitButton = () => {
		const {submitButtonComponent: SubmitButton, submitButtonLabel, submitButtonProps} = this.props
		return (
				<SubmitButton
					{...submitButtonProps}
					onClick={this.handleSubmit}
					className={cx(submitButtonProps.className, styles.submitButton)}
				>
					{submitButtonLabel}
				</SubmitButton>
		
		)
	}
	
	renderFooter = () => {
		const {isMobile, noFloatingFooter, submitButtonComponent, isSubmitLoading} = this.props;
		return (
			<div className={cx(styles.footer, !noFloatingFooter && styles.floatingFooter)}>
				{submitButtonComponent ? this.renderCustomSubmitButton() :
					<Button
						type="primary"
						size="large"
						shape={isMobile ? "circle" : "round"}
						className={styles.submitButton}
						onClick={this.handleSubmit}
						icon={isMobile ? <CheckOutlined/> : undefined}
						loading={isSubmitLoading}
					>
						{!isMobile ? "Submit" : null}
					</Button>
				}
			</div>
		)
	}
	
	render() {
		const {className, layout} = this.props;
		return (
			<div className={cx(className, styles.formContainer)}>
				{this.renderLayout(layout, 'formValues')}
				{this.renderFooter()}
			</div>
		);
	}
}

Form.propTypes = {
	initialFormValues: PropTypes.object,
	layout: PropTypes.array,
	className: PropTypes.string,
	onSubmit: PropTypes.func,
	hideRequired: PropTypes.bool,
}

Form.defaultProps = {
	initialFormValues: {},
	layout: [],
	className: '',
	onSubmit: _noop,
	hideRequired: false,
}

export default withIsMobile(Form);
