import React from 'react';
import 'antd/dist/antd.css';
import Menu from 'atoms/navigationMenu'
import {withRouter} from 'react-router-dom'
import {Avatar, Button, Popover, Spin} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import {compose} from 'recompose'
import _get from 'lodash/get'

import styles from './TopBar.module.css';
import {connect} from "react-redux";
import {logoutUser} from "actions/login.actions";


class TopBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
		};
	}
	
	renderUserPopoverTitle = () => {
		const displayName = _get(this.props, ['user', 'studentName']) || _get(this.props, ['user', 'name']);
		return(
			<div className={styles.popOverTitle}>
				<h3>{displayName}</h3>
			</div>
		)
	}
	
	handleLogoutSuccess = () => {
		const {history} = this.props;
		history.push('/login');
	}
	
	handleLogoutRequest = () => {
		const {logoutUser} = this.props;
		logoutUser().then(this.handleLogoutSuccess)
	}
	
	renderUserPopoverContent = () => {
		const {isLoggingOut} = this.props;
		return (
			<div className={styles.popoverContent}>
				<Spin
					spinning={!!isLoggingOut}
					indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
				>
					<Button
						type="link"
						onClick={this.handleLogoutRequest}
					>
						Sign Out
					</Button>
				</Spin>
			</div>
		)
	}
	
	
	render() {
		const displayName = _get(this.props, ['user', 'studentName']) || _get(this.props, ['user', 'name']);
		const initials = displayName? displayName.split(' ').reduce((acc,namePart)=>namePart.length?acc+namePart[0]:acc,''): undefined;
		const {menuItems} = this.props;
		return (
			<div className={styles.topBar}>
				<Menu
					mode="horizontal"
					sidebar={false}
					menuItems={menuItems}
				/>
				<Popover
					trigger="click"
					title={this.renderUserPopoverTitle()}
					placement="bottomRight"
					content={this.renderUserPopoverContent()}
				>
					<Button
						type="link"
						icon={
							<Avatar
								style={{backgroundColor: "grey", verticalAlign: 'middle'}}
								size="large"
								// icon={<UserOutlined/>}
								className={styles.userAvatar}
							>
								{initials}
							</Avatar>
						}
						className={styles.userAvatarButton}
						key="user"
					/>
				</Popover>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: _get(state, 'auth.user'),
	isLoggingOut: _get(state, 'auth.isLoggingOut')
});

const mapDispatchToProps = {
	logoutUser,
}


export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(TopBar);
