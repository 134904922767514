import React, {Component} from 'react';
import cx from 'classnames';
import {connect} from "react-redux";
import {compose} from "recompose";
import {Collapse} from "antd";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import PageHeader from "molecules/pageHeader";
import withIsMobile from "connectors/withIsMobile";
import AttendanceDashboard from "./organisms/AttendanceDashboard";
import InterestedStudents from "./organisms/InterestedStudents";
import RegisteredStudents from "./organisms/RegisteredStudents";
import StreamsList from './organisms/StreamsList';

import {
  fetchInterestedStudentList,
  fetchRegisteredStudentList,
  getStreamData
} from "./AdminDashboard.actions";

import {REDUX_PATH_KEY as adminReduxKey} from "../../constants/adminPages.general";
import {REDUX_PATH_KEY as adminDashboardReduxKey} from "./constants/adminDashboard.general";
import styles from './adminDashboard.module.scss';

const {Panel} = Collapse;

class AdminDashboard extends Component {
	componentDidMount() {
	  const {
      interestedStudentList,
      registeredStudentsList,
      streams,
      fetchInterestedStudentList,
      fetchRegisteredStudentList,
      getStreamData,
    } = this.props;
		if (_isEmpty(interestedStudentList)) fetchInterestedStudentList();
		if (_isEmpty(registeredStudentsList)) fetchRegisteredStudentList();
		if (_isEmpty(streams)) getStreamData();
	}
	
	reloadInterestedStudentList = () => {
		const {fetchInterestedStudentList} = this.props;
		fetchInterestedStudentList();
	}
	
	reloadRegisteredStudentList = () => {
		const {fetchRegisteredStudentList} = this.props;
		fetchRegisteredStudentList();
	}
	
	reloadStreamsList = () => {
	  const { getStreamData } = this.props;
    getStreamData();
  }
	
	renderMobileDashboard = () => {
		return (
			<Collapse
				className={cx(styles.collapseDashboard)}
				accordion
			>
        <Panel
          key={0}
          header="Mark Attendance"
        >
          <AttendanceDashboard className={cx(styles.dashboardItem)} noHeader/>
        </Panel>
        <Panel
          key={1}
          header="Streams List"
          extra={
            <IconButton
              size="small"
              onClick={this.reloadStreamsList}
              classes={{ root: styles.reloadButton }}
            >
              <RefreshIcon/>
            </IconButton>
          }
          className={cx(styles.dashboardPanel)}
        >
          <StreamsList className={cx(styles.dashboardItem)} noHeader/>
        </Panel>
        <Panel
          key={2}
          header="Registered Students"
          extra={
            <IconButton
              size="small"
              onClick={this.reloadRegisteredStudentList}
              classes={{ root: styles.reloadButton }}
            >
              <RefreshIcon/>
            </IconButton>
          }
          className={cx(styles.dashboardPanel)}
        >
          <RegisteredStudents className={cx(styles.dashboardItem)} noHeader/>
        </Panel>
				<Panel
					key={3}
					header="Interested Students"
					extra={
						<IconButton
							size="small"
							onClick={this.reloadInterestedStudentList}
							classes={{ root: styles.reloadButton }}
						>
							<RefreshIcon/>
						</IconButton>
					}
					className={cx(styles.dashboardPanel)}
				>
					<InterestedStudents className={cx(styles.dashboardItem)} noHeader/>
				</Panel>
			</Collapse>
		)
	}
	
	renderFullScreenDashboard = () => {
		return (
			<div className={cx(styles.dashboardContainer)}>
        <StreamsList className={cx(styles.dashboardItem)}/>
        <div className={cx(styles.dashboardColumn)}>
          <RegisteredStudents className={cx(styles.dashboardItem)}/>
          <InterestedStudents className={cx(styles.dashboardItem)}/>
        </div>
        <AttendanceDashboard className={cx(styles.dashboardItem)}/>
			</div>
		)
	}
	
	render() {
		const {isMobile} = this.props;
		return (
			<div className={styles.adminDashboard}>
				<PageHeader
					title="Admin Dashboard"
				/>
				{isMobile ? this.renderMobileDashboard() : this.renderFullScreenDashboard()}
			</div>
		);
	}
}

const mapStateToProps = state => ({
  interestedStudentList: _get(state, [adminReduxKey, adminDashboardReduxKey, 'interestedStudentList']),
  registeredStudentsList: _get(state, [adminReduxKey, adminDashboardReduxKey, 'registeredStudentsList']),
  streams: _get(state, [adminReduxKey, adminDashboardReduxKey, 'streams']),
})

const mapDispatchToProps = {
	fetchInterestedStudentList,
	fetchRegisteredStudentList,
  getStreamData,
}

export default compose(
	withIsMobile,
	connect(mapStateToProps, mapDispatchToProps)
)(AdminDashboard);
