import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import cx from "classnames";
import {compose} from "recompose";
import {
	MailOutlined,
	PhoneOutlined,
	LinkedinOutlined,
	WhatsAppOutlined
} from "@ant-design/icons";
import styles from './homepage.module.scss';
import logo from 'public/logo.svg'
import {
	streams,
	sections,
	reasons,
	aboutClasses
} from "./content";
import {helixImg, mobileHelixImg} from 'public';
import withIsMobile from "connectors/withIsMobile";
import {produce} from "immer";
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactCardFlip from 'react-card-flip';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router-dom";
import {
	fullScreenAccountButtonStyle,
	mobileAccountButtonStyle,
	flipBoundaries,
	initFlipStatuses,
} from "./homepage.constants";
import InterestedForm from "./InterestedForm";

class Homepage extends React.Component {
	state = {
		mobileSectionsFlipped: [],
		initFlipped: [],
		interestedFormVisible: false,
	}
	
	container = React.createRef()
	
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, true);
	}
	
	renderStream = (stream, index) => {
		if (index === 0) {
			return (<span key={stream} className={styles.streamText}>{stream}</span>);
		}
		return (
			<>
				<span key={stream + 'divider'} className={styles.streamDivider}>|</span>
				<span key={stream} className={styles.streamText}>{stream}</span>
			</>
		)
	}
	
	handleAccountClick = () => {
		const {history} = this.props;
		history.push('/login');
	}
	
	scrollDownToSections = () => {
		window.scrollBy(0, window.innerHeight);
	}
	
	renderHomeSection = () => {
		const {isMobile} = this.props;
		return (
			<div className={styles.homeSection}>
				<div className={cx(styles.logoContainer, isMobile && styles.mobileLogoContainer)}>
					<img src={logo} alt="logo" className={cx(styles.logo, isMobile && styles.mobileLogo)}/>
				</div>
				<div className={cx(styles.homeTextContainer, styles.mobileHomeTextContainer)}>
					<h1 className={cx(styles.title, isMobile && styles.mobileTitle, !isMobile && styles.fullTitle)}>
						EasyChemistry Academy
					</h1>
					<h1 className={cx(styles.tagLine, isMobile && styles.mobileTagLine)}>
						Your Coach for Success
					</h1>
					<h1 className={cx(styles.homeDescription, isMobile && styles.mobileHomeDescription)}>
						Chemistry Coaching for
						<div className={styles.streams}>
							{streams.map(this.renderStream)}
						</div>
					</h1>
					{!isMobile && <IconButton
						onClick={(this.scrollDownToSections)}
						style={{
							color: 'rgb(29, 167, 167)',
							position: 'absolute',
							bottom: '0',
						}}>
						<DownOutlined/>
					</IconButton>}
				</div>
				<Button
					variant="outlined"
					style={isMobile ? mobileAccountButtonStyle : fullScreenAccountButtonStyle}
					onClick={this.handleAccountClick}
				>
					<div className={isMobile ? styles.mobileLoginButtonText : styles.desktopLoginButtonText} >
						<span className={styles.loginButtonLoginText}>
							Login
						</span>
						<span>
							Register
						</span>
					</div>
				</Button>
			</div>
		)
	}
	
	renderFullScreenSectionText = ({title, text, image, imagePercent, mobileImage, mobileImagePercent, fullImageSizePercent}, index) => {
		return (
			<div
				className={cx(styles.section, index % 2 ? styles.leftText : styles.rightText)}
				style={{
					backgroundImage: `linear-gradient(to ${index % 2 ? "left" : "right"}, transparent, rgb(24, 136, 136) ${imagePercent}%), url("${image}"`,
					backgroundSize: `${fullImageSizePercent}%`
				}}
			>
				<div className={cx(styles.sectionText)}>
					<h1 className={cx(styles.sectionTitle)}>
						{title}
					</h1>
					<div className={cx(styles.sectionDescription)}>
						{text}
					</div>
				</div>
			</div>
		)
	}
	
	flipSection = index => () => {
		this.setState(state => produce(state, draft => {
			draft.mobileSectionsFlipped[index] = !state.mobileSectionsFlipped[index];
		}))
	}
	
	renderMobileSectionTextWithFlip = ({title, text, image, imagePercent, mobileImage, mobileImagePercent}, index) => {
		const cardFlipped = !!_get(this.state, ['mobileSectionsFlipped', index]);
		return (
			<ReactCardFlip
				isFlipped={cardFlipped}
				flipDirection="horizontal"
				infinite
			>
				<div
					onClick={this.flipSection(index)}
					className={cx(styles.section, styles.mobileSection, index % 2 ? styles.leftText : styles.rightText)}
					style={{
						backgroundImage: `linear-gradient(to ${index % 2 ? "left" : "right"}, transparent, rgb(24, 136, 136) ${mobileImagePercent ? mobileImagePercent : imagePercent}%), url("${mobileImage ? mobileImage : image}"`,
					}}
				>
					<div className={cx(styles.sectionText, styles.mobileSectionText)}>
						<h1 className={cx(styles.sectionTitle, styles.mobileSectionTitle)}>
							{title}
						</h1>
					</div>
				</div>
				
				<div
					onClick={this.flipSection(index)}
					className={cx(styles.section, styles.flippedSection)}
				>
					<h1 className={cx(styles.sectionTitle, styles.mobileSectionTitle, styles.flipSideSectionTitle)}>
						{title}
					</h1>
					<p className={cx(styles.sectionDescription, styles.mobileSectionDescription)}>
						{text}
					</p>
				</div>
			</ReactCardFlip>
		)
	}
	
	renderSection = (section, index) => {
		const {isMobile} = this.props;
		if (isMobile) return this.renderMobileSectionTextWithFlip(section, index);
		return this.renderFullScreenSectionText(section, index);
	}
	
	renderDescriptionSections = () => {
		return (
			<div className={styles.descriptionSectionsContainer}>
				{sections.map(this.renderSection)}
			</div>
		)
	}
	
	renderReason = ({title, text}) => {
		const {isMobile} = this.props;
		return (
			<div
				className={cx(styles.reason, isMobile && styles.mobileReason)}
			>
				<h2 className={cx(styles.reasonTitle, isMobile && styles.mobileReasonTitle)}>{title}</h2>
				<span className={cx(styles.reasonText, isMobile && styles.mobileReasonText)}>
					{text}
				</span>
			</div>
		)
	}
	
	renderWhySection = () => {
		const {isMobile} = this.props;
		return (
			<div className={styles.whySection}>
				<h1 className={cx(styles.whyTitle, isMobile && styles.mobileWhyTitle)}>Why Dr Padmaja?</h1>
				{isMobile ?
					<Carousel
						className={styles.reasonsContainer}
						renderItem={reason => <div className={styles.carouselItemContainer}> {reason} </div>}
						interval={3000}
						autoPlay
						infiniteLoop
						showStatus={false}
						renderArrowNext={_noop}
						renderArrowPrev={_noop}
						renderThumbs={_noop}
					>
						{reasons.map(this.renderReason)}
					</Carousel> :
					<div className={styles.reasonsContainer}>
						{reasons.map(this.renderReason)}
					</div>
				}
				<div style={{backgroundImage: `url("${isMobile ? mobileHelixImg : helixImg}")`}} className={styles.whyImage}/>
			</div>
		)
	}
	
	renderContactSection = () => {
		const {isMobile} = this.props;
		return (
			<div className={cx(styles.contactSection, isMobile && styles.mobileContactSection)}>
				<div className={cx(styles.contactUsTitleContainer, isMobile && styles.mobileContactUsTitleContainer)}>
					<h1 className={cx(styles.contactUsTitle, isMobile && styles.mobileContactUsTitle)}>Contact Us</h1>
				</div>
				<div className={cx(styles.meansOfContactContainer, isMobile && styles.mobileMeansOfContactContainer)}>
					<div className={cx(styles.contactContainer, styles.emailContact, isMobile && styles.mobileContactContainer)}>
						<h2>
							<MailOutlined/> Drop an email at
						</h2>
						<h2 className={cx(styles.contact, isMobile && styles.mobileContact)}>
							<a href="mailto:tapadmaja@gmail.com" className={cx(styles.contact, styles.email)}>tapadmaja@gmail.com</a>
						</h2>
					</div>
					<div className={cx(styles.contactContainer, styles.phoneContact, isMobile && styles.mobileContactContainer)}>
						<h2>
							<PhoneOutlined/> Call / <WhatsAppOutlined/> Whatsapp
						</h2>
						<h2 className={cx(styles.contact, isMobile && styles.mobileContact)}>
							<a href="tel:+91-934-840-5065" className={cx(styles.contact, styles.phoneNumber)}>+91 934-840-5065</a>
						</h2>
					</div>
					<div
						className={cx(styles.contactContainer, styles.linkedinContact, isMobile && styles.mobileContactContainer)}>
						<h2>
							<LinkedinOutlined style={{fontSize: isMobile ? '1.2rem' : '1.8rem',}}/> Drop a message at
						</h2>
						<h2 className={cx(styles.contact, isMobile && styles.mobileContact)}>
							<a href="https://in.linkedin.com/in/padmaja-naishadham"
							   className={cx(styles.contact, styles.linkedin)}
							   target="_blank"
							   rel="noopener noreferrer"
							>
								Dr Padmaja
							</a>
						</h2>
					</div>
				</div>
			</div>
		)
	}
	
	renderAboutClassesBullet = ({text, tip}) => {
		const {isMobile} = this.props;
		return (
			<>
				<li className={cx(styles.aboutBullet, isMobile && styles.mobileAboutBullet)} key={text}>
					<span>{text}</span>
					{tip}
				</li>
			</>
		)
	}
	
	renderAboutClasses = () => {
		const {isMobile} = this.props;
		return (
			<div className={cx(styles.aboutContainer, isMobile && styles.mobileAboutContainer)}>
				<div className={cx(styles.aboutSectionTitle, isMobile && styles.mobileAboutSectionTitle)}>
					<h1>About Classes</h1>
				</div>
				<div className={cx(styles.aboutListContainer, isMobile && styles.mobileAboutListContainer)}>
					<ul
						className={cx(styles.aboutList, isMobile && styles.mobileAboutList)}>{aboutClasses.map(this.renderAboutClassesBullet)}</ul>
				</div>
			</div>
		)
	}
	
	handleCardFlip = scrollPosition => (flipBoundary, index) => {
		const initFlipStatus = _get(this.state, ['initFlipped', index]);
		if (scrollPosition > flipBoundary.start && !initFlipStatus) {
			this.setState(produce(draft => {
				draft.mobileSectionsFlipped[index] = true;
				draft.initFlipped[index] = initFlipStatuses.INIT_FLIP;
			}))
		}
		if((scrollPosition > flipBoundary.bottomEnd || scrollPosition < flipBoundary.topEnd) && initFlipStatus === initFlipStatuses.INIT_FLIP){
			this.setState(produce(draft => {
				draft.mobileSectionsFlipped[index] = false;
				draft.initFlipped[index] = initFlipStatuses.INIT_FLIP_BACK;
			}))
		}
	}
	
	handleScroll = () => {
		const {isMobile} = this.props;
		if(!isMobile) return;
		const scrollPosition = window.pageYOffset / window.innerHeight;
		flipBoundaries.forEach(this.handleCardFlip(scrollPosition));
	}
	
	toggleInterestedForm = () => {
		this.setState(state =>({
			interestedFormVisible: !state.interestedFormVisible,
		}))
	}
	
	renderLinksSection = () => {
		const {isMobile} = this.props;
		return(
			<div className={cx(styles.linksSection)}>
				<h1 className={cx(styles.interestedHeading, isMobile && styles.mobileInterestedHeading)}>
					Interested?
					<Button
						variant="outlined"
						classes={{
							label: cx(styles.interestedHeading, styles.interestedFillFormButton, isMobile && styles.mobileInterestedHeading),
							root: cx(styles.interestedFillFormButtonContainer, isMobile && styles.mobileInterestedFillFormButtonContainer)
						}}
						onClick={this.toggleInterestedForm}
					>
						Fill This Form
					</Button>
				</h1>
			</div>
		)
	}
	
	render() {
		const {interestedFormVisible} = this.state;
		return (
			<div ref={this.container} className={styles.homePageContainer}>
				{this.renderHomeSection()}
				{this.renderDescriptionSections()}
				{this.renderWhySection()}
				{this.renderAboutClasses()}
				{this.renderLinksSection()}
				{this.renderContactSection()}
				<InterestedForm
					visible={interestedFormVisible}
					onClose={this.toggleInterestedForm}
				/>
			</div>
		)
	}
}

export default compose(
	withIsMobile,
	withRouter,
)(Homepage);
