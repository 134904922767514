import React from 'react';
import styles from "./StudentDetailsForm.module.scss";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import cx from "classnames";
import Form from "organisms/form/Form";

import {getDetailsFormLayout} from "./studentDetailsForm.formConfig";

const StudentDetailsForm = ({
	handleSubmit,
	studentDetails,
	handleUnmount,
	isSubmitting,
	submitButtonLabel,
	className,
}) => {
	const layout = getDetailsFormLayout();
	return (
		<Form
			layout={layout}
			hideRequired
			noRequiredErrorDisplay
			noFloatingFooter
			removeRequiredErrorOnSingleChange
			className={cx(styles.detailsFormContainer, className)}
			onSubmit={handleSubmit}
			noRequiredErrorText
			noConfirmSubmit
			submitButtonComponent={Button}
			initialFormValues={studentDetails}
			submitButtonProps={{
				variant: "contained",
				color: "primary",
				style: {backgroundColor: 'rgb(41,127,245)', textTransform: 'none'},
				endIcon: (isSubmitting) ? <CircularProgress size={15} style={{color: "white"}}/> :
					<ArrowForwardIosIcon style={{fontSize: 15}}/>
			}}
			submitButtonLabel={submitButtonLabel || "Create Account"}
			onUnmount={handleUnmount}
		/>
	);
}

export default StudentDetailsForm;
