import React, {Component} from 'react';
import cx from "classnames";
import {connect} from "react-redux";

import _get from 'lodash/get';

import Button from "@material-ui/core/Button";
import {Collapse, Spin} from "antd";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import {FrownOutlined, LoadingOutlined, MehOutlined} from '@ant-design/icons';

import StudentDetails from "../../molecules/StudentDetails";
import {fetchInterestedStudentList, removeInterestedStudent} from "../../AdminDashboard.actions";

import {REDUX_PATH_KEY as adminDashboardReduxKey} from "../../constants/adminDashboard.general";
import {REDUX_PATH_KEY as adminReduxKey} from "../../../../constants/adminPages.general";

import styles from "./InterestedStudents.module.scss";
import {toast} from "react-toastify";

const {Panel} = Collapse;

class InterestedStudents extends Component {
  getHandleRemoveInterestedStudent = uid => () => {
    const {fetchInterestedStudentList} = this.props;
    return removeInterestedStudent(uid).then(() => {
      toast.success('Successfully Removed Interested Student');
      fetchInterestedStudentList();
    }).catch(() => {
      toast.error('Error Removing Interested Student');
    });
  }
  
	renderListItem = (studentDetails, index) => {
    const { id } = studentDetails;
    return (
			<Panel key={index} header={studentDetails.studentName} className={cx(styles.interestedStudentItem)}>
        <div className={styles.interestedStudentItemBody}>
          <StudentDetails
            studentDetails={studentDetails}
          />
          <Button
            variant="outlined"
            classes={{outlined: styles.removeInterestedStudentButton, label: styles.removeInterestedStudentButtonLabel}}
            onClick={this.getHandleRemoveInterestedStudent(id)}
          >
            Remove
          </Button>
        </div>
			</Panel>
		);
	}
	
	renderList = () => {
		const {interestedStudentList, isInterestedStudentListFetchError} = this.props;
		if (isInterestedStudentListFetchError) {
			return <h2 className={styles.errorMessage}><FrownOutlined/> Error Fetching Data</h2>
		}
		if (!interestedStudentList.length) {
			return <h2 className={styles.emptyListMessage}><MehOutlined/> No New Interested Students</h2>
		}
		return (
			<div className={styles.listContainer}>
				<Collapse
					className={cx(styles.interestedStudentList)}
					accordion
					destroyInactivePanel
				>
					{interestedStudentList.map(this.renderListItem)}
				</Collapse>
			</div>
		)
	}
	
	reloadInterestedStudentList = () => {
		const {fetchInterestedStudentList} = this.props;
		fetchInterestedStudentList();
	};
	
	renderHeader = () => {
		return (
			<div className={styles.header}>
				<h2>Interested Students</h2>
				<IconButton
					size="small"
					classes={{root: styles.reloadButton}}
					onClick={this.reloadInterestedStudentList}
				>
					<RefreshIcon/>
				</IconButton>
			</div>
		)
	}
	
	render() {
		const {className, isFetchingInterestedStudentList, noHeader} = this.props;
		return (
			<div className={cx(className)}>
				<div className={cx(styles.container)}>
					{!noHeader && this.renderHeader()}
					{isFetchingInterestedStudentList ? <Spin
							spinning
							tip="Fetching Interested Students"
							className={styles.loader}
							indicator={<LoadingOutlined style={{fontSize: '2rem'}}/>}
						/> :
						this.renderList()
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	isFetchingInterestedStudentList: _get(state, [adminReduxKey, adminDashboardReduxKey, 'isFetchingInterestedStudentList']),
	interestedStudentList: _get(state, [adminReduxKey, adminDashboardReduxKey, 'interestedStudentList']),
	isInterestedStudentListFetchError: _get(state, [adminReduxKey, adminDashboardReduxKey, 'isInterestedStudentListFetchError']),
});

const mapDispatchToProps = {
	fetchInterestedStudentList,
}

export default connect(mapStateToProps, mapDispatchToProps)(InterestedStudents);
