import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import PageHeader from "molecules/pageHeader";
import Logo from "atoms/logo";
import styles from './ResetPassword.module.scss';
import {Button, Input, Spin} from "antd";
import {toast} from "react-toastify";
import {getAccountEmail, resetPassword} from "./ResetPassword.actions";
import StatusModal from "../../../../molecules/StatusModal";

class ResetPassword extends Component {
	state = {
		password: "",
		confirmPassword: '',
		loading: true,
		email: '',
	}
	
	componentDidMount = () => {
		const oobCode = this.getOobCode();
		getAccountEmail(oobCode)
			.then((email) => {
				this.setState({
					loading: false,
					email,
				})
			})
			.catch(() => {
				this.setState({
					loading: false,
					hasError: true,
				})
			})
	}
	
	handleChange = id => (event) => {
		const value = event.target.value;
		this.setState({
			[id]: value
		});
	}
	
	getOobCode = () => {
		const {location} = this.props;
		this.queryParams = new URLSearchParams(location.search);
		return this.queryParams.get('oobCode');
	}
	
	handleSubmit = () => {
		const {password, confirmPassword} = this.state;
		if (password !== confirmPassword) {
			toast.error('Passwords don\'t match');
			return;
		}
		const oobCode = this.getOobCode();
		resetPassword(oobCode, password)
			.then(() => {
			this.setState({
				status: 'success',
			})
		})
			.catch((error) => {
				if(error.code === "auth/weak-password"){
					toast.error("Weak Password. Please try again")
					return;
				}
				this.setState({
					hasError: true,
				})
			})
	}
	
	renderLoader = () => {
		return (
			<div>
				<Spin
					tip="Loading..."
				/>
			</div>
		)
	}
	
	renderErrorContent = () => {
		return (
			<>
				<h2>Invalid or Expired Reset Request</h2>
				<h2>Please request to reset password again</h2>
			</>
		)
	}
	
	renderContent = () => {
		const {email, password, confirmPassword, loading, hasError} = this.state;
		if (loading) return this.renderLoader();
		if (hasError) return this.renderErrorContent();
		return (
			<>
				<h2>Please enter the new password of {email}</h2>
				<Input.Password
					placeholder="New Password"
					className={styles.passwordInput}
					onChange={this.handleChange('password')}
					value={password}
				/>
				<Input.Password
					placeholder="Confirm New Password"
					className={styles.confirmPasswordInput}
					onChange={this.handleChange('confirmPassword')}
					value={confirmPassword}
				/>
				<Button
					type="primary"
					className={styles.resetPasswordButton}
					onClick={this.handleSubmit}
					disabled={!password || !confirmPassword}
				>
					Reset Password
				</Button>
			</>
		)
	}
	
	renderSuccessModal = () => {
		const {history} = this.props;
		return <StatusModal
			message="Reset Password Successfully"
			onClose={() => history.push('/login')}
			type="success"
		/>
	}
	
	render() {
		const {status} = this.state;
		return (
			<div className={styles.resetPasswordPage}>
				<Logo containerClassName={styles.logo}/>
				<PageHeader
					title="Reset Password"
				/>
				<div className={styles.content}>
					{this.renderContent()}
				</div>
				{status === 'success' && this.renderSuccessModal()}
			</div>
		);
	}
}

export default withRouter(ResetPassword);
