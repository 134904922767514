const ACTION_TYPES = {
	CREDENTIALS_SUBMIT_REQUEST: 'CREDENTIALS_SUBMIT_REQUEST',
	CREDENTIALS_SUBMIT_SUCCESS: 'CREDENTIALS_SUBMIT_SUCCESS',
	CREDENTIALS_SUBMIT_FAILURE: 'CREDENTIALS_SUBMIT_FAILURE',
	EMAIL_CHECK_REQUEST: 'EMAIL_CHECK_REQUEST',
	EMAIL_CHECK_SUCCESS: 'EMAIL_CHECK_SUCCESS',
	EMAIL_CHECK_FAILURE: 'EMAIL_CHECK_FAILURE',
	DETAILS_SUBMIT_REQUEST: 'DETAILS_SUBMIT_REQUEST',
	DETAILS_SUBMIT_SUCCESS: 'DETAILS_SUBMIT_SUCCESS',
	DETAILS_SUBMIT_FAILURE: 'DETAILS_SUBMIT_FAILURE',
	RESET_CREATE_ACCOUNT_STATE: 'RESET_CREATE_ACCOUNT_STATE',
	REMOVE_CREDENTIALS_ERROR: 'REMOVE_CREDENTIALS_ERROR',
}

export default ACTION_TYPES;
