import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import './index.css';
import App from 'pages/base/App';
import appStore from "./store";
import 'react-virtualized/styles.css';


ReactDOM.render(
	<Provider store={appStore}>
		<Router>
				<App/>
		</Router>
	</Provider>,
	document.getElementById('root')
);

