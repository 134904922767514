import ACTION_TYPES from "./login.actionTypes";
import {toast} from "react-toastify";

import {firebaseAppAuth, userDetails} from 'services/firebase';

const handleUserDetailsFetchSuccess = (dispatch, user) => (snapshot) => {
	let extraUserDetails = snapshot.val();
	dispatch({
		type: ACTION_TYPES.LOGIN_SUCCESS,
		payload: {
			...extraUserDetails,
			...user,
			actualEmail: extraUserDetails.email,
		}
	});
};

const handleUserDetailsFetchFailure = (dispatch) => () => {
	dispatch({
		type: ACTION_TYPES.LOGIN_FAILURE,
	})
}

export const handleSignInSuccess = dispatch => (user) => {
	userDetails(user.uid).once('value')
		.then(handleUserDetailsFetchSuccess(dispatch, user))
		.catch(handleUserDetailsFetchFailure(dispatch))
}


const handleSignInError = dispatch => (err) => {
	if(err.code === "auth/wrong-password"){
		toast.error("Incorrect password");
	}
	else {
		toast.error('Login Error');
	}
	dispatch({
		type: ACTION_TYPES.LOGIN_FAILURE,
		payload: err,
	})
}

export const loginUser = (email, password) => dispatch => {
	dispatch({type: ACTION_TYPES.LOGIN_REQUEST});
	firebaseAppAuth.signInWithEmailAndPassword(email, password)
		.then(handleSignInSuccess(dispatch))
		.catch(handleSignInError(dispatch));
};

const handleLogoutSuccess = dispatch => () => {
	dispatch({type: ACTION_TYPES.LOGOUT_SUCCESS});
};

const handleLogoutFailure = dispatch => () => {
	dispatch({type: ACTION_TYPES.LOGOUT_FAILURE});
}

export const logoutUser = () => dispatch => {
	dispatch({type: ACTION_TYPES.LOGOUT_REQUEST});
	return firebaseAppAuth.signOut()
		.then(handleLogoutSuccess(dispatch))
		.catch(handleLogoutFailure(dispatch));
}
