import React, {Component} from 'react';
import Login from "pages/commonPages/pages/login";
import RouteSwitch from "atoms/routeSwitch";
import Homepage from "pages/commonPages/pages/homepage";
import CreateAccount from 'pages/commonPages/pages/createAccount'
import ResetPassword from "pages/commonPages/pages/resetPassword";

const loginRoute = componentProps => ({
	path: '/login',
	component: Login,
	componentProps,
})

const homepageRoute = componentProps => ({
	path: '/home',
	component: Homepage,
	componentProps,
})

const createAccountRoute = componentProps=> ({
	path: '/createAccount',
	component: CreateAccount,
	componentProps,
})

const resetPasswordRoute = componentProps => ({
	path: '/resetPassword',
	component: ResetPassword,
	componentProps,
})

const getRoutes = componentProps => ([
	loginRoute(componentProps),
	homepageRoute(componentProps),
	createAccountRoute(componentProps),
	resetPasswordRoute(componentProps),
])

class Routes extends Component {
	render() {
		return (
			<RouteSwitch
				routes={getRoutes({...this.props})}
				redirectPath="/home" // TODO: Need to redirect to common dashboard page
			/>
		);
	}
}


export default Routes;
