import moment from 'moment';

import _get from 'lodash/get';
import _isNil from 'lodash/isNil';

import {DATE_FORMAT} from "../../../../../../constants/app.general";
import {EXPIRATION_PERIOD_IN_MS} from "../../../../constants/adminPages.general";

export const compareRegisteredStudentForSort = (stud1, stud2) => {
  const isStud1AssignedStream = !!(stud1.stream && stud1.batch);
  const isStud2AssignedStream = !!(stud2.stream && stud2.batch);
  if (isStud1AssignedStream !== isStud2AssignedStream) return isStud1AssignedStream ? 1 : -1;
  const isStud1Inactive = isStudentInactive(stud1);
  const isStud2Inactive = isStudentInactive(stud2);
  return isStud1Inactive && !isStud2Inactive ? -1 : 1
}

export const isStudentInactive = (studentDetails) => {
  const lastPresentDateString = _get(studentDetails, 'lastPresentDate');
  if (_isNil(lastPresentDateString)) return false;
  const lastPresentDate = moment(lastPresentDateString, DATE_FORMAT).toDate();
  return Date.now() - lastPresentDate > EXPIRATION_PERIOD_IN_MS;
}
