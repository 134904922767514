import { handleActions } from "redux-actions";

import ACTION_TYPES from "./AdminDashboard.actionTypes";

const INITIAL_STATE = {
	isFetchingInterestedStudentList: false,
	interestedStudentList: [],
	isInterestedStudentListFetchError: false,
	isFetchingRegisteredStudentList: false,
	registeredStudentsList: [],
	isRegisteredStudentListFetchError: false,
	isFetchingAttendance: [],
	isAttendanceFetchError: false,
	streams: [],
	streamMap: {},
	isFetchingStreamData: false,
	streamDataFetchError: false,
	isOpeningAttendance: false,
	openAttendanceStatus: '',
}

const actionHandlers = {
	[ACTION_TYPES.FETCH_INTERESTED_STUDENT_LIST_REQUEST]: (state) => ({
		...state,
		isFetchingInterestedStudentList: true,
	}),
	[ACTION_TYPES.FETCH_INTERESTED_STUDENT_LIST_SUCCESS]: (state, {payload}) => ({
		...state,
		isFetchingInterestedStudentList: false,
		interestedStudentList: payload,
	}),
	[ACTION_TYPES.FETCH_INTERESTED_STUDENT_LIST_FAILURE]: (state) => ({
		...state,
		isFetchingInterestedStudentList: false,
		isInterestedStudentListFetchError: true,
	}),
  [ACTION_TYPES.FETCH_REGISTERED_STUDENT_LIST_REQUEST]: (state) => ({
		...state,
		isFetchingRegisteredStudentList: true,
	}),
  [ACTION_TYPES.FETCH_REGISTERED_STUDENT_LIST_SUCCESS]: (state, {payload}) => ({
    ...state,
    ...payload,
    isFetchingRegisteredStudentList: false,
  }),
  [ACTION_TYPES.FETCH_REGISTERED_STUDENT_LIST_FAILURE]: (state) => ({
		...state,
		isFetchingRegisteredStudentList: false,
		isRegisteredStudentListFetchError: true,
	}),
	[ACTION_TYPES.FETCH_ATTENDANCE_REQUEST]: (state) => ({
		...state,
		isFetchingAttendance: true,
	}),
	[ACTION_TYPES.FETCH_ATTENDANCE_SUCCESS]: (state) => ({
		...state,
		isFetchingAttendance: false,
	}),
	[ACTION_TYPES.FETCH_ATTENDANCE_FAILURE]: (state) => ({
		...state,
		isFetchingAttendance: false,
		isAttendanceFetchError: true,
	}),
	[ACTION_TYPES.FETCH_STREAM_DATA_REQUEST]: (state) => ({
		...state,
		isFetchingStreamData: true,
	}),
	[ACTION_TYPES.FETCH_STREAM_DATA_SUCCESS]: (state, {payload:{streams, streamMap}}) => ({
		...state,
		isFetchingStreamData: false,
		streams,
		streamMap,
	}),
	[ACTION_TYPES.FETCH_STREAM_DATA_FAILURE]: (state) => ({
		...state,
		isFetchingStreamData: false,
		streamDataFetchError: true,
	}),
	[ACTION_TYPES.UPDATE_ATTENDANCE_REQUEST]: (state) => ({
		...state,
		isOpeningAttendance: true,
	}),
	[ACTION_TYPES.UPDATE_ATTENDANCE_SUCCESS]: (state) => ({
		...state,
		isOpeningAttendance: false,
		openAttendanceStatus: 'success',
	}),
	[ACTION_TYPES.UPDATE_ATTENDANCE_FAILURE]: (state) => ({
		...state,
		isOpeningAttendance: false,
		openAttendanceStatus: 'failed',
	}),
	[ACTION_TYPES.RESET_OPEN_ATTENDANCE_STATUS]: state => ({
		...state,
		openAttendanceStatus: '',
	})
};

export default handleActions(actionHandlers, INITIAL_STATE);
