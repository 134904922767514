import moment from 'moment';

import _isEmpty from 'lodash/isEmpty';
import _keys from 'lodash/keys';
import _get from 'lodash/get';
import _max from 'lodash/max';
import _map from 'lodash/map';
import _some from 'lodash/some';
import _reduce from 'lodash/reduce';
import _memoize from 'lodash/memoize';

import {DATE_FORMAT} from "constants/app.general";

import {EXPIRATION_PERIOD_IN_MS} from "../../../../constants/adminPages.general";

export const getIsSomeBatchStudentListEmpty = batches => _some(batches, batchDetails => _isEmpty(batchDetails.students));

const getIsBatchInactive = (batchAttendanceDetails) => {
  const batchAttendanceDates = _map(_keys(batchAttendanceDetails), dateString => moment(dateString, DATE_FORMAT).toDate());
  const latestDate = _max(batchAttendanceDates);
  return Date.now() - latestDate > EXPIRATION_PERIOD_IN_MS;
}

export const getIsSomeBatchInactive = _memoize((batches, streamAttendanceDetails) => _reduce(batches, (acc, batchDetails, batchName) => {
  const isBatchInactive = getIsBatchInactive(_get(streamAttendanceDetails, batchName))
  return {
    isSomeBatchInactive: acc.isSomeBatchInactive || isBatchInactive,
    batchInactivityMap: {
      ...acc.batchInactivityMap,
      [batchName]: isBatchInactive,
    }
  }
}, {
  isSomeBatchInactive: false,
  batchInactivityMap: {},
}));
