import React, {Component} from 'react';
import cx from 'classnames';
import {connect} from "react-redux";
import {compose} from "recompose";
import Button from "@material-ui/core/Button";
import moment from "moment-timezone";

import _get from "lodash/get";
import _isNil from 'lodash/isNil';

import PageHeader from "molecules/pageHeader";
import withIsMobile from "connectors/withIsMobile";
import {fetchTodaysAttendanceDetails, markAttendance} from "./StudentDashboard.actions";
import {getBatchName} from "helpers/general";

import styles from './StudentDashboard.module.scss';
import {Spin} from "antd";
import {RightOutlined, LoadingOutlined} from "@ant-design/icons";
import {toast} from "react-toastify";

const ATTENDANCE_STATUSES = {
	NOT_OPEN: 'NOT_OPEN',
	OPEN: 'OPEN',
	CLOSED: 'CLOSED',
}

const DATE_FORMAT = "Do MMMM, YYYY";

class StudentDashboard extends Component {
	state = {loading: true}
	
	componentDidMount() {
		const {stream, batch} = _get(this.props, 'user');
		const date = moment().tz('Asia/Kolkata').format(DATE_FORMAT);
    this.setState({ date });
		fetchTodaysAttendanceDetails(stream, batch, date).then(this.handleTodaysAttendanceDetailsFetchSuccess).catch(this.handleTodaysAttendanceDetailsFetchFailure);
	}
	
	handleTodaysAttendanceDetailsFetchSuccess = (attendanceDetails) => {
		if (!attendanceDetails || _isNil(attendanceDetails.open)) {
			this.setState({
				loading: false,
				attendanceStatus: ATTENDANCE_STATUSES.NOT_OPEN
			});
			return;
		}
		const {uid} = _get(this.props, 'user');
		const {open: isAttendanceOpen, ...students} = attendanceDetails;
		if (!isAttendanceOpen) {
			this.setState({
				loading: false,
				studentAttendanceStatus: !!students[uid],
				attendanceStatus: ATTENDANCE_STATUSES.CLOSED
			});
			return;
		}
		this.setState({
			loading: false,
			attendanceStatus: ATTENDANCE_STATUSES.OPEN,
			studentAttendanceStatus: !!students[uid],
		});
	}
	
	handleTodaysAttendanceDetailsFetchFailure = () => {
		this.setState({
			loading: false,
			hasError: true
		})
	}
	
	markAttendance = () => {
		const {stream, batch, uid} = _get(this.props, 'user');
		const { date } = this.state;
		this.setState({
			isMarkingAttendance: true
		});
		markAttendance(stream, batch, date, uid).then(this.handleMarkAttendanceSuccess).catch(this.handleMarkAttendanceFailure);
	}
	
	handleMarkAttendanceSuccess = () => {
		const {stream, batch} = _get(this.props, 'user');
    const { date } = this.state;
		this.setState({
			isMarkingAttendance: false
		});
		fetchTodaysAttendanceDetails(stream, batch, date).then(this.handleTodaysAttendanceDetailsFetchSuccess)
			.catch(this.handleTodaysAttendanceDetailsFetchFailure)
	}
	
	handleMarkAttendanceFailure = () => {
		this.setState({
			isMarkingAttendance: false
		});
		toast.error('Unable to Mark Attendance');
	}
	
	renderAttendanceSection = () => {
		const {loading, hasError, attendanceStatus, studentAttendanceStatus, isMarkingAttendance} = this.state;
		if (loading) {
			return <Spin
				spinning
				indicator={<LoadingOutlined/>}
				tip="Fetching Attendance Details"
			/>
		}
		if (hasError) {
			return <h2 className={styles.errorMessage}> Error fetching attendance details</h2>
		}
		if(studentAttendanceStatus){
			return <h2> Attendance marked for today</h2>
		}
		switch (attendanceStatus) {
			case ATTENDANCE_STATUSES.NOT_OPEN:
				return <h2> Attendance not open yet</h2>
			case ATTENDANCE_STATUSES.CLOSED:
				return <h2>Attendance Closed</h2>
			case ATTENDANCE_STATUSES.OPEN:
				return (
					<Button
						classes={{root: styles.markAttendanceButton, label: styles.markAttendanceButtonLabel}}
						endIcon={isMarkingAttendance ? <LoadingOutlined/> : <RightOutlined style={{fontSize: '1em'}}/>}
						onClick={this.markAttendance}
					>
						Mark Attendance For Today
					</Button>
				)
			default:
				return null;
		}
	}
	
	renderStreamDetails = () => {
		const {stream, batch} = _get(this.props, 'user');
		return (<div className={styles.streamsDetails}>
			<div>
				<b>Stream: </b> {stream}
			</div>
			<div>
				<b>Batch: </b> {getBatchName(batch)}
			</div>
		</div>)
	}
	
	render() {
		const {stream, batch} = _get(this.props, 'user');
		return (
			<div className={styles.studentDashboard}>
				<PageHeader
					title="Student Dashboard"
				/>
				{(!stream || !batch ) ?
					<h2 style={{textAlign: 'center', marginTop: '10%'}}>You are yet to be admitted to a stream. Please contact the instructor</h2> :
					<div className={cx(styles.dashboardContainer)}>
						{this.renderStreamDetails()}
						{this.renderAttendanceSection()}
					</div>
				}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: _get(state, 'auth.user'),
});

const mapDispatchToProps = {}


export default compose(
	withIsMobile,
	connect(mapStateToProps, mapDispatchToProps,)
)(StudentDashboard);
