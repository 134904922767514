import React, {Component} from 'react';
import Dialog from "@material-ui/core/Dialog";
import PageHeader from "molecules/pageHeader";

import styles from './ForgotPasswordModal.module.scss';
import Input from "antd/es/input";
import {Button} from "antd";
import SendOutlined from "@ant-design/icons/lib/icons/SendOutlined";
import {isUserRegistered, sendResetEmail} from "./ForgotPasswordModal.actions";
import {toast} from "react-toastify";
import {onEnter} from "helpers/onEnter";
import withIsMobile from "../../../../../connectors/withIsMobile";

class ForgotPasswordModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: this.props.defaultEmail,
			isValidEmail: this.validateEmail(this.props.defaultEmail),
			isSendingEmail: false,
			isEmailSent: false,
		}
	}
	
	handleEmailChange = (event) => {
		const value = event.target.value;
		this.setState({
			email: value,
			isValidEmail: this.validateEmail(value),
		})
	}
	
	validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	
	handleResetEmailSentSuccess = () => {
		this.setState({
			isSendingEmail: false,
			isEmailSent: true,
		});
	}
	
	handleResetEmailSentFailure = () => {
		this.setState({
			isSendingEmail: false,
		})
		toast.error('Error Occurred');
	}
	
	sendResetEmail = () => {
		const {email} = this.state;
		this.setState({
			isSendingEmail: true
		});
		isUserRegistered(email).then(
			(isUserRegistered) => {
				if (isUserRegistered) {
					sendResetEmail(email).then(this.handleResetEmailSentSuccess).catch(this.handleResetEmailSentFailure)
				} else {
					toast.error("Email ID not registered");
				}
			}
		).catch(this.handleResetEmailSentFailure)
	}
	
	renderRequestResetEmailContent = () => {
		const {email, isValidEmail, isSendingEmail} = this.state;
		return (
			<>
				<Input
					placeholder="Email"
					onChange={this.handleEmailChange}
					type="email"
					className={styles.emailInput}
					value={email}
					onKeyDown={onEnter(this.sendResetEmail)}
				/>
				<Button
					type="primary"
					icon={<SendOutlined/>}
					onClick={this.sendResetEmail}
					className={styles.resetPasswordButton}
					disabled={!isValidEmail}
					loading={isSendingEmail}
				>
					Reset Password
				</Button>
			</>
		);
	}
	
	renderEmailSuccessContent = () => {
		return (
			<>
				<h2>Reset email has been sent</h2>
				<h3>Proceed with the link in the mail to reset password</h3>
			</>
		)
	}
	
	renderContent = () => {
		const {isEmailSent} = this.state;
		return (<div className={styles.content}>
				{isEmailSent ? this.renderEmailSuccessContent() : this.renderRequestResetEmailContent()}
			</div>
		)
	}
	
	render() {
		const {onClose, isMobile} = this.props;
		return (
			<Dialog
				open={true}
				fullWidth={!isMobile}
				fullScreen={isMobile}
			>
				<PageHeader
					title="Forgot Password"
					hasBack
					handleGoBack={onClose}
					className={styles.header}
					backIconClassName={styles.backIcon}
				/>
				{this.renderContent()}
			</Dialog>
		);
	}
}

export default withIsMobile(ForgotPasswordModal);
