import ACTION_TYPES from "./interestedForm.actionTypes";
import { v4 as uuidv4 } from 'uuid';
import {interestedStudentsDetails} from 'services/firebase';
import {sendEmailToAdmin} from "services/emailServices";
import {interestedStudentEmailGenerator} from 'helpers/emailGenerator';
import {STUDENT_DETAILS_KEY_LABEL_MAP} from "constants/app.general";


const handleSubmitDetailsSuccess = (details, dispatch) => () => {
	dispatch({type: ACTION_TYPES.SUBMIT_DETAILS_SUCCESS});
}

const handleSubmitDetailsFailure = (dispatch) => () => {
	dispatch({type: ACTION_TYPES.SUBMIT_DETAILS_FAILURE});
}

export const submitDetails = (details) => dispatch => {
	dispatch({type: ACTION_TYPES.SUBMIT_DETAILS_REQUEST});
	const id = uuidv4();
	const {subject, body} = interestedStudentEmailGenerator(details, STUDENT_DETAILS_KEY_LABEL_MAP);
	return Promise.all([interestedStudentsDetails(id).set(details), sendEmailToAdmin(subject, body)]).then(handleSubmitDetailsSuccess(details, dispatch)).catch(handleSubmitDetailsFailure(dispatch));
}

export const resetState = () => dispatch => dispatch({type: ACTION_TYPES.RESET_INTERESTED_FORM_STATE});
