import React from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _startCase from 'lodash/startCase';
import _isEmpty from 'lodash/isEmpty';

import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import {LineOutlined} from '@ant-design/icons';

import styles from './studentListRenderer.module.scss';

function StudentListRenderer({
	cell
}) {
	const { value } = cell;
	if(_isEmpty(value)) return <LineOutlined />;
	return (
		<li className={styles.container}>
			{_map(value, studentName => (
				<ul key={studentName} className={styles.studentItem}>
					<ArrowRightOutlinedIcon/>
					<div>
						{_startCase(studentName)}
					</div>
				</ul>
			))}
		</li>
	);
}

StudentListRenderer.propTypes = {
	cell: PropTypes.object,
};

StudentListRenderer.defaultProps = {
	cell: {},
};

export default StudentListRenderer;
