import {firebaseAppAuth} from "../../../../services/firebase";


export const resetPassword = (oobCode, password) => {
	return firebaseAppAuth.confirmPasswordReset(oobCode, password);
}

export const getAccountEmail = (oobCode) => {
	return firebaseAppAuth.verifyPasswordResetCode(oobCode);
}
