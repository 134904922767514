import React, {Component} from 'react';
import 'antd/dist/antd.css';
import cx from 'classnames';
import {Button, Input} from "antd";
import MaterialButton from '@material-ui/core/Button';
import {ArrowRightOutlined, LeftOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {compose} from 'recompose';

import _get from 'lodash/get'

import styles from './login.module.scss';
import {loginUser} from 'actions/login.actions'
import logo from 'public/logo.svg'
import {Redirect, withRouter} from "react-router-dom";
import withIsMobile from "connectors/withIsMobile";
import {verifyAuth} from "actions/app.actions";
import AbilityContext from "services/abilityContext";
import {getUserHomeRoute} from "./login.helper";
import IconButton from "@material-ui/core/IconButton";
import ForgotPasswordModal from "./ForgotPasswordModal";

class Login extends Component {
	state = {
		isForgotPasswordModalVisible: false,
		email: '',
		password: '',
	}
	static contextType = AbilityContext;
	
	componentDidMount() {
		const {verifyAuth} = this.props;
		verifyAuth();
	}
	
	goBackToHomepage = () => {
		const {history} = this.props;
		history.push('/');
	}
	
	renderLogoSection = () => {
		const {isMobile} = this.props;
		return (
			<div
				className={cx(styles.logoSection, isMobile && styles.horizontalLogoSection, !isMobile && styles.verticalLogoSection)}
			>
				<IconButton
					classes={{
						root: styles.backButton,
						label: cx(styles.backButtonLabel, isMobile && styles.mobileBackButtonLabel),
					}}
					onClick={this.goBackToHomepage}
				>
					<LeftOutlined/>
				</IconButton>
				<div className={styles.logoContainer}>
					<img src={logo} alt="" className={styles.logoImg}/>
					<h1
						className={cx(styles.logoText, isMobile ? styles.mobileLogoText : styles.fullLogoText)}
					>
						EasyChemistry Academy
					</h1>
				</div>
			</div>
		)
	}
	
	handleChange = (id) => (event) => {
		const value = _get(event, 'target.value');
		this.setState(state => ({
			...state,
			[id]: value,
		}))
	}
	
	handleLogin = () => {
		const {email, password} = this.state;
		const {loginUser} = this.props;
		if (email && password && email.length && password.length) loginUser(email, password);
	}
	
	renderLoginFields = () => {
		return (
			<React.Fragment>
				<Input
					placeholder="Email"
					className={styles.email}
					onChange={this.handleChange('email')}
					onPressEnter={this.handleLogin}
				/>
				<Input.Password
					placeholder="Password"
					className={styles.password}
					onChange={this.handleChange('password')}
					onPressEnter={this.handleLogin}
				/>
			</React.Fragment>
		)
	}
	
	handleCreateAccount = () => {
		const {history} = this.props;
		history.push('/createAccount')
	}
	
	renderSignupMessageContainer = () => {
		return (
			<div className={styles.signupMessageContainer}>
				<span className={styles.dontHaveAccount}>Don't Have an Account?</span>
				<MaterialButton
					size="small"
					style={{textTransform: 'none', color: 'white', textDecoration: 'underline'}}
					onClick={this.handleCreateAccount}
				>
					Register Now
				</MaterialButton>
			</div>
		)
	}
	
	toggleForgotPasswordModal = () => {
		this.setState(state => ({
			isForgotPasswordModalVisible: !state.isForgotPasswordModalVisible
		}))
	}
	
	renderLoginSection = () => {
		const {isMobile} = this.props;
		const {isAuthenticating, isValidating} = this.props;
		return (
			<div
				className={cx(styles.loginSection, isMobile && styles.horizontalLoginSection, !isMobile && styles.verticalLoginSection)}>
				<div className={styles.loginContainer}>
					{this.renderLoginFields()}
					<MaterialButton
						size="small"
						style={{
							textTransform: 'none',
							color: 'white',
							textDecoration: 'underline',
							alignSelf: 'flex-end',
							marginRight: '2%'
						}}
						onClick={this.toggleForgotPasswordModal}
					>
						Forgot Password?
					</MaterialButton>
					<Button
						type="primary"
						className={styles.loginSubmit}
						icon={<ArrowRightOutlined/>}
						loading={isAuthenticating || isValidating}
						onClick={this.handleLogin}
					>
						Let's Go
					</Button>
					{this.renderSignupMessageContainer()}
				</div>
			</div>
		)
	}
	
	checkIfAuthenticated = () => {
		const {isAuthenticated} = this.props;
		if (isAuthenticated) {
			const {location} = this.props;
			const { state: routeState } = location;
			const redirectUrl = `${_get(routeState, 'previousLocation', '')}${_get(routeState, 'previousLocationSearch', '')}`;
			if (redirectUrl) {
				return <Redirect to={redirectUrl}/>;
			}
			const ability = this.context;
			return <Redirect to={getUserHomeRoute(ability)}/>;
		}
	}
	
	renderForgotPasswordModal = () => {
		const {email} = this.state;
		return <ForgotPasswordModal
			onClose={this.toggleForgotPasswordModal}
			defaultEmail={email}
		/>
	}
	
	render() {
		const {isForgotPasswordModalVisible} = this.state;
		const authenticatedResponse = this.checkIfAuthenticated();
		if (authenticatedResponse) return authenticatedResponse
		
		return (
			<div className={styles.loginPage}>
				{this.renderLogoSection()}
				{this.renderLoginSection()}
				{isForgotPasswordModalVisible && this.renderForgotPasswordModal()}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	isAuthenticating: _get(state, 'auth.isAuthenticating'),
	isAuthenticated: _get(state, 'auth.isAuthenticated'),
	isValidating: _get(state, 'auth.isValidating'),
})

const mapDispatchToProps = {
	loginUser,
	verifyAuth,
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
	withIsMobile,
)(Login);

/*
<button
						className={styles.loginButton}
						type="button"
						onClick={signIn}
					>
						<img
							src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
							alt="logo"
							className={styles.googleLogo}
						/>
						<div className={styles.loginButtonText}>
							Login With Google
						</div>
					</button>
 */
