import {allStreams, attendanceOfStreamBatchAndDate, classes, firebaseAppAuth} from "services/firebase";
import ACTION_TYPES from "./app.actionTypes";
import {handleSignInSuccess} from "./login.actions";
import {toast} from "react-toastify";

const handleFetchClassTypesSuccess = dispatch => (snapshot) => dispatch(
	{
		type: ACTION_TYPES.LOAD_CLASS_TYPES_SUCCESS,
		payload: snapshot.val()
	}
)

const handleFetchClassTypesFailure = () => {
	toast.error("Error loading data")
}

export const fetchClassTypes = () => (dispatch) => {
	classes.once('value')
		.then(handleFetchClassTypesSuccess(dispatch))
		.catch(handleFetchClassTypesFailure)
	;
}

const handleValidationError = (dispatch) => {
	dispatch({
		type: ACTION_TYPES.VERIFY_AUTH_FAILURE
	})
}

export const verifyAuth = () => dispatch => {
	dispatch({type: ACTION_TYPES.VERIFY_AUTH_REQUEST});
	firebaseAppAuth.onAuthStateChanged(user => {
		if (user !== null) {
			handleSignInSuccess(dispatch)(user);
		}
		else{
			handleValidationError(dispatch);
		}
	});
}

export const fetchStreamData = () => allStreams.once('value').then(snapshot => {
	const streamsData = snapshot.val();
	const streams = Object.keys(streamsData);
	return {
		streamsData,
		streams,
	};
});

export const setAttendanceStatus = (date, stream, batch, topic, openStatus, studentId, isStudentPresent) => attendanceOfStreamBatchAndDate(stream, batch, date)
		.update(studentId ? {open: openStatus, [studentId]: isStudentPresent, topic} : {open: openStatus, topic});
