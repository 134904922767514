import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Redirect, withRouter} from 'react-router-dom';
import _startsWith from 'lodash/startsWith';
import _compact from 'lodash/compact';
import {compose} from 'recompose';
import ProtectedRoute from "atoms/protectedRoute";
import NotPermitted from "pages/commonPages/pages/notPermitted";
import AbilityContext from "../../services/abilityContext";
import {connect} from "react-redux";
import _get from 'lodash/get'

const getNextRelativePath = (fullPath, matchedPath) => {
	if (!_startsWith(fullPath, matchedPath)) return '/';
	const splitPaths = _compact(fullPath.split(matchedPath));
	let nextRelativePath = splitPaths.join(matchedPath);
	if (nextRelativePath.startsWith('/')) return nextRelativePath;
	return '/'.concat(nextRelativePath);
}

const getPath = (pathA, pathB) => {
	if (pathA === '/') return pathB;
	if (pathB === '/') return pathA;
	return pathA.concat(pathB);
}

const isURLPrefix = (fullPath, checkPath) => {
	const actualCheckPath = checkPath.endsWith('/') ? checkPath : checkPath.concat('/');
	const actualFullPath = fullPath.endsWith('/') ? fullPath : fullPath.concat('/');
	return actualFullPath.startsWith(actualCheckPath);
}

const isRouteMatch = (nextRelativePath, route) => {
	return (!isURLPrefix(nextRelativePath, route.path)) || (route.exact && nextRelativePath !== route.path)
}

function RouteSwitch({
	location,
	match,
	routes,
	redirectPath,
	isValidating
}) {
	const ability = useContext(AbilityContext);
	const nextRelativePath = getNextRelativePath(location.pathname, match.url);
	for (const route of routes) {
		if (isRouteMatch(nextRelativePath, route)) continue;
		const {accessSubject} = route;
		if(accessSubject && ability.cannot('access', accessSubject)){
			if(isValidating){
				return (
					<Redirect
						to={{
							pathname: "/login",
							state: {
							  previousLocation: location.pathname,
                previousLocationSearch: location.search,
							}
						}}
					/>
				)
			}
			return(
				<ProtectedRoute
					exact={route.exact}
					path={location.pathname + location.search}
					component={NotPermitted}
				/>
			)
		}
		return (
			<ProtectedRoute
				exact={route.exact}
				path={getPath(match.url, route.path)}
				component={route.component}
				componentProps={route.componentProps}
			/>
		)
	}
	return (
		<Redirect to={redirectPath}/>
	)
}

RouteSwitch.propTypes = {
	routes: PropTypes.arrayOf(PropTypes.shape({
		path: PropTypes.string.isRequired,
		componentProps: PropTypes.object,
		exact: PropTypes.bool,
		routeProps: PropTypes.object
	})).isRequired,
	redirectPath: PropTypes.string,
	match: PropTypes.object,
	location: PropTypes.object,
}

const mapStateToProps = state => ({
	isValidating: _get(state, 'auth.isValidating')
});

export default compose(
	connect(mapStateToProps),
	withRouter,
)(RouteSwitch);
