const ACTION_TYPES = {
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	LOGOUT_REQUEST: 'LOGOUT_REQUEST',
	LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
	LOGOUT_FAILURE: 'LOGOUT_FAILURE',
	VERIFY_AUTH_REQUEST: 'VERIFY_AUTH_REQUEST',
	VERIFY_AUTH_FAILURE: 'VERIFY_AUTH_FAILURE',
}

export default ACTION_TYPES;
