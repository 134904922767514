import React from "react";
import {LeftOutlined} from "@ant-design/icons";
import styles from "./PageHeader.module.scss";
import {PageHeader as Header} from "antd";
import cx from "classnames"

function PageHeader({
  title,
  className,
  handleGoBack,
  backIconClassName,
}) {
  return (
    <Header
      title={title}
      className={cx(styles.PageHeader, className)}
      onBack={handleGoBack}
      backIcon={<LeftOutlined className={cx(backIconClassName, styles.backIcon)}/>}
      shape="circle"
    />
  )
}

export default PageHeader;
